/* ======== MENU ============
* 1. GENERAL
* 2. TYPOGRAPHY
* 3. LAYOUT (PHYS.)
* 4. LAYOUT (AESTH.)
* 5. STATES
* 6. ANIMATIONS
* 7. @MEDIA
========================== */

/* == 1. GENERAL ========= */

/* == 2. TYPOGRAPHY ====== */
.mini figcaption {
    text-align: center;
}

.mini span:nth-child(1) {
    color: var(--secondary-colour);
    font-weight: 900;
}

/* == 3. LAYOUT (PHYS.) == */
.mini {
    display: grid;
    justify-items: center;
}

.mini div {
    width: 4.5rem;
    height: 4.5rem;
}

.mini img {
    width: 4.5rem;
    height: 4rem;
    object-fit: cover;
}

/* == 4. LAYOUT (AESTH.) = */
.mini div {
    background-image: url(/public/gold-tiles.png);
    background-size: contain;
    border: .25rem solid transparent;
    border-radius: 50%;
    overflow: hidden;
}

.mini img {
    background-color: var(--neutral-background-colour);
    object-position: right .25rem top 0;
}

/* == 5. STATES ========== */

/* == 6. ANIMATIONS ====== */

/* == 7. @MEDIA ========== */
