/* .blend-in a, */
/* .blend-in  */
.defined-term a,
.defined-term {
    text-decoration: none;
}

.defined-term a,
.defined-term {
    cursor: pointer;
    position: relative;
    color: inherit;
    font-style: normal;
}
/* .defined-term:not( .blend-in ) a,
.defined-term:not( .blend-in ) {
    text-decoration: underline dotted;
} */
.defined-term:hover {
    color: orangered;
}

.defined-term > a + * {
    visibility: hidden;
    opacity: 0;
    transition: .3s ease-in-out;
}
.defined-term > a:hover + * {
    visibility: visible;
    opacity: 1;
}

.defined-term sup {
    font-size: .5em;
}