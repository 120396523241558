/* ======== MENU ============
 * 1. GENERAL
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES & PSEUDO
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */
 
/* == 1. GENERAL ========= */

/* == 2. TYPOGRAPHY ====== */

/* == 3. LAYOUT (PHYS.) == */
section.reviews {
    position: relative;
    overflow: hidden;
    padding-top: 0;
}

.scenarios {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    width: 100vw;
    padding: 3rem;
}

.scenarios header {
    padding: 2rem 2rem 0;
    flex: 1;
}

/* == 4. LAYOUT (AESTH.) = */
.scenarios {
    background: var(--neutral-background-colour-dark);
}

/* == 5. STATES & PSEUDO = */

/* == 6. ANIMATIONS ====== */

/* == 7. @MEDIA ========== */
