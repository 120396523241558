section.contact {
    flex-flow: row wrap;
    justify-content: center;
    /* background: var(--primary-colour); */
    background: var(--primary-gradient-new);
    color: var(--font-colour-on-dark-background-new); 
    /* color: var(--font-colour-on-dark-background);   */
    padding: 1rem;
}

.contact h2 {
    flex: 1;
}

.contact h2, 
.contact h3 {
    text-align: center;
}

.contact h3 {
    margin-bottom: 2rem;
}

.contact form {
    margin: 1rem .5rem;
    padding-bottom: 2rem;
    border: .5rem solid var(--secondary-colour-highlight);
    border-image: url('/public/gold-tiles.png') 30 round;
    border-image-width: .25rem;
}

.contact fieldset {
    flex-wrap: wrap;
    margin-top: 1rem;
}


.contact fieldset,
.contact fieldset div {
    display: flex;
    flex: 1;
    border: 0;
}

.contact fieldset div {
    flex-direction: column;
    padding: .5rem;
    max-height: 3rem;
    box-sizing: content-box;
}

.contact label {
    width: fit-content;
    transform: translateY(-2.25rem);
    font-size: .75rem;
    margin: .25rem;
    padding: .25rem;
    color: var(--grey-colour-dark);
    background: var(--field-background);
    transition-property: background-color, font-size, color, transform;
    transition-duration: .1s, .3s;
    transition-timing-function: ease-in-out;
}

.contact select,
.contact input {
    min-height: 2.5rem;
    min-width: 4rem;
    box-sizing: border-box;
    padding: .25rem .5rem;
    background: var(--field-colour);
    transition: outline .3s ease-in-out;
}

.contact select {
    min-width: 10.25rem;
    cursor: pointer;
}

.empty {
    color: var(--field-colour);
}

.empty:focus,
.empty:focus-visible {
    outline: var(--secondary-colour-highlight-new) auto 2px;
}

.has-value + label,
.empty:focus + label,
.empty:focus-visible + label {
    font-size: 1rem;
    background: transparent;
    color: var(--font-colour-on-dark-background);
    transform: translateY(-4.5rem);
}

.organisation .empty:focus-visible + label,
.organisation .empty:focus + label,
.organisation .has-value + label {
    font-size: .9rem;
}

.button {
    text-align: center;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.button button {
    margin-top: 1rem;
}