/*
========= MENU =========
* 1. GENERAL STYLES
* 2. THEME
* 3. TYPOGRAPHY
* 4. LAYOUT
* 5. CLASSES
* 5. STATES
* 6. ANIMATIONS
* 7. MEDIA (SCREEN MOD.)
=======================
*/

.App > button {
    position: absolute;
    bottom: 0;
    z-index: 20;
}

body > #webpack-dev-server-client-overlay-div,
body > iframe {
    display: none;
}

/* == GENERAL STYLES ===== */
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: 20px;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: .5rem;
  /* background-color: transparent; */
}

::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  background: linear-gradient(
      to bottom,
      var(--primary-colour-highlight),
      var(--secondary-colour-highlight)
  );
}

::-webkit-scrollbar-track {
  border-radius: 2rem;
  background-color: var(--neutral-background-colour-opaque);
}

h1, h2, h3, h4, li, p, ul, figure {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

dialog::backdrop,
button {
  cursor: pointer;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

.loader {
    position: absolute;
    top: 0;
}

/* == THEME ============== */
:root {
  --primary-colour: hsl(0, 100%, 14%);
  --primary-colour-highlight: hsl(0, 100%, 27%);
  --primary-colour-highlight-opaque: hsl(0, 100%, 27%, .5);
  --primary-gradient: linear-gradient(to right, #540303ff, #980202ff, #db0000ff, #980202ff, #540303ff, #980202ff);
  --primary-gradient-new: linear-gradient(to right, #FFDE59, #FF914D);
  --secondary-colour: hsl(43, 89%, 38%);
  --secondary-colour-light: hsl(43, 74%, 49%);
  --secondary-colour-highlight: hsl(51, 100%, 50%);
  --secondary-colour-glow: hsl(60, 100%, 50%);
  --secondary-colour-pale: hsl(51, 100%, 87%);
  --secondary-colour-pale-opaque: hsl(51, 100%, 87%, .5);
  /* --secondary-colour-pale-less-opaque: hsl(51, 100%, 87%, .75); */
  --secondary-gradient: linear-gradient(to right, #B38728, #BF953F, #FCF6BA, #BF953F, #B38728, #BF953F);
  --secondary-gradient-new: linear-gradient(to right, #FF914D, #FFDE59, #FF914D);
  --tertiary-colour: hsl(33, 100%, 50%);
  --grey-colour: hsl(0, 0%, 57%);
  --grey-colour-dark: hsl(0, 0%, 37%);
  --grey-colour-highlight: hsl(0, 0%, 77%);
  --grey-colour-glow: hsl(0, 0%, 87%);
  --grey-colour-glow-opaque: hsl(0, 0%, 87%, .5);
  /* --gold-gradient: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C); */
  --field-colour: white;
  --font-colour-on-light-background: black;
  --font-colour-on-light-background-new: #343434;
  --font-colour-on-dark-background: white;
  --neutral-background-colour: hsl(60, 100%, 97%);
  --neutral-background-colour-dark: hsl(43, 25%, 60%);
  --neutral-background-colour-mid-opaque: hsl(60, 100%, 97%, .5);
  --neutral-background-colour-opaque: hsl(60, 100%, 97%, .1);
}

/* == TYPOGRAPHY ========= */
body {
  /* font-family: 'Dosis', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Noto Color Emoji', sans-serif; */
  /* font-family: 'Noto Emoji', sans-serif; */
  /* font-family: 'Raleway', sans-serif; */
}

h1, h2, h3, h4 {
  text-align: center;
  text-transform: uppercase;
  font-family: 'Arima Madurai', cursive;
  font-weight: 900;
}

h1, h2, h3 {
  line-height: 2rem;
  letter-spacing: .25rem;
  margin-bottom: 2rem;
}

h1 {
  font-size: clamp(2rem, 5vw, 3.5rem);
  line-height: 3rem;
  /* font-size: clamp(2.5rem, 5.5vw, 4rem); */
}

h2 {
  font-size: clamp(1.5rem, 4vw, 3rem);
}

p, li {
  line-height: 1.25;
  letter-spacing: .025rem;
}

/* == LAYOUT ============= */
/* great red #902a2a */
.App {
  /* background-color: var(--neutral-background-colour); */
  background-color: #fdfdfd;
  scroll-snap-type: y mandatory;
}

.other-sections > section {
  padding: 4rem 2rem;
}

.other-sections > section,
.hero-section > header {
  display: flex;
  flex-wrap: nowrap;
  scroll-snap-align: start;
}

.other-sections > section:nth-child(2),
.hero-section > header {
  min-height: 100vh;
}

.other-sections > section {
  flex-flow: column;
  align-items: center;
  /* justify-content: flex-start; */
}

/* == CLASSES ============ */

/* == STATES ============= */

/* == ANIMATIONS ========= */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* == MEDIA (SCREEN MOD.)  */
/* div.buttons {
    position: absolute;
    display: grid;
    gap: 1rem;
    top: 0;
    right: 0;
    z-index: 10;
}

div.buttons button {
    background: pink;
    padding: 1rem;
} */