.section {
    --min-bar-height: 1.5rem;
    --h: 30;
    --s: 100%;
    --l: 50%;
}

.section > div {
    margin: 0;
    /* padding: 1rem 0; */
    background: hsl( var( --h ), var( --s ), 90% );
    min-height: calc( var( --min-bar-height ) * 4 );
}

.light-boxes.section > div {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.dark-boxes.section > div {
    min-height: calc( var( --min-bar-height ) * 2 );
    background: hsl( var( --h ), var( --s ), 75% );
}

.placeholder-bar {
    display: grid;
    grid-template-columns: 1fr !important;
    justify-items: center;
    border: 2px dashed hsl( var( --h ), var( --s ), 50% );
    border-radius: .5em;
    /* padding: .5em 1em !important; */
    /* align-items: center; */
}
.placeholder-bar > button:first-of-type {
    display: none;
}
button.placeholder-button {
    width: 100%;
    justify-content: center;
    font-size: .75rem;
    padding: .75rem;
}