.assignments ul,
.week {
    display: grid;
    overflow: visible;
    /* gap: 1rem; */
}

.week {
    --h: 35;
    --s: 100%;
    --l: 75%;

    border-radius: 1.25rem;
    margin-bottom: 1rem;
    transition: .15s ease-in-out;
}
.week.open .header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* .assignments ul {
    gap: 1rem;
} */

.header {
    /* display: flex; */
    position: relative;
    font-size: .9rem;
    background: hsl( var( --h ), var( --s ), 90% );
    padding: .5rem 1rem;
    border-radius: 1rem;
}

.header span {
    text-transform: uppercase;
    transition: .3s ease-in-out;
    cursor: pointer;
}

.pill {
    position: absolute;
    right: .5rem;
    top: .5rem;

    font-size: .75em;
    background: hsl( var( --h ), var( --s ), 40% );
    color: #fdfdfd;
    padding: .25em 1em;
    border-radius: 1em;
    /* transform: scale( .75 ); */
    align-self: center;
}

.cover span,
.cover {
    align-items: center;
}
.cover span {
    display: flex;
    font-size: .75rem;
    visibility: hidden;
    opacity: 0;
}
.cover {
    --date: "---";

    position: absolute;
    display: grid;
    padding-left: .5rem;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
}
.collapsible .cover {
    cursor: pointer;
}

.cover:hover ~ div,
.cover:hover span {
    visibility: visible;
    opacity: 1;
}
.cover ~ div,
.empty .cover:hover + span {
    visibility: hidden;
    opacity: 0;
}

.closed .assignments {
    height: 0rem;
}
.open .assignments {
    height: fit-content;
}

.assignments {
    overflow: hidden;
    transition: .5s ease-in-out;
}