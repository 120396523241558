/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.week {
    --border-color: hsl( 0, 0%, 15% );
}

.day {
    --colour-odd: hsl( 120, 100%, 25% );
    --colour-even: hsl( 120, 50%, 100% );
    --colour-off: hsl( 0, 0%, 75% );
    --colour-other-month: hsl( 0, 0%, 75%, .5 );
    --font-colour-light: white;
    --font-colour-dark: black;
}

.unit {
    --background: darkslategrey;
    --font-colour: white;
}

.day.even {
    --background: var( --colour-even );
    --background-add : var( --colour-odd );
    --font-colour: var( --font-colour-dark );
    --font-colour-add: var( --font-colour-light );
}
.day.odd {
    --background: var( --colour-odd );
    --background-add: var( --colour-even );
    --font-colour: var( --font-colour-light );
    --font-colour-add: var( --font-colour-dark );
}
.day.off {
    --background: var( --colour-off );
}
.day:not( .current-month ) {
    --background: var( --colour-other-month );
    --font-colour: hsl(0, 0%, 25%);
}

/* == 2. TYPOGRAPHY ====== */
.reason,
.pill,
.add {
    font-size: .75rem;
}

.planning,
.active,
.pill {
    color: black;
}

.unit span,
.planning {
    font-size: .9rem;
}

.day {
    font-weight: 900;
    color: var( --font-colour );
}

.day:not( .current-month ) {
    font-style: italic;
}

.add {
    color: var( --font-colour-add );
}

.add i {
    font-size: 1rem;
}

.pill {
    text-decoration: none;
}

.unit span {
    color: var( --font-colour );
    white-space: nowrap;
}

/* == 3. LAYOUT (PHYS.) == */
.month > header,
.week .days,
.planning,
.week,
.day,
.add {
    display: grid;
}

.planning,
.add {
    grid-template-columns: max-content max-content;
    justify-content: center;
    gap: .25rem;
    margin-top: .25rem;
    padding: .25rem 0;
}

.month {
    margin: 0;
}

.month > header {
    grid-template-columns: 35% 30% 35%;
}

.week.with-button {
    grid-template-columns: 15% 85%;
}
.week.sans-button {
    grid-template-columns: 1fr;
}

.week .days {
    grid-template-columns: repeat( 5, 20% );
}

.holiday-week .days {
    grid-template-rows: 2rem;
}

.holiday-week .day {
    height: auto;
}

.day {
    height: 8rem;
}

.day:not( .off ) {
    grid-template-rows: 1fr 1rem;
}

.add {
    align-items: center;
}

.details, .pill {
    padding: .25rem .5rem;
}

.planning {
    align-items: end;
}

.unit {
    position: relative;
    height: 1rem;
}

.unit span {
    position: absolute;
    top: -1rem;
    padding: .5rem .75rem;
    margin-left: .5rem;
}

/* == 4. LAYOUT (AESTH.) = */
.unit span,
.unit div,
.day {
    transition: .3s ease-in-out;
}

.unit span,
.unit,
.day {
    background: var( --background );
}

.unit div,
.reason,
.add {
    visibility: hidden;
    opacity: 0;
}

.unit span,
.pill {
    border-radius: .5rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.active,
.pill {
    background: var( --secondary-colour-highlight );
}

.day {
    border: 2px solid var( --border-color );
}
.queen.day {
    cursor: pointer;
}

.day:not( .current-month ) {
    opacity: .5;
    border-color: hsl(0, 0%, 20%, .5);
}

.day.coming-month {
    cursor: e-resize;
}
.day.previous-month {
    cursor: w-resize;
}

.queen.day.off {
    cursor: not-allowed;
}

.add {
    color: var( --background-add );
    transition: .1s ease-in;
}

.today {
    border: 5px solid var( --secondary-colour-glow );
    animation: pulse 3s linear infinite;
}

/* == 5. STATES & PSEUDO = */
.day:not( .coming-month, .previous-month, .active ) .details:hover .reason,
.day:not( .coming-month, .previous-month, .active ) .details:hover .add {
    visibility: visible;
    opacity: 1;
}

.unit div::after {
    content: '';
    position: absolute;
    left: 40%;
    top: 1rem;
    border-left: .75rem solid transparent;
    border-right: .75rem solid transparent;
    border-top: .75rem solid var( --background );
    transition: .3s ease-in-out;
}

.unit:hover div {
    visibility: visible;
    opacity: 1;
}
.unit:hover span {
    top: -3rem;
}
.unit:hover div::after {
    top: -1rem;
}

/* == 6. ANIMATIONS ====== */
@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 hsl( 60, 100%, 50%, .7 );
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px hsl( 60, 100%, 50%, 0 );
	}
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 hsl( 60, 100%, 50%, 0 );
    }
}

/* == 7. @MEDIA ========== */