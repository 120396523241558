.sprite {
    --frame-width: 64;
    --frame-height: 64;
    --steps: 9;
    --animation-row: 0;
    --scale: 1.25;

    width: var( --frame-width );
    height: var( --frame-height );
    animation: float 1s steps( var( --steps ) ) infinite;
    background-position: 0 calc( var( --frame-height ) * var( --animation-row ) )px;
    transform: scale( var( --scale ) );
}

@keyframes float {
    50% { transform: scale( var( --scale ) ) translateY( -.5rem ); }
    100% { background-position: calc( -1 * var( --frame-width ) * var( --steps ) ) 0; }
}