/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* DND CONTEXT */
.dnd {
    display: flex;
    /* flex-flow: column wrap; */
    gap: 2rem;
    background: green;
    width: 100vw;
}

.dnd > ul {
    display: flex;
}

/* CONTAINERS */
.container {
    width: 20vw;
    display: grid;
    grid-template-rows: min-content 1fr;
    /* flex-wrap: wrap; */
}

/* ITEMS */
.child {
    display: grid;
    align-items: center;
    /* border-left: 2px solid gold; */
    /* padding: 1rem 0; */
    /* margin: 1rem 0; */
    min-width: 4em;
    /* box-shadow: 0 4px 30px hsla( 0, 0%, 0%, 0.1 ); */
    color: aliceblue;
    font-weight: 900;
}

.content * {
    display: none;
}
.gradebook {
    overflow: auto;
}

/* == 1. GENERAL ========= */
.content > button {
    --rotation-deg: 45deg;
}
/* == 2. TYPOGRAPHY ====== */
.content {
    text-align: center;
}

.icon {
    font-size: 2rem;
}

/* == 3. LAYOUT (PHYS.) == */
.gradebook {
    display: grid;
    align-items: center;
    justify-items: center;
}

.content {
    display: grid;
    gap: 2rem;
    padding: 2rem;
}

/* .gradebook button {
    padding: 1rem;
} */

/* == 4. LAYOUT (AESTH.) = */
.content {
    background: white;
    border-radius: .5rem;
    box-shadow: 0 4px 30px hsla( 0, 0%, 0%, 0.1 );
}

/* == 5. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */
.content > button {
    --padding: 1em;

    border-radius: 3em;
    padding: 1em 2em;
    position: relative;
    font-weight: 900;
    font-size: 1.1rem;
    border: 3px solid black;
    box-shadow: .1em .1em;
    transition: .1s ease-in-out;
    background: linear-gradient( to right, orangered, orange 40%, gold );
    /* background: hsla( 0, 50%, 100%, .5 ); */
    /* backdrop-filter: blur( 10px ); */
    letter-spacing: 1px;
    /* z-index: 1; */
}
.content > button:hover {
    transform: translate3d( -.05em, -.05em, 0 );
    box-shadow: .15em .15em;
}
.content > button:active {
    transform: translate3d( .05em, .05em, 0 );
    box-shadow: .05em .05em;
}
/* .gradebook button::before {
    content: '';
    position: absolute;
    width: 98%;
    height: 95%;
    top: 2.5%;
    left: 1%;
    border-radius: 1.75em;
    filter: blur( 10px );
    background: linear-gradient( to right, orangered, orange 40%, gold );
    z-index: -1;
} */

.content > button:hover .line {
    opacity: 1;
    animation: shake;
    animation-fill-mode: both;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}
.line,
.content > button:active .line {
    opacity: 0;
}

.line {
    --x-y: -.5em;
    --rotation: 45deg;

    position: absolute;
    border: .2em solid transparent;
    border-right-color: orange;
    border-radius: 50%;
    padding: 1em;
    transition: .1s ease-in-out;
}
.line:first-of-type {
    --rotation-deg: calc( 180deg + var( --rotation ) );

    left: var( --x-y );
    top: var( --x-y );
    transform: rotate3d( 0, 0, 1, var( --rotation-deg ) );
}
.line:last-of-type {
    --rotation-deg: var( --rotation );

    right: var( --x-y );
    bottom: var( --x-y );
    transform: rotate3d( 0, 0, 1, var( --rotation-deg ) );
}

.line::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: .15em solid transparent;
    border-right-color: orangered;
    padding: .75em;
    right: calc( var( --x-y ) * 1.25 );
    bottom: .25em;
    transform: rotate( -10deg );
}

@keyframes shake {
    20% {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), 15deg); */
      transform: rotate3d( 0, 0, 1, calc( var( --rotation-deg ) + 15deg ) );
    }

    40% {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), -10deg); */
      transform: rotate3d( 0, 0, 1, calc( var( --rotation-deg ) - 10deg ) );
    }

    60% {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), 5deg); */
      transform: rotate3d( 0, 0, 1, calc( var( --rotation-deg ) + 5deg ) );
    }

    80% {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), -5deg); */
      transform: rotate3d( 0, 0, 1, calc( var( --rotation-deg ) - 5deg ) );
    }

    to {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), 0deg); */
      transform: rotate3d( 0, 0, 1, var( --rotation-deg ) );
    }
  }