/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES & PSEUDO
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
/* == 2. LAYOUT (PHYS.) == */
.button img:last-of-type,
.closed-menu,
.open-menu,
.button {
    position: absolute;
}

.closed-menu,
.open-menu {
    height: 100%;
}

.button img,
.button {
    padding: .25rem 0;
}

.button {
    height: 3rem;
    width: 20vw;
    z-index: 10;
}

.button img {
    width: 1.5rem;
}

.button img:last-of-type {
    left: calc( 10vw - .75rem );
}

/* == 3. LAYOUT (AESTH.) = */
.open-menu .button img:first-of-type,
.button:hover img:first-of-type {
    transform: scale(2) translate( .25rem, -.25rem );
    opacity: 0;
}

.open-menu .button img:last-of-type,
.button:hover img:last-of-type {
    transform: scale(1) translate(0 , 0);
}

.button img:last-of-type,
.button {
    background: none;
}

.button {
    overflow: hidden;
    border: none;
    border-radius: 0;
}

.button img {
    transition: .5s ease-in-out;
}

.button img:last-of-type {
    transform: scale(.6) translate( -.5rem, .5rem );
}

.open-menu .button {
    background-color: var(--secondary-colour-pale-opaque);
}

.menu {
    transition: .3s ease-in-out;
}

.closed-menu .menu {
    visibility: hidden;
}

/* == 4. STATES & PSEUDO = */
/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */