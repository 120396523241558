.error {
    --i: 1;
    --trunk-colour: hsl( 35, 60%, 25% );
    --leaves-duotone: linear-gradient(
        to bottom,
        hsl( 80, 60%, 50% ),
        hsl( 80, 60%, 50% ) 50%,
        hsl( 80, 60%, 40% ) 50%
    );

    display: grid;
    grid-template-areas: 'header'
                         'message';
    grid-template-rows: min-content 1fr;
    /* position: relative; */
}

.tree {
    /* padding: 5rem; */
    border: 3px solid hsl( 35, 60%, 25% );
    /* position: absolute; */
    width: 80%;
    left: 10%;
}

.trunk::before {
    position: absolute;
    display: block;
    content: '';
    background: var( --trunk-colour );
    width: 5rem;
    height: 80vh;
}

.branch {
    width: 5rem;
    height: 1rem;
    border: 3px solid black;
}

.leaves {
    display: grid;
    z-index: 1;
}

.leaves > div {
    width: calc( 3rem * var( --i ) );
    height: 2rem;
    background: var( --leaves-duotone );
    border: 3px solid green;
}

.message {
    display: grid;
    grid-area: "content";
}

.message .text,
.message svg {
    grid-area: content;
}

/* .error {
    display: grid;
    justify-items: center;
    grid-template-rows: max-content 1rem max-content 1fr;
    height: 100vh;
}

.error hr {
    width: 80vw;
}

.page-details {
    min-height: 10vh;
}

.logo {
    border-radius: 1rem;
    width: 8.75rem;
    height: 8.75rem;
    transform: scale(1.5);
    animation: float 6s ease-in-out infinite;
}
.logo img {
    width: 11.5rem;
    transform: translate(-1.5rem, -.5rem);
    transition: .5s ease-in-out;
}
.logo img:hover,
.logo:hover {
    transform: translate(-1.5rem, -.5rem) scale(1.2);
} */

@keyframes float {
    0% {
        /* box-shadow: 0 5px 15px 0px hsla(0, 100%, 0%, .6); */
        transform: translateY(0px);
    }
    50% {
        /* box-shadow: 0 25px 15px 0px hsla(0, 100%, 0%, .2); */
        transform: translateY(-20px);
    }
    100% {
        /* box-shadow: 0 5px 15px 0px hsla(0, 100%, 0%, .6); */
        transform: translateY(0px);
    }
}