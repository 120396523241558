/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
.multiple-icons,
.single-icon {
    text-decoration: none;
}

.multiple-icons,
.single-icon i {
    color: black;
}

/* == 2. LAYOUT (PHYS.) == */
.multiple-icons,
.single-icon i {
    width: 2rem;
    height: 2rem;
}

.section {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
}

.single-icon i {
    display: flex;
    align-items: center;
    justify-content: center;
}

.multiple-icons {
    width: fit-content;
}

.multiple-icons i {
    padding: .25rem 0;
}
.multiple-icons i:first-of-type {
    padding-left: .5rem;
}
.multiple-icons i:last-of-type {
    padding-right: .5rem;
}

/* == 3. LAYOUT (AESTH.) = */
.multiple-icons,
.single-icon i {
    border: 2px solid black;
    border-radius: 10px;
}

button.section {
    background-color: transparent;
    border: none;
}

button.section i {
    background-color: var(--secondary-colour-pale);
}

button.active i {
    background-color: var(--secondary-colour-light);
}

/* == 4. STATES & PSEUDO = */
.multiple-icons:hover,
.single-icon:hover i {
    background-color: var(--secondary-colour-highlight);
}

/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */