.page {
    --h: 45;
    --s: 100%;
    --l: 50%;
}

.edit-button {
    padding: .25em 1em;
    margin-right: 1em;
}

.key-terms .term {
    font-weight: 900;
}

.key-terms .definition {
    margin-left: 1em;
    font-style: italic;
}

.page :is( h1, h2, h3 ) {
    margin: 0;
}
.page h1 {
    font-size: 1.5em;
}
.page h2 {
    font-size: 1.1em;
}
.page h3 {
    font-size: 1em;
}
.page li:has( svg ) {
    display: flex;
    gap: 1em;
    align-items: center;
}

.page header > p {
    font-style: italic;
    font-size: .8em;
    text-align: center;
}
.bolded-subtitle {
    font-weight: 900;
}

.unit-header {
    padding: 2em 3em;
    background: hsl( var( --h ), calc( var( --s ) * .75 ), var( --l ), .5 );
}

.details {
    display: grid;
    justify-content: center;
    padding: 0 3em 2em;
}

.tabs {
    display: flex;
    justify-content: center;
    transform: translateY( -1em );
}

.tab {
    margin: 0 .5em;
}
/* .active.tab {
    border-radius: .75em .75em 0 0 / .75em .75em 0 0;
} */

.key-terms,
.info {
    display: grid;
    gap: 2em;
}

.info > section:first-of-type {
    margin-top: 2em;
}

.info > section:not( :has( section ) ),
.info > section > section {
    border: 2px solid hsl( var( --h ), var( --s ), var( --l ) );
    border-radius: 1em;
}

/* .info > section > header {
    padding: 1em 2em;
} */


.key-terms > section > header,
.info > section > section > header,
.info > section > header {
    padding: 1em 2em;
    border-top-right-radius: .9em;
    border-top-left-radius: .9em;
    background: hsl( var( --h ), var( --s ), var( --l ), .5 );
}
/* .days .day.grey {
    background: hsl( var( --h ), var( --s ), var( --l ), .5 );
} */

.info > section:not( .month ) ul > li,
.key-terms > section ul > li {
    padding: .5em 2em;
}


.info > section:not( .month ) ul > li:last-of-type,
.key-terms > section ul > li:last-of-type {
    padding-bottom: 2em;
    border-bottom-right-radius: .9em;
    border-bottom-left-radius: .9em;
}
.month .day:last-of-type {
    border-bottom-right-radius: .9em;
}
.month .day:nth-last-of-type( 5 ) {
    border-bottom-left-radius: .9em;
}

.info > section:not( .month ) ul > li:first-of-type,
.key-terms > section ul > li:first-of-type {
    padding-top: 1em;
}

.info > section:not( .month ) ul > li:nth-of-type( even ),
/* .info > section ul > li:nth-of-type( even ), */
.key-terms > section ul > li:nth-of-type( even ) {
    background: hsl( var( --h ), var( --s ), var( --l ), .1 );
}
.info .month .day.even:not( .grey ) {
    background: hsl( var( --h ), var( --s ), var( --l ), .1 );
}

.bullseye .white-rings {
    fill: hsl( var( --h ), var( --s ), 97.5% );
}
.bullseye .red-rings {
    fill: hsl( 15, var( --s ), 50% );
}
.bullseye .dart-tip {
    fill: hsl( var( --h ), 50%, var( --l ) );
}
.bullseye .dart-wings {
    fill: hsl( var( --h ), var( --s ), 35% );
}
.bullseye .dart-body {
    fill: hsl( var( --h ), var( --s ), var( --l ) );
}

.inputs-container {
    display: grid;
    grid-template-columns: 7.5em max-content 1fr;
}
/* .form {
    display: flex;
} */

.board > header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.board-assignments {
    display: flex;
    width: 80vw;
    overflow-x: auto;
}
.assignment {
    width: 10rem;
}
.assignment:nth-of-type( 2 ) {
    /* margin-right: 2em; */
    border-right: 10px double orange;
}

.month .days {
    display: grid;
    grid-template-columns: repeat( 5, 1fr );
}
li.day {
    display: grid;
    grid-template-areas: "date empty";
    grid-template-columns: 2em 1fr;
    grid-template-rows: min-content repeat( 2, 1em );
    grid-auto-rows: 1em;
    gap: .25em;
    padding: .5em 1em 2em;
}
.date {
    grid-area: date;
}

.days .day.grey {
    background: none;
}

.day:is( .holiday, .grey, .inactive ) {
    font-style: italic;
    background: grey;
    opacity: .5;
}
.day.inactive .pill {
    display: none;
}

.day .pill {
    grid-area: event;
    color: black;
    font-size: .6em;
}
/* .pill.day-off {
    border: .25em dashed orange;
    border-radius: 1em;
    display: grid;
    text-align: center;
    align-items: center;
} */

/* .boards .board ul.board-assignments li.assignment {
    display: grid;
    padding: 0;
    grid-template-areas: "square";
}
.board-assignments li.assignment > * {
    grid-area: square;
} */
/* .board-assignments .assignment .button {
    background: hsl(0, 0%, 100%, .1);
    backdrop-filter: blur( .1em );
    align-self: normal;
    display: grid;
    align-items: center;
    justify-content: center;
} */
/* .board-assignments .assignment .content {
    padding: 2em;
} */

button.add-to-course {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 2em;
    padding: 1em;
    background: hsl( var( --h ), calc( var( --s ) * .75 ), var( --l ), .5 );
}


@media only screen and ( min-width: 992px ) {
    .key-terms {
        grid-template-columns: 40vw 40vw;
    }
}