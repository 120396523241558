.over:not( .disabled ) > div,
.over:not( .disabled ) {
    border: 3px solid gold;
    background: hsl( 50, 100%, 80% );
}

.over.disabled {
    border: 3px solid orangered;
}

.over:not( .disabled ) > div,
.droppable {
    /* background: hsl( 45, 100%, 50%, .75 ); */
    transition: .3s ease-in-out;
}

.wrapping-sortable {
    padding: 0;
    margin: 1rem;
}