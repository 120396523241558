/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

/* == 1. VARIABLES ======= */
.square {
    --i: 0;
}
/* == 2. TYPOGRAPHY ====== */
.category {
    text-transform: capitalize;
    font-size: .65em;
}

/* == 3. LAYOUT (PHYS.) == */
.square {
    display: grid;
}

.category {
    padding: 1em;
    /* height: 100%; */
}

.sub-categories {
    display: flex;
    flex-wrap: wrap;
}

/* == 4. LAYOUT (AESTH.) = */
.category,
.square {
    border-radius: 1rem;
}

/* == 5. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */