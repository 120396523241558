/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES & PSEUDO
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
.link {
    color: var(--secondary-colour);
    text-decoration: none;
    font-weight: 900;
}

.label span {
    text-overflow: ellipsis;
}

.label i {
    text-align: center;
}

.label sup {
    font-size: .5rem;
}

/* == 2. LAYOUT (PHYS.) == */
.link {
    position: relative;
}

.iframe {
    position: relative;
    width: 11.5rem;
    height: 9.25rem;
    margin-bottom: .5rem;
}

.iframe iframe {
    position: absolute;
    width: 56rem;
    height: 48rem;
}

.label {
    display: flex;
    align-items: center;
    gap: .25rem;
    padding: .5rem .5rem;
}

.iframe + .label {
    position: absolute;
    width: 11.5rem;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.label i {
    width: 2rem;
}

/* == 3. LAYOUT (AESTH.) = */
.iframe,
.label {
    overflow: hidden;
    white-space: nowrap;
    border: 2px solid var(--secondary-colour);
}

.iframe {
    overflow: hidden;
    border-radius: .5rem;
}

.iframe iframe {
    transform: scale( 21% ) translateY(-2rem);
    transform-origin: 0 0;
}

.label {
    background-color: var(--secondary-colour-pale-opaque);
}

.iframe + .label {
    background-color: var(--secondary-colour-pale);
}

.label span {
    overflow: hidden;
    white-space: nowrap;
}

/* == 4. STATES & PSEUDO = */
.iframe::after {
    content: '';
    position: absolute;
    left: 0;
    height: 9.25rem;
    width: 11.5rem;
    background-color: var(--neutral-background-colour-opaque);
}

/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */