/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */
/* input[ type='file' ]::file-selector-button */

/* == 1. VARIABLES ======= */
.container {
    --border-radius: .25rem;
}

/* == 2. TYPOGRAPHY ====== */
.input-container > button {
    text-transform: capitalize;
    font-size: 1rem;
}

.show-input .input-container > button .label,
.hide-input.container button.reset,
.hide-input.container .field
.link label,
.sans-input button.reset {
    font-size: 0;
}

/* == 3. LAYOUT (PHYS.) == */
.sans-input .field button,
.sans-input .field input,
.sans-input .field,
.link {
    margin: 0;
    padding: 0;
    border: none;
}

.hide-input.container .field > div,
.container fieldset,
.link {
    margin: 0;
}

.hide-input.container .field input,
.container fieldset {
    padding: 0;
}

.input-container,
.buttons {
    display: grid;
    align-items: center;
}

.hide-input.container .input-container {
    grid-template-columns: 1fr;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
}

.full > div {
    grid-auto-flow: column;
}
.full .field {
    display: none;
}

.hide-input.container .field {
    width: 0%;
}

.hide-input.container .buttons,
.buttons input[ type='file' ] {
    display: none;
}

.show-input .input-container > button .label {
    padding-left: 0;
}

.container {
    margin: 0 .25rem;
}

.input-container {
    grid-template-columns: max-content 1fr;
    grid-template-rows: 1.5rem;
    /* border: 2px solid white; */
}

.field {
    position: relative;
}

.input-container > button,
.container button.reset {
    padding: 0 .5rem;
    min-height: 1.75rem;
    /* display: grid; */
    /* margin: 0; */
}

.buttons {
    position: absolute;
    /* align-content: center; */
    grid-template-columns: max-content max-content;
    gap: .25rem;
    z-index: 10;
    left: .5rem;
}

.input-container > button .label {
    padding-left: .5rem;
}

/*

.buttons > button,
.file-input-label {
    display: grid;
    align-items: center;
    align-content: center;
}

.container.hide-input .input-container {
    align-items: end;
}

/* == 4. LAYOUT (AESTH.) = */
.input-container > button,
.file-input-label i,
.file-input-label,
button.reset,
.add {
    transition: .3s ease-in-out;
}

.input-container > button,
.file-input-label,
button.reset {
    cursor: pointer;
}

.hide-input.container .field input
.container fieldset,
.buttons button {
    border: none;
}

.hide-input.container .input-container > button {
    border-top-left-radius: 0;
    /* border-top-right-radius: 0; */
    /* border-bottom-right-radius: 0; */
}

.show-input.container .input-container > button:not( :disabled ) {
    background: green;
    color: white;
    border: 2px solid white;
}

.show-input.container .input-container > button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-container > button {
    border-radius: var( --border-radius );
    background: white;
}

button.reset {
    /* border-radius: var( --border-radius ); */
    background: transparent;
}

.sans-input button.reset {
    font-size: 0;
}

.link > input[ type='text' ] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* == 5. STATES & PSEUDO = */
.file-input-label:hover i,
.input-container > button:hover .add {
    transform: scale( 1.1 );
}

.input-container > button:disabled {
    cursor: not-allowed;
}

.file-input-label:hover i {
    color: hsl(240, 80%, 50%);
}

button.reset:hover {
    transform: scale( 1.25 );
    color: hsl( 0, 80%, 50%);
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */