/* ======== MENU ============
 * 1. GENERAL
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */
 
/* == 1. GENERAL ========= */
.box {
    cursor: pointer;
}

/* == 2. TYPOGRAPHY ====== */
.text {
    font-size: clamp(.8rem, 3vw, 2rem);
}

.text h4 {
    font-size: clamp(1.25rem, 3.5vw, 2.5rem);
}

.box-nav ul {
    font-size: .75rem;
}

.box {
    color: transparent;
}

.active-box {
    color: var(--primary-colour-highlight);
}

/* == 3. LAYOUT (PHYS.) == */
.carousel {
    display: grid;
    justify-items: center;
}

.content {
    display: flex;
    width: 100vw;
    height: 54.25vw;
}

.screen-mockup {
    position: absolute;
    width: 100vw;
    z-index: 1;
}

.screenshot-container {
    position: relative;
    min-width: 55.65%;
}

.screenshot {
    position: absolute;
    left: 15%;
    top: 20vw;
    width: 69%;
    object-fit: cover;
    object-position: top left;
}

div.text {
    flex-grow: 1;
    margin: 2rem 2.5rem 2rem .5rem;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    z-index: 2;
}

.text hr {
    margin: 1vw 5vw 2vw;
}

.box-nav ul {
    display: flex;
    gap: .5rem;
    width: fit-content;
    margin-top: .5rem;
    margin-bottom: 1.5rem;
}

.buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 2rem;
}

/* == 4. LAYOUT (AESTH.) = */
.content {
    overflow: hidden;
}

.screen-mockup {
    background-color: transparent;
}

div.text {
    background: var(--neutral-background-colour-mid-opaque);
}

.box-nav ul {
    background: linear-gradient(
        90deg,
        var(--tertiary-colour) 0%,
        var(--secondary-colour-highlight) 100%
        );
    background-clip: text;
}

.box {
    transition: .3s ease-in-out;
}

/* == 5. STATES ========== */
.box:hover {
    color: hsl(0, 0%, 100%, .5);
}

.active-box:hover {
    color: var(--primary-colour-highlight-opaque);
}

/* == 6. ANIMATIONS ====== */

/* == 7. @MEDIA ========== */