/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.pane {
    --dark: hsl( 25, 90%, 70% );
    --mid: hsl( 30, 65%, 65% );
    --light: hsl( 35, 95%, 60% );
}

/* == 2. TYPOGRAPHY ====== */
.sections-and-premium-choices :is( legend, header ) {
    font-variant: small-caps;
}

.submit-button button {
    text-align: center;
}

/* == 3. LAYOUT (PHYS.) == */
.sections-and-premium-choices,
.fields-and-submit-button,
.submit-button,
.course,
.form {
    display: grid;
}

.premium-choices li:not( :first-of-type ),
.course-details > button {
    margin-top: 1em;
}

.form {
    grid-template-columns: 1rem 1fr;
}

.sections-and-premium-choices {
    grid-auto-flow: row;
    gap: 2em;
    margin: 2em 4em 0;
}

.course {
    align-items: flex-end;
    grid-template-columns: max-content 1fr;
}
.input.text {
    margin-top: 2em;
    margin-right: 2em;
}
.input.text input {
    width: 100%;
}

.sections-and-premium-choices input[ type=number ] {
    width: 100%;
}
.sections-and-premium-choices > *:first-child > section {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.premium-choices input {
    transform: scale( 1.25 );
    margin-right: 1em;
}

.submit-button {
    margin: 2em 4em;
}

/* == 4. LAYOUT (AESTH.) = */
.form-colour-pop {
    background: linear-gradient( to top right, orangered, orange );
    /* background: linear-gradient( to top right, hsl( 30, 100%, 50% ), hsl( 48, 100%, 50% ) ); */
    border-bottom-left-radius: 1em;
    border-top-left-radius: 1em;
}

/* == 5. STATES & PSEUDO = */
.sections-and-premium-choices input[ type=checkbox ]:disabled {
    cursor: not-allowed;
}

/* == 6. ANIMATIONS ====== */

/* == 7. @MEDIA ========== */
@media only screen and ( min-width: 600px ) {
    .sections-and-premium-choices {
        grid-template-columns: 40% 60%;
        grid-auto-flow: column;
    }
}

@media only screen and ( min-width: 768px ) {
    .sections-and-premium-choices > fieldset {
        margin-right: 2em;
    }

    .sections-and-premium-choices input[ type=number ] {
        width: max( 10vw, 4rem );
    }
}