.assignment:not( .with-handle ):not( :hover ) .buttons,
.assignment:not( .with-handle ):not( :hover ) .icon {
    visibility: hidden;
}

.assignment:not( .with-handle ):not( :hover ) .button {
/* .button:first-of-type { */
    padding: 0;
}

.assignment:not( .with-handle ):not( :hover ) .icon {
    height: 0;
}

.assignment:not( .with-handle ):not( :hover ) .buttons,
.assignment:not( .with-handle ):not( :hover ) .button {
    width: 0;
}

.assignment span,
.buttons > *,
.assignment,
.buttons {
    transition: .3s ease-in-out;
}

.assignment {
    display: grid;
    grid-template-columns: max-content 1fr 1.5rem;
    border-radius: 1rem;
    gap: 1rem;
    justify-items: center;
    align-items: center;
    font-size: .8rem;
}
.assignment:not( .empty-assignment ) {
    padding: .5rem 0;
}
.assignment.no-buttons {
    grid-template-columns: 1fr 1.5rem;
}

.assignment fieldset {
    padding: 0;
    margin: 0;
    border: none;
}

.buttons,
.button {
    width: fit-content;
}

.button {
    padding: .25rem .5rem;
}

.icon {
    height: .75rem;
}

.assignment span {
    cursor: default;
}
.assignment span:not( :hover ) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.empty-assignment > span {
    font-style: italic;
}

.disabled {
    color: hsl( 35, 0%, 50% );
}

.overlay {
    background: gold;
}

.copies {
    padding-left: 1em;
    background: red;
}

.attachments {
    grid-auto-flow: row !important;
    gap: .25em;
    padding-right: 1rem;
}

.attachments img {
    max-width: 1rem;
    height: auto;
}
.attachments.multiple img {
    max-height: .75rem;
}

.attachments > div > div {
    padding: .25em .5em !important;
}

.directions {
    justify-self: start;
}