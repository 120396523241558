dialog {
    position: relative;
}

dialog::backdrop {
    background: hsl( 45, 0%, 0%, .25 );
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

dialog[ open ] {
    background: hsl( 45, 0%, 100%, .75 );
    backdrop-filter: blur( 8px );
    padding: 0;
    border: none;
    /* border-radius: .5rem; */
    max-width: 80%;
    max-height: 80%;
}
dialog[ open ] + button {
    display: none;
}

.button {
    padding: 0;
    width: 3rem;
    height: 1rem;
}
.button svg {
    transform: scale( .75 );
}

.container {
    /* padding: 2em; */
    border-radius: .5rem;
}

.bottom {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.right {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.top {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.left {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.close-button {
    position: absolute;
    left: 2rem;
    top: 1rem;
    padding: .25rem .5rem;
    border-radius: .5rem;
    z-index: 1;
}