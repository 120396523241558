/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
.main-nav a {
    text-decoration: none;
    color: var(--font-colour-on-light-background);
    font-weight: 600;
    font-size: .9rem;
}

.main-nav li {
    letter-spacing: .1rem;
}

.not-button {
    text-align: center;
}

/* == 3. LAYOUT (PHYS.) == */
.main-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-nav li {
    display: inline-block;
    position: relative;
    min-width: 5rem;
}

.not-button {
    padding: .5rem 1rem;
}

/* == 4. LAYOUT (AESTH.) = */
.not-button {
    cursor: pointer;
}

.main-nav li {
    transition: all .3s ease-in-out;
}

/* == 5. STATES & PSEUDO = */
.main-nav img:hover,
.main-nav.single-item li:hover,
.main-nav .not-button:hover {
    transform: scale( 1.1 );
}

/* .main-nav li:not(:last-child)::after, */
.main-nav .not-button::after,
.main-nav.single-item li::after {
    content: '';
    position: absolute;
    width: 0%;
    height: .2rem;
    background: linear-gradient(
        to right,
        var(--primary-colour-highlight),
        var(--secondary-colour-highlight)
    );
    left: 50%;
    right: 50%;
    bottom: 0;
    transition: .3s;
}

/* .main-nav li:not(:last-child):hover::after, */
.main-nav .not-button:hover::after,
.main-nav.single-item li:hover::after {
    left: 10%;
    right: 10%;
    width: 80%;
}

/* == 6. ANIMATIONS ====== */

/* == 7. @MEDIA ========== */