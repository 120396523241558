.plans-and-features,
.plans {
    display: grid;
    gap: 2rem;
}
.plans {
    justify-items: center;
}

.plans-and-features {
    --font-size: .75rem;

    /* grid-template-columns: min-content 1fr; */
}

.guarantee {
    --font-size: .7em;
    font-size: var( --font-size );
}
.guarantee svg {
    height: calc( var( --font-size ) * 1.5 );
}

.plans-and-features > ul {
    display: grid;
    /* gap: var( --font-size ); */
    align-items: center;
}

.billing {
    justify-self: center;
}

.feature {
    font-size: var( --font-size );
    padding: calc( var( --font-size ) / 2 ) calc( 2 * var( --font-size ) );
    /* border-radius: var( --font-size ); */
    /* cursor: pointer; */
    transition: .3s ease-in-out;
}
.feature.applies {
    /* box-shadow: .1rem .1rem .6rem -.4rem black; */
    /* background: hsl( 45, 100%, 90% ); */
    font-weight: 900;
}
.feature:not( .applies ) {
    color: hsl( 0, 0%, 80% );
}

/* .feature.applies:hover {
    background: hsl( 45, 100%, 80% );
    transform: scale( 1.1 );
} */

.student-attributes .list {
    display: grid;
    grid-template-columns: 4.5rem repeat( 3, 6rem );
    gap: 1rem;
}
.student-attributes .list li {
    /* align-self: stretch; */
    display: grid;
}
.student-attributes .list label {
    border: none;
}
.student-attributes .option:first-of-type label {
    background: hsl( 45, 0%, 80% );
}

.attribute {
    text-transform: uppercase;
    font-weight: 900;
    font-size: .9em;
}

.bar-and-features {
    display: grid;
    grid-template-columns: min-content 1fr;
}