.nav ul,
.icon {
    display: grid;
}

.nav ul {
    --icon-height: 2rem;

    grid-auto-rows: max-content;
    /* padding-top: 1.5rem; */
}

.step {
    display: flex;
    align-items: center;
    margin-bottom: var( --icon-height );
    position: relative;
}

.header {
    margin-left: .5rem;
    text-transform: capitalize;
}

.icon {
    position: relative;
    align-items: center;
    justify-items: center;
    height: var( --icon-height );
    width: var( --icon-height );
    border-radius: 50%;
    background: orange;
}
/* .icon:not( :last-of-type ) { */
/* } */
.icon svg {
    width: calc( var( --icon-height ) * .6 );
    height: calc( var( --icon-height ) * .6 );
}

.icon .complete {
    stroke: white
}

.step:not( :last-of-type ) .icon::after,
.cover {
    position: absolute;
}

.cover {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.step:not( :last-of-type ) .icon::after {
    content: '';
    background: orange;
    top: 2.25rem;
    width: .25rem;
    height: calc( var( --icon-height ) - .5rem );
    border-radius: calc( var( --icon-height ) / 2 );
}