/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. GENERAL ========= */
/* == 2. TYPOGRAPHY ====== */
.footer sup {
    font-size: .6rem;
}

.footer a {
    text-decoration: none;
    color: inherit;
}

/* == 3. LAYOUT (PHYS.) == */
.footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 2em;
}

/* == 4. LAYOUT (AESTH.) = */
.footer a {
    transition: .3s ease-in-out;
}

/* == 5. STATES & PSEUDO = */
.footer a:hover {
    transform: scale( 1.1 );
}
/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */