/* ======== MENU ============
 * 1. GENERAL
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */
 
/* == 1. GENERAL ========= */

/* == 2. TYPOGRAPHY ====== */
.icons i {
    font-style: normal;
    font-size: 1.5rem;
}

.line {
    font-size: .75rem;
}

.me .line {
    text-align: end;
}

.conversation li .narration,
.board .line {
    color: transparent;
    font-weight: 900;
}

/* == 3. LAYOUT (PHYS.) == */
.scenario {
    display: grid;
    grid-template-rows: 20% 80%;
    height: min(30rem, 80vw);
    width: min(20rem, 80vw);
    z-index: -10;
}

.scenario header {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    padding: 1rem 0 .5rem;
    gap: .5rem;
}

.icons i {
    padding: .25rem .5rem;
}

.conversation {
    max-height: 90%;
    padding: 1rem;
    scrollbar-color: red;
    scrollbar-width: thin;
}
    
.conversation li {
    display: grid;
    grid-auto-flow: column;
    gap: .1rem .5rem;
    margin-bottom: .1rem;
    width: 100%;
    justify-content: start;
}

.conversation li.me {
    justify-content: end;
}

.conversation li.last-of-group {
    margin-bottom: .5rem;
}

.conversation li .narration,
.board .speaker,
.board .line {
    padding-bottom: 0;
    padding-top: 0;
}

.speaker {
    padding: .1rem .2rem;
    width: 1.5rem;
    height: fit-content;
    align-self: end;
}

.board {
    position: relative;
}

.board img {
    margin: 0;
    height: 1.9rem;
    width: auto;
    position: absolute;
    left: -.9rem;
    bottom: 0;
    /* transform: translate(-1.15rem, -.05rem); */
}
 
.line {
    padding: .3rem .5rem;
}

.me .line {
    margin-left: 2rem;
}

li:not(.me) .line,
.board .line,
.them1 .line,
.them .line {
    margin-right: 2rem;
}

.board.first-of-group .line,
li.first-of-group .narration {
    padding-top: .3rem;
}

/* == 4. LAYOUT (AESTH.) = */
.scenario {
    /* background-color: var(--secondary-colour-pale); */
    /* background: rgba(238, 255, 0, 0.5); */
    backdrop-filter: blur(10px);
}

.scenario header {
    border-top-left-radius: .85rem;
    border-top-right-radius: .85rem;
    padding-bottom: .5rem;
}

.speaker.show {
    /* padding-right: 1.25rem; */
    font-size: .9rem;
}

.speaker.show,
.icons i {
    border: 2px solid var(--secondary-colour);
    background-color: var(--secondary-colour-pale);
    border-radius: 50%;
}

.board .speaker {
    border: none;
    background-color: transparent;
}

.conversation {
    overflow: auto;
}

.line {
    background-color: var(--grey-colour-glow);
}

.me .line {
    background: linear-gradient(
        180deg,
        var(--tertiary-colour) 20%,
        var(--secondary-colour-glow) 100%
    );
    background-attachment: fixed;
    border-bottom-left-radius: .75rem;
    border-top-left-radius: .75rem;
}

.board .line {
    background-color: var(--primary-colour-highlight);
}

.me.first-of-group .line {
    border-top-right-radius: 1rem;
}

.me.last-of-group:not(.first-of-group) .line {
    border-bottom-right-radius: 1rem;
}

.board .line,
.them1 .line,
.them .line {
    border-bottom-right-radius: .75rem;
    border-top-right-radius: .75rem;
}

.board.first-of-group .line,
.them1.first-of-group .line,
.them.first-of-group .line {
    border-top-left-radius: 1rem;
}

.board.last-of-group:not(.first-of-group) .line,
.them1.last-of-group:not(.first-of-group) .line,
.them.last-of-group:not(.first-of-group) .line {
    border-bottom-left-radius: 1rem;
}

li:not(.me) .line {
    border-bottom-right-radius: .5rem;
    border-top-right-radius: .75rem;
}

.conversation li .narration,
.board .line {
    background-clip: text;
}

li:not(.me) .narration {
    background-color: var(--grey-colour-dark);
}

li.me .narration {
    background-color: var(--secondary-colour-light);
}

/* == 5. STATES & PSEUDO = */
.conversation::before {
    position: absolute;
    left: 10%;
    top: 20%;
    content: '';
    height: .1rem;
    width: 80%;
    background-color: var(--primary-colour-highlight);
}

/* == 6. ANIMATIONS ====== */

/* == 7. @MEDIA ========== */