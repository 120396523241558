/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.buttons {
    --i: 0;
    --hue: calc( 0 + ( 10 * var( --i ) ) );
    --saturation: 100%;
    --lightness: calc( 20% + ( 6% * var( --i ) ) );
}

button.merge {
    --colour1: hsl( var( --hue ), 100%, var( --lightness ), .1 );
    --colour2: hsl( var( --hue ), 100%, calc( var( --lightness ) + 10% ) );
    --gradient-percentage1: 30%;
    --gradient-percentage2: 80%;
    --translate-distance: .75rem;
}

/* == 2. TYPOGRAPHY ====== */
button.fill-board {
    font-size: 3rem;
}

.merged.buttons .fill-board {
    color: hsl( var( --hue ), var( --saturation ), var( --lightness ) );
}

/* == 3. LAYOUT (PHYS.) == */
.merged.buttons .fill-board i,
.cluster:nth-of-type( n + 2 ),
button.merge span,
.plus .cover {
    position: absolute;
}

.cluster,
.plus {
    display: grid;
    gap: .1rem;
}

button.merge,
.plus {
    position: relative;
}

.fill-board,
.buttons {
    display: grid;
}

.fill-board {
    align-items: center;
    justify-items: center;
}

.three.buttons {
    grid-template-rows: 1.5rem 1fr 1.5rem;
}

.merged.buttons {
    background: yellow;
    position: relative;
}
.merged.buttons .fill-board i {
    width: 100%;
    top: -15%;
    left: 0;
}

button.merge span {
    bottom: 75%;
    left: 10%;
    width: 80%;
    padding: .5rem;
}

.plus {
    justify-items: center;
}

.plus .cover {
    width: 100%;
    height: 100%;
}

.square {
    width: .4rem;
    height: .4rem;
}

/* == 4. LAYOUT (AESTH.) = */
.merged.buttons.down button.merge:first-of-type,
.merged.buttons.up button.merge:last-of-type,
button.merge span {
    visibility: hidden;
}

button.merge,
.buttons button {
    border: none;
}

.cluster,
.buttons {
    transition: .4s ease-in-out;
}

.buttons button,
.plus .cover {
    background: transparent;
}

.merged.buttons,
.holiday-week.buttons {
    background: hsl( var( --hue ), var( --saturation ), calc( var( --lightness ) - 10% ) );
}

.buttons {
    background: hsl( var( --hue ), var( --saturation ), var( --lightness ) );
}

.buttons:not( .last-week ) {
    border-bottom: .1rem solid white;
}

.holiday-week button {
    cursor: not-allowed;
}

.fill-board > div {
    transform: scale( 2 );
}

button.merge {
    z-index: 2;
}

button.merge:first-of-type {
    transform: translateY( calc( -1 * var( --translate-distance ) ) );
    background: linear-gradient(
        to top,
        var( --colour1 ) var( --gradient-percentage1 ),
        var( --colour2 ),
        var( --colour1 ) var( --gradient-percentage2 )
    );
}
button.merge:last-of-type {
    transform: translateY( var( --translate-distance ) );
    background: linear-gradient(
        to bottom,
        var( --colour1 ) var( --gradient-percentage1 ),
        var( --colour2 ),
        var( --colour1 ) var( --gradient-percentage2 )
    );
}

button.merge span {
    backdrop-filter: blur( .25rem );
    background: hsl( var( --hue ), var( --saturation ), 100%, .5 );
    border: .1rem solid white;
    border-radius: .5rem;
    box-shadow: 0 4px 30px hsla( 0, 0%, 0%, 0.1 );
    opacity: 0;
    transition: .3s ease-in-out;
}

.cluster:nth-of-type( n + 2 ) {
    top: 0;
    transform: rotate( -90deg );
}

.square {
    --radius: .1rem;

    border-radius: var( --radius );
    background: white;
    transition: .3s ease-in-out;
    transition-delay: .3s;
}

/* == 5. STATES & PSEUDO = */
.buttons:not( .holiday-week, .merged ):hover {
    background: hsl( var( --hue ), var( --saturation ), calc( var( --lightness ) + 10% ) );
}

.buttons:hover .cluster.num2 {
    top: -.5rem;
}
.buttons:hover .cluster.num3 {
    top: .5rem;
}

button.merge:hover span {
    visibility: visible;
    opacity: 1;
    bottom: 125%;
}

.buttons:hover .cluster.num2 .square:first-child {
    border-top-right-radius: calc( 2 * var( --radius ) );
}
.buttons:hover .cluster.num2 .square:last-child {
    border-bottom-right-radius: calc( 2 * var( --radius ) );
}
.buttons:hover .cluster.num3 .square:first-child {
    border-top-left-radius: calc( 2 * var( --radius ) );
}
.buttons:hover .cluster.num3 .square:last-child {
    border-bottom-left-radius: calc( 2 * var( --radius ) );
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */