.dots {
    display: inline-flex;
    gap: .25em;
    padding: .5em .25em .25em;
    background: hsl( 50, 100%, 80%, .5);
    border-radius: .25rem;
}

.dot {
    display: inline-block;
    width: .25em;
    height: .25em;
    border-radius: 50%;
    background: goldenrod;
    animation: jumping 1.2s linear infinite;
}

.dot:last-of-type {
    animation-delay: .6s;
}
.dot:nth-last-of-type( 2 ) {
    animation-delay: .3s;
}

@keyframes jumping {
    0% {
        transform: translate( 0, 0 );
    }
    16% {
        transform: translate( 0, -.3em );
    }
    33% {
        transform: translate( 0, 0 );
    }
    100% {
        transform: translate( 0, 0 );
    }
}