.form {
    display: grid;
    grid-template-columns: 50% 40%;
    justify-content: space-between;
    width: 100%;
    /* background: pink; */
    padding: 1rem;
}
.form fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

.directions,
.points-directions-date {
    position: relative;
}

fieldset.points-directions-date {
    background: white;
    padding: .5rem 1rem;
    border-radius: 1rem;
}

.category-and-attachments .gradebook-category,
.directions {
    /* padding-bottom: 2.5rem; */
    margin: 0;
}

.directions label {
    display: grid;
    grid-template-columns: max-content 5rem;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.points-directions-date .directions textarea {
    min-height: 5rem;
    font-size: .9em;
    /* padding-bottom: 3rem; */
}

/* fieldset.points-and-date {
    position: absolute;
    left: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: 5rem 1fr;
    align-items: center;
    width: 100%;
    gap: 1rem;
    justify-items: end;
    padding-left: .5rem;
    padding-right: .25rem;
    height: 2.5rem;
} */

.points {
    display: flex;
    align-items: center;
    white-space: nowrap;
    height: 2rem;
    /* margin-bottom: 10%; */
}

fieldset.date {
    padding: .5rem 0;
}
.date input[ type='date' ] {
    margin: 0;
}

.form input[ type='number' ]::-webkit-inner-spin-button,
.form input[ type='number' ]::-webkit-outer-spin-button,
.form input[ type='number' ] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
}
.form input[ type='number' ] {
    text-align: end;
}

.points .suffix,
.ungraded,
.date {
    font-size: .75rem;
}
.ungraded {
    white-space: normal;
    padding-left: .5rem;
}

.category-and-attachments {
    display: grid;
    align-content: space-between;
}
fieldset.links {
    padding-bottom: .5rem;
}

.date {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.date > button {
    border-bottom-left-radius: 0;
    padding: .25rem .5rem;
    background: wheat;
}

.date label {
    align-self: center;
}