.pill {
    --active-background: linear-gradient( to top right, orangered, orange );
    --inactive-colour: darkgrey;

    font-size: .8rem;
    font-weight: 900;
    padding: .25em 1.25em;
    border-radius: 2em;
    border: none;
    background: white;
}
.pill:not( .sans-transition ) {
    transition: .1s ease-in-out;
}

.pill.active {
    background: var( --active-background );
    color: white;
}
.pill.inactive {
    color: var( --inactive-colour );
}

button.pill:hover {
    transform: translate( -.05em, -.05em );
}