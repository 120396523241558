.stepper fieldset {
    border: none;
    margin: 0;
}

.stepper {
    display: grid;
}
.stepper:not( .complete ) {
    grid-template-columns: max-content 1fr;
}

.step {
    transition: .3s ease-in-out;
}

.step form {
    display: grid;
    gap: 2rem;
}

.buttons {
    display: flex;
    gap: 1rem 2rem;
}

.form-finish {
    display: grid;
    text-align: center;
    gap: 2rem;
    height: min-content;
    /* width: 100%; */
}