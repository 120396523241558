.container {
    padding: 2em;
    --odd-colour: 'brown';
    --even-colour: 'gold'
}

.container > header {
    margin-bottom: 2em;
}
.container > header h1 {
    margin: 0;
}
.container > header p {
    font-size: .8em;
    text-align: center;
}
.container > header button {
    font-size: .75em;
    margin-left: .5em;
}

.container > nav ul {
    display: flex;
}
.container > nav li:first-of-type button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.container > nav li:not( :first-of-type, :last-of-type ) button {
    border-radius: 0;
}
.container > nav li:last-of-type button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.container fieldset {
    border: 2px solid black;
    padding: 1em 2em;
    margin: 2em 0;
    position: relative;
}
.container fieldset > button {
    position: absolute;
    top: -1.5em;
    right: 0;
}

.container legend {
    /* margin-left: .5em; */
    padding-inline-start: .5em;
    padding-inline-end: .5em;
}
.container legend button {
    padding: .25em 1em;
}

.user-details-list li:not( .schedule-row ) {
    margin-bottom: 1em;
}

.container legend,
.sub-label {
    font-weight: 900;
}

.time-slot-contents,
.schedule-row,
.schedule,
.courses {
    display: grid;
}

.schedule {
    gap: 2em;
}

.schedule-row {
    /* grid-auto-flow: column; */
    /* justify-items: start; */
    grid-template-columns: 3em 1fr 3em 1fr;
}

/* .slot {
    display: grid;
    grid-template-columns: min-content 1fr;
} */

.time-slot-contents {
    grid-template-areas: " courses ";
}
.time-slot-contents > * {
    grid-area: courses;
}

.courses {
    gap: .5em;
    align-items: center;
    padding: .25em .75em;
    /* position: relative; */
}

.time-slot-label {
    background: red;
    padding: .25em .0em;
    font-size: 1.5em;
    text-align: center;
}

.time-slot-label.odd,
.odd .colour {
    background: var( --odd-colour );
}
.time-slot-label.even,
.even .colour {
    background: var( --even-colour );
}
.colour {
    opacity: .25;
}