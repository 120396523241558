h1 {
    padding-top: 5rem;
}

.tiers > ul {
    display: grid;
    align-items: start;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    padding: 2rem;
}

.tier {
    display: grid;
    padding: 2rem;
    border: .25rem solid goldenrod;
    border-radius: 1rem;
}

.tier:nth-child(even) {
    border-color: darkred;
}

.tier section,
.tier button {
    margin-top: 1rem; 
}

.tier button {
    margin-top: 2rem;
}