/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES & PSEUDO
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
.primary,
.button {
    font-weight: 900;
    font-size: clamp(1.25rem, 18px, 4vw);
    letter-spacing: .1rem;
}

.secondary,
.primary {
    color: var(--font-colour-on-dark-background);
}

.button {
    text-align: center;
}

.caret {
    font-size: 1.25rem;
}

.clip-to-text {
    color: transparent;
    font-size: 2rem;
}

/* == 2. LAYOUT (PHYS.) == */
.primary,
.button {
    padding: .5rem 1rem;
}

.clip-to-text {
    padding: 0;
    margin: 0;
}

/* == 3. LAYOUT (AESTH.) = */
.primary,
.button {
    border-radius: .25rem;
}

.button {
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.primary {
    background: var(--primary-gradient);
}

.secondary {
    /* background: var(--secondary-gradient); */
    background: var(--secondary-gradient-new);
}

.secondary,
.primary {
    background-size: 400% 100%;
    transition-duration: .3s;
}

.clip-to-text {
    background-clip: text;
    border: none;
}

.up {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.down {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.left {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.right {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

/* == 4. STATES & PSEUDO = */
.button:not( :disabled ):hover {
    transform: scale(1.1);
    animation: ButtonShine 1s ease 1;
}

.button:not( :disabled ).right:hover {
    transform: translateX(.5rem);
}
.button:not( :disabled ).left:hover {
    transform: translateX(-.5rem);
}
.button:not( :disabled ).up:hover {
    transform: translateY(.5rem);
}
.button:not( :disabled ).down:hover {
    transform: translateY(-.5rem);
}

.button:disabled {
    cursor: auto;
    opacity: .5;
}

/* == 5. ANIMATIONS ====== */
@keyframes ButtonShine {
    0% { background-position: 100% 100%; }
    100% { background-position: 0% 0%; }
}

/* == 6. @MEDIA ========== */