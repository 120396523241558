.user-icon {
    position: relative;
}
.premium.user-icon {
    transform: translateY( .25rem );
}

.icon {
    width: 2rem;
    border-radius: 50%;
    border: 3px solid white;
    transform: scale( 1.25 );
}

.crown {
    position: absolute;
    top: -1rem;
    left: -.25rem;
    width: 1.25rem;
    transform: rotate( -25deg );
}