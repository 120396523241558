/* ======== MENU ============
* 1. VARIABLES
* 2. TYPOGRAPHY
* 3. LAYOUT (PHYS.)
* 4. LAYOUT (AESTH.)
* 5. STATES
* 6. ANIMATIONS
* 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.text-input {
    --translate-x: 0;
    --translate-y: -1.75rem;
    --background: black;
    --padding-left: .25rem;
    --padding-right: .25rem;
    --text-colour: transparent;
}

/* == 2. TYPOGRAPHY ====== */
.label {
    color: black;
    font-style: normal;
    font-weight: 900;
    text-transform: uppercase;
}

.to-top .label,
.hide .label {
    color: grey;
    font-style: italic;
    text-transform: capitalize;
}

.input {
    font-size: 1.05rem;
    text-overflow: ellipsis;
}

/* .input.has-value + label {
    color: red;
} */

/* == 3. LAYOUT (PHYS.) == */
.text-input {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: .5rem;
    margin: .25rem;
}

.text-input.on-left {
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.text-input.on-top {
    flex-direction: column-reverse;
    gap: .25rem;
}

.to-top .label,
.hide .label {
    position: absolute;
    left: var( --padding-left );
    top: .25rem;
}

.on-top,
.on-left {
    display: flex;
}

.on-top {
    align-items: flex-start;
}

.input {
    min-height: 1.75rem;
    /* max-height: max( 1.75rem, auto ); */
    padding-left: var( --padding-left );
    padding-right: var( --padding-right );
    width: 100%;
}

.text-input textarea.input {
    min-height: 7.5rem;
    resize: vertical;
}

.on-left input[ type = 'number' ] {
    width: 3rem;
}

.to-top {
    margin-top: 1.75rem;
}

/* == 4. LAYOUT (AESTH.) = */
.to-top .label,
.hide .label,
.input {
    transition: .3s ease-in-out;
}

.input {
    border: 2px solid white;
    background: var( --secondary-colour-pale );
    border-radius: .25rem;
}

.input.has-value {
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
}

/* == 5. STATES & PSEUDO = */
.to-top .input:focus-visible + .label,
.to-top .input.has-value + .label,
.to-top .input:focus + .label {
    transform: translate( var( --translate-x ), var( --translate-y ) );
    background-color: var( --background );
    background-clip:  text;
    color: var( --text-color );
    font-style: normal;
    font-weight: 900;
    text-transform: uppercase;
}

.hide .input:focus-visible + .label,
.hide .input.has-value + .label,
.hide .input:focus + .label {
    display: none;
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */