/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

/* == 1. VARIABLES ======= */
.tooltip {
    --x-padding: .5rem;
    --y-padding: 1rem;
    --background: hsl( 0, 0%, 15%, .9 );
    --width: fit-content;
    --distance-from-relative: 1.75rem;
    --arrow-size: .5rem;
}

/* == 2. TYPOGRAPHY ====== */
.tooltip {
    color: white;
    font-size: .9rem;
    word-break: keep-all;
}

/* .tooltip p span { */
.tooltip > * > span {
    color: hsl( 0, 50%, 75% );
}

/* == 3. LAYOUT (PHYS.) == */
.tooltip {
    display: block;
    position: absolute;
    width: var( --width );
    padding: var( --x-padding ) var( --y-padding );
    z-index: 1;
}

.down,
.up {
    left: -100%;
}
.right,
.left {
    top: -40%;
}

.up.tooltip {
    top: var( --distance-from-relative );
}
.right.tooltip {
    right: var( --distance-from-relative );
}
.down.tooltip {
    bottom: var( --distance-from-relative );
}
.left.tooltip {
    left: var( --distance-from-relative );
}

/* == 4. LAYOUT (AESTH.) = */
.tooltip {
    border-radius: .5rem;
    background: var( --background );
    transition: .3s ease-in-out;
    cursor: context-menu;
}

/* == 5. STATES & PSEUDO = */
.up.arrow::before,
.right.arrow::after,
.down.arrow::after,
.left.arrow::before {
    content: '';
    transition: .3s ease-in-out;
}

.arrow::before,
.arrow::after {
    position: absolute;
}

.up.arrow::before {
    border-left: var( --arrow-size ) solid transparent;
    border-right: var( --arrow-size ) solid transparent;
    border-bottom: var( --arrow-size ) solid var( --background );
    top: calc( var( --x-padding ) * -1 );
    left: calc( var( --x-padding ) * 2 );
}
.right.arrow::after {
    border-top: var( --arrow-size ) solid transparent;
    border-bottom: var( --arrow-size ) solid transparent;
    border-left: var( --arrow-size ) solid var( --background );
    top: calc( var( --x-padding ) * 1 );
    right: calc( var( --x-padding ) * -1 );
}
.down.arrow::after {
    border-left: var( --arrow-size ) solid transparent;
    border-right: var( --arrow-size ) solid transparent;
    border-top: var( --arrow-size ) solid var( --background );
    bottom: calc( var( --x-padding ) * -1 );
    right: calc( var( --x-padding ) * 2 );
}
.left.arrow::before {
    border-top: var( --arrow-size ) solid transparent;
    border-bottom: var( --arrow-size ) solid transparent;
    border-right: var( --arrow-size ) solid var( --background );
    left: calc( var( --x-padding ) * -1 );
}
/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */