/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES & PSEUDO
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
/* == 2. LAYOUT (PHYS.) == */
.modal-container {
    position: absolute;
    left: 0;
    bottom: 0;
    display: grid;
    align-items: center;
    justify-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 9;
}

.modal {
    display: grid;
    width: 80%;
    height: max-content;
    padding: 2rem;
    z-index: 10;
}

.modal.sans-carets {
    grid-template-columns: 70vw;
}

.modal.with-carets {
    grid-template-areas: "left-caret content right-caret";
}

/* == 3. LAYOUT (AESTH.) = */
.modal-container {
    background-color: hsla(0, 100%, 0%, .5);
    cursor: pointer;
}

.modal {
    background: hsla(0, 0%, 100%, .5);
    backdrop-filter: blur(10px);
    border-radius: 1rem;
    border: 2px solid var(--font-colour-on-dark-background);
    cursor: default;
}

.modal.with-carets > *:nth-child(1) {
    grid-area: left-caret;
}

.modal.with-carets > *:nth-child(2) {
    grid-area: content;
}

.modal.with-carets > *:nth-child(3) {
    grid-area: right-caret;
}

/* == 4. STATES & PSEUDO = */
/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */