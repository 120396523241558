/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ====== */
/* == 2. TYPOGRAPHY ====== */
.label {
    font-size: .8rem;
}

/* == 3. LAYOUT (PHYS.) == */
.button {
    position: fixed;
}

.label {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: .25rem .5rem;
}

/* == 4. LAYOUT (AESTH.) = */
.button {
    opacity: 0;
}

.label {
    background-color: var(--secondary-colour-pale);
    border: 2px solid var(--secondary-colour-highlight);
    cursor: pointer;
}

/* == 5. STATES & PSEUDO = */
.button:focus + label,
.button:hover + label {
    background-color: var(--secondary-colour-pale-opaque);
    border-style: dashed;
}

.button:checked + .label {
    background-color: var(--secondary-colour-highlight);
    font-weight: 900;
}

/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */