.form,
.buttons {
    display: grid;
}

.form {
    grid-template-columns: 90%;
    gap: 1em;
    margin-top: 1em;
}

.buttons {
    grid-auto-flow: column;
    grid-template-columns: 40% 1fr;
}

.form input[ type="text" ],
.form input[ type="date" ] {
    width: 100%;
}

.form input[ type="date" ] {
    margin: 0;
}