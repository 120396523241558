/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. GENERAL ========= */
.button {
    --background: linear-gradient( to right, orangered, orange 40%, gold );
    --padding: 1em;
    --rotation-deg: 45deg;

    --gradient-rotation: 90deg;
    --gradient-from: white;
    --gradient-to: white;
    --hover-from: white;
    --hover-to: white;
    --shadow-from: gold;
    --shadow-to: orangered;

    --active-label-colour: black;
    --label-colour: black;
    --hover-text: black;
}

.line {
    --x-y: -.5em;
    --rotation: 45deg;
}
.line:first-of-type {
    --rotation-deg: calc( 180deg + var( --rotation ) );
}
.line:last-of-type {
    --rotation-deg: var( --rotation );
}

/* == 2. TYPOGRAPHY ====== */
.button {
    font-weight: 900;
    font-size: 1rem;
    letter-spacing: 1px;
    color: var( --label-colour );
}

.link {
    text-decoration: none;
}

/* == 3. LAYOUT (PHYS.) == */
.button {
    display: inline-grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    align-items: center;
    column-gap: .5rem;
    padding: .5em 2em;
    position: relative;
    justify-content: center;
}

.line {
    position: absolute;
    padding: 1em;
}
.line:first-of-type {
    left: var( --x-y );
    top: var( --x-y );
}
.line:last-of-type {
    right: var( --x-y );
    bottom: var( --x-y );
}

.drips {
    position: absolute;
    top: 100%;
    right: 1rem;
    display: flex;
    width: fit-content;
    height: fit-content;
}

.drip {
    position: absolute;
    top: 100%;
    right: 1%;
    display: inline-block;
    /* width: 1rem; */
    /* bottom: 0; */
}

/* == 4. LAYOUT (AESTH.) = */
.looks-like-link.can-fill-background,
.button > svg {
    transition: .3s ease-in-out;
}

.button {
    border-radius: 3em;
    border: none;
    box-shadow: .1em .1em .25em 0 hsl( 0, 0%, 30%, .25 );
    transition: .1s ease-in-out;
    background: linear-gradient( var( --gradient-rotation ), var( --gradient-from ), var( --gradient-to ) );
    cursor: pointer;
}

.line {
    border: .2em solid transparent;
    border-right-color: orange;
    border-radius: 50%;
    transition: .1s ease-in-out;
}
.line:first-of-type {
    transform: rotate3d( 0, 0, 1, var( --rotation-deg ) );
}
.line:last-of-type {
    transform: rotate3d( 0, 0, 1, var( --rotation-deg ) );
}

button.looks-like-link {
    background: none;
    box-shadow: none;
    padding: 1rem;
}
button.looks-like-link:hover {
    background: none;
}
button.looks-like-link:active {
    box-shadow: none;
}

/* .drips { */
    /* border: 3px solid black; */
    /* margin-left: auto; */
    /* background-image: var( --background ); */
/* } */

.drip {
    visibility: hidden;
    opacity: 0;
    fill: url( #honey-gradient ) orange;
    z-index: -1;
    /* border-radius: 0 0 1rem 1rem; */
    /* background-image: var( --background ); */
    /* background-size: 300%; */
    /* background-position: calc( 100% - calc( 100% - 1rem ) ); */
    /* background-clip: content-box; */
    /* background: transparent; */
    /* background-attachment: fixed; */
}
.drip:nth-of-type( 2 ) {
    transform: translateX( .25rem );
}

/* == 5. STATES & PSEUDO = */
.line::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: .15em solid transparent;
    border-right-color: orangered;
    padding: .75em;
    right: calc( var( --x-y ) * 1.25 );
    bottom: .25em;
    transform: rotate( -10deg );
}

.line,
.button:active .line {
    opacity: 0;
}

.looks-like-link.can-fill-background:hover,
.button:hover {
    background: linear-gradient( var( --gradient-rotation ), var( --hover-from ), var( --hover-to ) );
    color: var( --hover-text );
    transform: translate3d( -.05em, -.05em, 0 );
}
.button:active {
    transform: translate3d( .05em, .05em, 0 );
    background: linear-gradient( var( --gradient-rotation ), white, white );
    color: var( --active-label-colour );
    box-shadow: .1em .05em .25em 0 var( --shadow-from ), -.1em -.05em .25em 0 var( --shadow-to );
    /* background: orange; */
    /* color: black; */
    /* background-clip: padding-box, border-box; */
    /* background-origin: border-box; */
}

.fill-svg-on-hover:hover path,
.fill-svg-on-hover:hover svg,
.fill-svg-on-hover:hover g {
    opacity: 1;
    fill-opacity: 1;
}

.scale-svg-on-hover:hover svg {
    transform: scale( 1.25 );
}

.button:hover .drip {
    opacity: 1;
    visibility: visible;
}

.button:hover .line {
    opacity: 1;
    animation: shake;
    animation-fill-mode: both;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.button::after {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3em;
    content: "";
    width: 100%;
    height: 100%;
}

/* == 6. ANIMATIONS ====== */
@keyframes shake {
    20% {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), 15deg); */
      transform: rotate3d( 0, 0, 1, calc( var( --rotation-deg ) + 15deg ) );
    }

    40% {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), -10deg); */
      transform: rotate3d( 0, 0, 1, calc( var( --rotation-deg ) - 10deg ) );
    }

    60% {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), 5deg); */
      transform: rotate3d( 0, 0, 1, calc( var( --rotation-deg ) + 5deg ) );
    }

    80% {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), -5deg); */
      transform: rotate3d( 0, 0, 1, calc( var( --rotation-deg ) - 5deg ) );
    }

    to {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), 0deg); */
      transform: rotate3d( 0, 0, 1, var( --rotation-deg ) );
    }
}

/* == 7. @MEDIA ========== */