/* ======== MENU ============
* 1. GENERAL
* 2. TYPOGRAPHY
* 3. LAYOUT (PHYS.)
* 4. LAYOUT (AESTH.)
* 5. STATES
* 6. ANIMATIONS
* 7. @MEDIA
========================== */

/* == 1. GENERAL ========= */
/* == 2. TYPOGRAPHY ====== */
/* == 3. LAYOUT (PHYS.) == */
.assignment {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 5rem 5rem;
    gap: .5rem;
}

/* == 4. LAYOUT (AESTH.) = */
/* == 5. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */