/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
/* == 3. LAYOUT (PHYS.) == */
.calendar {
    margin: 0 2rem;
}

/* == 4. LAYOUT (AESTH.) = */
/* == 5. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */