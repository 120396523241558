/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ========= */
/* == 2. TYPOGRAPHY ====== */
.knock-knock li i {
    font-style: normal;
}

.form header p {
    text-align: center;
}

/* == 3. LAYOUT (PHYS.) == */
.knock-knock li,
.knock-knock,
.container,
.form {
    display: grid;
}

.form h1,
.form p {
    padding: 0;
    margin: 0;
}

.container {
    align-items: center;
    justify-items: center;
    height: 100vh;
}

.knock-knock {
    gap: 1rem;
}

.knock-knock li:not( .input-li )  {
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: 2.5rem 1fr;
    padding: .5rem 0;
}

/* .knock-knock li:not( :last-of-type ) i:first-of-type { */
.knock-knock li > i {
    padding-left: 1rem;
}

.form {
    padding: 3rem;
}

.form header {
    margin-bottom: 2rem;
}

.input-field {
    position: relative;
}

.input-icon {
    position: absolute;
    z-index: 1;
    bottom: 4.5rem;
    /* left: 1rem; */
}

.message p {
    text-align: start;
}

/* == 4. LAYOUT (AESTH.) = */
.not-accessed .input-icon,
.not-accessed .message p,
.not-accessed input {
    outline-color: red;
    animation-name: shake;
    animation-duration: .2s;
    animation-iteration-count: 3;
}

.not-accessed .input-li,
.not-accessed .message p {
    color: red;
}

.input-field,
.form input {
    transition: .3s ease-in-out;
}

.form {
    box-shadow: 0 4px 30px 1px hsla( 0, 0%, 0%, 0.1 );
    /* box-shadow: 0 0 15px 1px rgba( 0, 0, 0, 0.4 ); */
    border-radius: .5rem;
    background: white;
}

.accessed input {
    outline-color: greenyellow;
}
.accessed .input-li {
    color: green;
}

.knock-knock li:nth-of-type( 2n ) > i:first-of-type {
    animation-name: float;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

.input-field {
    background: none;
    border: none;
}

/* == 5. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
@keyframes shake {
    0% { transform: translate3d( 0, 0, 0 ); }
    25% { transform: translate3d( 8px, 0, 0 ); }
    75% { transform: translate3d( -8px, 0, 0 ); }
    100% { transform: translate3d( 0, 0, 0 ); }
}

@keyframes float {
    0% { transform: translate3d( .5rem, 0%, 0 ) rotate3d( 0, 1, 0, 180deg ); }
    50% { transform: translate3d( .5rem, 8%, 0 ) rotate3d( 0, 1, 0, 180deg ); }
    100% { transform: translate3d( .5rem, 0%, 0 ) rotate3d( 0, 1, 0, 180deg ); }
}

@keyframes pulse {
    0% { transform: rotate3d( 0, 0, 0, 0deg ); }
    100% { transform: rotate3d( 0, 0, 1, 1turn ); }
}

/* == 7. @MEDIA ========== */
