/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES & PSEUDO
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
.label {
    text-transform: uppercase;
}

.label.active {
    font-weight: 900;
}

/* == 2. LAYOUT (PHYS.) == */
.toggle-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.label {
    margin: 0 1rem;
}

.toggle {
    position: relative;
    height: 1.5rem;
    width: 5rem;
}

.button {
    position: absolute;
    top: 10%;
    left: 2.5%;
    height: 80%;
    /* height: 1rem; */
    width: 2rem;
}
.on-right {
    left: calc(97.5% - 2rem);
}

/* == 3. LAYOUT (AESTH.) = */
.toggle {
    background: var(--neutral-background-colour-dark);
    border: 2px solid var(--secondary-colour-light);
    border-radius: 2rem;
}

.toggle.active {
    background: var(--secondary-gradient);
}

.button {
    background-color: white;
    border-radius: 2rem;
}

.toggle,
.button,
.label {
    transition: .3s ease-in-out;
}

/* == 4. STATES & PSEUDO = */
/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */