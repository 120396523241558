.container {
    display: grid;
    grid-template-areas: "content";
    /* background: hsl(35, 50%, 20%); */
    background: hsl( 35, 0%, 25% );
    color: white;
}
.container > * {
    grid-area: content;
}

.solutions {
    padding: 0 0 2rem;
}
.solutions > header {
    padding: 3rem 0 1rem;
    text-align: center;
    text-transform: uppercase;
}

.list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    /* justify-content: center; */
}

.solution:not( .with-cover ),
.solution .details {
    display: grid;
    grid-template-columns: 2rem 1fr;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.solution {
    flex: 1 1 35vw;
    /* grid-template-areas: "solution"; */
    background: hsl( 35, 0%, 0%, .35 );
    border-radius: 1em;
    margin: 1em;
}

.solution p {
    font-size: .75rem;
}

.title {
    font-size: 1em;
    font-weight: 900;
    text-transform: capitalize;
}

/* .solution > * {
    grid-area: solution;
    width: fit-content;
} */

/* .cover {
    cursor: pointer;
    background-color: pink;
} */

/* @media only screen and ( min-width: 768px ) {
    .list {
        column-gap: 1em;
    }

    .details {
        width: fit-content;
    }

    .details p {
        width: 35vw;
    }
} */