.subjects {
    padding: 2em;
}
/* .curriculum-container > header, */
.subject > header,
.level > header {
    padding-bottom: 1em;
}
.curriculum-container {
    /* padding: 1em 2em; */
    margin-right: 2em;
}
/* .curriculum-container > header { */
    /* text-align: left; */
    /* padding-left: 2em; */
/* } */

.subject > header {
    display: grid;
    grid-template-columns: min-content max-content 1fr;
    gap: 1em;
    align-items: center;
}

/* .curriculum-container > header span {
    margin: 0 2em;
    font-size: 1.25em;
} */

.curriculum h3,
.subject h3 {
    margin: 0;
    white-space: nowrap;
}

.levels {
    display: flex;
    flex-wrap: wrap;
    gap: 1em 2em;
}

.themes {
    display: flex;
    flex-flow: column wrap;
    gap: 2em;
    font-size: .75em;
}
.tile-container {
    display: contents;
}
.theme {
    display: flex;
    position: relative;
    align-items: center;
    /* gap: 0 1em; */
    /* width: 25vw; */
    /* min-width: 5em; */
    /* width: fit-content; */
    padding: 1em;
    border-radius: 1em;
}

.expand-button,
.curriculum,
.theme > * {
    transition: .3s ease-in-out;
}
/* .curriculum-container.show-all .hide-all .theme i, */
.theme i {
    font-size: 1.5em;
    font-style: normal;
    flex: 1;
    text-align: center;
}
/* .curriculum-container.collapsed .curriculum, */
.collapsed .expand-button,
.theme :is( button:not( .add ), p ) {
    opacity: 0;
    font-size: 0em;
    visibility: hidden;
    white-space: nowrap;
    padding: 0;
}
/* .curriculum-container.show-all .hide-all .theme > :is( div, .add ), */
.theme > :is( div, .add ) {
    display: block;
    position: absolute;
    font-size: .75em;
    top: -.75em;
    right: -.75em;
    padding: .5em 1em;
    border-radius: 50%;
    background: gold;
}

.show-all .theme,
.theme:not( .placeholder ):hover {
    gap: 0 1em;
    justify-content: normal;
}
.show-all .theme i,
.theme:not( .placeholder ):hover i {
    flex: 0;
    padding-left: 0;
    font-size: 1em;
    width: fit-content;
}
.show-all .theme :is( button:not( .add ), p ),
.theme:not( .placeholder ):hover :is( button:not( .add ), p ) {
    font-size: 1em;
    opacity: 1;
    visibility: visible;
}
.show-all .theme button:not( .add ),
.theme:not( .placeholder ):hover button:not( .add ) {
    padding: .1em .5em;
    /* align-content: center; */
    /* display: flex; */
}
.show-all .theme :is( .subtitle, button:not( .add ) ),
.theme:not( .placeholder ):hover :is( .subtitle, button:not( .add ) ) {
    font-size: .75em;
}
.show-all .theme > :is( div, .add ),
.theme:not( .placeholder ):hover > :is( div, .add ) {
    opacity: 0;
    display: block;
    visibility: hidden;
}

.curriculum {
    empty-cells: show;
    border-spacing: 2em 1em;
    display: grid;
    gap: 1em;
}
.curriculum caption {
    caption-side: bottom;
}

.curriculum.collapsed {
    visibility: collapse;
}

/* .curriculum-container {
    background: grey;
    border: 2px solid yellow;
    overflow-x: scroll;
} */
/* .curriculum-container.collapsed .curriculum {} */