/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
.menu-item > *:not( .emoji, .link, a ),
.subnav header {
    background: radial-gradient( circle farthest-side at 30% 10%, orangered 50%, orange, gold );
    background-clip: text;
    color: transparent;
}

.subnav i {
    font-style: normal;
}

.frosty,
.subnav header {
    font-weight: 900;
}

.link a {
    text-decoration: none;
    color: black;
}

.category > span {
    display: block;
    margin-left: 1rem;
    margin-top: 1rem;
    color: hsl( 45, 0%, 30% );
}

/* == 3. LAYOUT (PHYS.) == */
.menu-item {
    display: grid;
    align-items: center;
    grid-template-columns: min-content 1fr;
    gap: 1rem;
    padding: 1rem 2rem;
}
.menu-item.without-icon {
    grid-template-columns: 1fr;
}

.menu {
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

/* .navbar > section, */
.subnav {
    position: relative;
}

.subnav header {
    align-self: center;
    margin-left: .5rem;
}

.background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -2;
}

.menu {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    left: 0rem;
    top: 2rem;
    z-index: 10;
    transition: .3s ease-in-out;
}
.menu.show {
    top: 100%;
    opacity: 1;
    visibility: visible;
}

.link {
    position: relative;
}

.img-container {
    display: flex;
    align-items: center;
}
.img-container img {
    width: 1rem;
}

/* == 4. LAYOUT (AESTH.) = */
.menu-item:last-of-type,
.frosty,
.menu {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.menu {
    background: hsl( 45, 100%, 50%, .1 );
    backdrop-filter: blur( 4px );
    box-shadow: 0 4px 30px 1px hsla( 0, 0%, 0%, 0.1 );
}

.menu-item > * {
    transition: .3s ease-in-out;
}

.menu {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background: hsl( 45, 100%, 85%, .5 );
    backdrop-filter: blur( 4px );
    /* box-shadow: -2.5px 7.5px 10px 1px hsla( 0, 0%, 0%, 0.1 ); */
}

.subnav > header,
.menu-item {
    cursor: pointer;
}
.menu-item:not( .frosty, .link ) > * {
    filter: brightness( 0 );
}

.frosty {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background: hsl( 45, 100%, 100%, .75 );
    backdrop-filter: blur( 8px );
}
.frosty img {
    width: 1.25rem;
}

/* == 5. STATES & PSEUDO = */
.subnav header:hover + .menu,
.menu:hover {
    visibility: visible;
    opacity: 1;
    top: 1.5rem;
}

.menu-item:hover {
    background: hsl( 45, 100%, 100%, .75 );
}

.menu-item:hover > * {
    transform: scale( 1.1 );
}

.menu-item:hover .label {
    font-weight: 900;
}
.menu-item:not( :last-of-type ):hover i:not( .emoji ) {
    filter: brightness( 100% );
}

.link .active-link::before {
    content: '';
    position: absolute;
    background: hsl( 45, 100%, 50%, .25 );
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */