/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
.slide h2 {
    font-size: 1.5rem;
}

.slide sup {
    font-size: .75rem;
}

.text span i {
    font-size: .5rem;
}

.text li:last-of-type i {
    color: var(--secondary-colour);
}

/* == 2. LAYOUT (PHYS.) == */
.slide h1,
.slide h2 {
    margin: 0;
    padding: 0;
}

.slide {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-areas: "h1 h1"
                         "img text"
                         "links links";
    grid-template-columns: 40% 50%;
    gap: 1rem;
}

.slide h1 {
    grid-area: h1;
}

.image {
    grid-area: img;
}

.image img {
    width: 105%;
}

.text {
    grid-area: text;
}

.links {
    grid-area: links;
}

/* == 3. LAYOUT (AESTH.) = */
.image img {
    transform: translateX(-5%);
}

.text li {
    list-style: '';
}

.links {
    border-top: 2px inset var(--font-colour-on-dark-background);
}

/* == 4. STATES & PSEUDO = */
/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */