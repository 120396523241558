.sortable {
    display: grid;
    transition: .3s ease-in-out;
}
.sortable.wrap-with-shadow {
    padding: .5rem 1rem;
    box-shadow: 0 4px 30px hsla( 0, 0%, 0%, 0.1 );
}

.sortable .button {
    justify-content: center;
}

.sortable.on-top {
    padding-top: 0;
}
.sortable.on-bottom {
    padding-bottom: 0;
}
.sortable.on-left {
    padding-left: 0;
}
.sortable.on-right {
    padding-right: 0;
}

.sortable:not( .with-handle ),
.sortable .button {
    cursor: grab;
}

.sortable.on-bottom,
.sortable.on-top {
    grid-auto-flow: row;
}
.sortable.on-right,
.sortable.on-left {
    grid-auto-flow: column;
}

.on-bottom .button,
.on-top .button {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 2em;
}
.on-right .button,
.on-left .button {
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 2em;
}

.on-bottom .button,
.on-right .button {
    order: 1;
}

.dragging {
    opacity: .5;
}
.sorting:not( .disabled ) {
    background: hsl( 45, 100%, 90% );
}
.sorting.disabled {
    background: hsl( 45, 0%, 85% );
    color: hsl( 45, 0%, 50% );
}

.over:not( .disabled ) {
    background: hsl( 35, 100%, 50% );
}

.action-items,
.action-items > p {
    display: flex;
}

.action-items {
    align-items: center;
    background: hsl( 45, 100%, 100%, .5 );
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;
    gap: .5rem;
}

.action-items > * {
    font-size: .75rem;
}

.action-items > p {
    font-style: italic;
    gap: .25rem;
}