/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */
 .page-links {
    display: flex;
    gap: 1em;
 }

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
.info header li:nth-of-type( -n + 1 ),
.details > li > span,
.buttons button,
.details > span {
    text-transform: uppercase;
}

span.setting-header,
span.sub-setting,
.details a {
    color: black;
}

.info header li:nth-of-type( -n + 1 ),
.info header li > button,
.details > li > i {
    color: brown;
}

.details > *:nth-child( n + 4 ),
.info header {
    text-align: center;
}

.info header li:nth-of-type( -n + 1 ),
.info header li > button {
    font-size: .8rem;
    font-weight: 900;
}

.info h2 {
    font-size: 1.5rem;
}

.details > li {
    font-weight: 900;
    white-space: nowrap;
}

.details .rule {
    font-weight: 300;
}

.details a {
    font-size: .9rem;
}

.profile > button > button {
    font-size: 1rem;
}

.profile i {
    font-style: normal;
}

/* == 3. LAYOUT (PHYS.) == */
.rules ul,
.profile,
.courses {
    display: grid;
}

.profile h2,
.profile h3 {
    margin: 0;
}

.profile,
.rule {
    position: relative;
}

.profile {
    grid-template-rows: max-content 1fr;
    overflow: hidden;
    height: 100vh;
}

.info header {
    margin-bottom: 2rem;
}

.rules > ul {
    gap: .5rem;
}

.boxes {
    grid-auto-flow: column;
}

/* == 4. LAYOUT (AESTH.) = */
.profile {
    transition: .3s ease-in-out;
}

.info header > img {
    border: 4px solid gold;
    border-radius: 25%;
    margin-bottom: 1rem;
}

/* == 5. STATES & PSEUDO = */
.profile > button:last-of-type:hover,
.menu-item:hover > * {
    transform: scale( 1.1 );
}

.profile {
    --colour-1: hsl( 45, 70%, 50% ); /* -165 */
    --colour-3: hsl( 55, 90%, 70% ); /* -155 */
    --colour-2: hsl( 50, 65%, 65% ); /* -120 */
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */