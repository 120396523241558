.input-fieldset,
.form {
    --font-size: 1rem;
}

.form {
    display: flex;
}

.input-fieldset {
    position: relative;
    transition: .3s ease-in-out;
}

.input-fieldset label {
    position: absolute;
    font-size: var( --font-size );
    left: var( --font-size );
    top: calc( 75% - var( --font-size ) - .25rem );
    transition: .3s ease-in-out;
    cursor: text;
    color: black;
}

.input-fieldset:not( .colour ) label,
.colour input {
    cursor: pointer;
}

.input-fieldset:not( .colour ) input {
    border-radius: .5rem;
}
.fill-all-borders:not( .colour, .fill-all-borders ) input {
    border: 1px solid black;
}
.input-fieldset:not( .colour, .fill-all-borders ) input {
    border: 2px solid transparent;
    border-bottom-color: hsl( 45, 0%, 65% );
    background: hsl( 45, 0%, 95%, .75 );
}

.colour input {
    --h: 45;
    --s: 100%;
    --l: 50%;

    border-radius: 50%;
    /* border: none; */
    width: 1rem;
    padding: 1rem;
    background: hsl( var( --h ), var( --s ), var( --l ) );
    border: 2px solid hsl( var( --h ), var( --s ), calc( var( --l ) - 5% ) );
    /* border: none; */
}
.colour input::after {
    content: '';
    width: 50%;
    height: 50%;
    right: 25%;
    bottom: 25%;
    background: inherit;
    border-radius: inherit;
    position: absolute;
}

.input-fieldset:not( .colour ) input {
    padding: 1.25rem 1rem .25rem;
}

.form .input-fieldset:not( .colour ) input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.form button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-fieldset.has-value label,
.input-fieldset input:focus + label {
    top: .25rem;
    font-size: calc( var( --font-size ) * .7 );
    color: hsl( 45, 0%, 45% );
}

.input-fieldset.with-icon > input {
    padding-left: 2.5em;
}
.input-fieldset.with-icon > label {
    padding-left: 2em;
}
.icon {
    position: absolute;
    display: grid;
    align-items: center;
    height: 100%;
    width: 2.5em;
    /* border: 2px solid red; */
    text-align: center;
}