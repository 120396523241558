.container {
    --hue: 45;
    --saturation: 100%;
    --lightness: 50%;

    display: flex;
    flex-flow: row wrap;
    position: absolute;
    top: -3.5rem;
    margin: 0;
    padding: 1rem 0;
    width: 100%;
    background: hsl( var( --hue ), var( --saturation ), 90%, .5 );
    justify-content: center;
}

.container fieldset,
.inputs > *,
.inputs {
    display: grid;
    grid-auto-flow: column;
}

.container fieldset,
.container {
    border: none;
}

.container > *:not( :last-child ) {
    margin-right: 1rem;
}

.container button {
    font-size: .75em;
    padding: .25em .5em;
}

.container fieldset {
    padding: 0;
}

.inputs {
    gap: 1rem;
}

.inputs input {
    --background: white;
    --grey: hsl( var( --hue ), 0%, 70% );

    border: 2px solid transparent;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    border-radius: 1em;
    height: .75em;
    background: var( --background );
    transition: .3s ease-in-out;
}
.inputs input::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    height: .5em;
    width: .4em;
    /* border-radius: 50%; */
    box-shadow: -10em 0 0 0 hsl(0, 0%, 19%), -9em 0 0 0 hsl(0, 0%, 19%),
       -4em 0 0 0 hsl( var( --hue ), var( --saturation ), 48% ),
       -3.5em 0 0 0 hsl( var( --hue ), var( --saturation ), 52% ),
       -3em 0 0 0 hsl( var( --hue ), var( --saturation ), 56% ),
       -2.5em 0 0 0 hsl( var( --hue ), var( --saturation ), 60% ),
       -2em 0 0 0 hsl( var( --hue ), var( --saturation ), 64% ),
       -1.5em 0 0 0 hsl( var( --hue ), var( --saturation ), 68% ),
       -1em 0 0 0 hsl( var( --hue ), var( --saturation ), 72% ),
       -.5em 0 0 0 hsl( var( --hue ), var( --saturation ), 76% ),
       .5em 0 0 0 var( --grey ),
       1em 0 0 0 var( --grey ),
       1.5em 0 0 0 var( --grey ),
       2em 0 0 0 var( --grey ),
       2.5em 0 0 0 var( --grey ),
       3em 0 0 0 var( --grey ),
       3.5em 0 0 0 var( --grey ),
       4em 0 0 0 var( --grey );
    transition: .3s ease-in-out;
    background: hsl( var( --hue ), var( --saturation ), 80% );
}
.inputs input::-webkit-slider-runnable-track {
    height: .5em;
    border-radius: 1em;
}

.inputs > * {
    grid-template-columns: 1.5rem 4rem;
    align-items: center;
}

.inputs input:hover {
    border-color: hsl( var( --hue ), var( --saturation ), var( --lightness ) );
}