/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

.page nav {
    /* display: grid; */
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}
.page nav > section:nth-child( 3 ) {
    visibility: hidden;
    display: none;
}
/* .page > section {
    display: grid;
    align-items: center;
    justify-items: center;
} */

/* == 1. VARIABLES ======= */
.page {
    --colour-honey: hsl( 45, 100%, 95% );
    --colour-border: hsl( 35, 85%, 75% );
    --size: 20px;

    --background: radial-gradient(
        circle farthest-side at 0% 50%,
        var( --colour-honey ) 23.5%,
        rgba( 240, 166, 17, 0 ) 0
    ) calc( var( --size ) * .7 ) var( --size ),
    radial-gradient(
        circle farthest-side at 0% 50%,
        var( --colour-border ) 24%,
        rgba( 240, 166, 17, 0 ) 0
    ) calc( var( --size ) * 19/30 ) var( --size ),
    linear-gradient(
        var( --colour-honey ) 14%,
        rgba( 240, 166, 17, 0 ) 0,
        rgba( 240, 166, 17, 0 ) 85%,
        var( --colour-honey ) 0
    ) 0 0,
    linear-gradient(
        150deg,
        var( --colour-honey ) 24%,
        var( --colour-border ) 0,
        var( --colour-border ) 26%,
        rgba( 240, 166, 17, 0 ) 0,
        rgba( 240, 166, 17, 0 ) 74%,
        var( --colour-border ) 0,
        var( --colour-border ) 76%,
        var( --colour-honey ) 0
    ) 0 0,
    linear-gradient(
        30deg,
        var( --colour-honey ) 24%,
        var( --colour-border ) 0,
        var( --colour-border ) 26%,
        rgba( 240, 166, 17, 0 ) 0,
        rgba( 240, 166, 17, 0 ) 74%,
        var( --colour-border ) 0,
        var( --colour-border ) 76%,
        var( --colour-honey ) 0
    ) 0 0,
    linear-gradient(
        90deg,
        var( --colour-border ) 2%,
        var( --colour-honey ) 0,
        var( --colour-honey ) 98%,
        var( --colour-border ) 0%
    ) 0 0 var( --colour-honey );
    --background-size: calc( var( --size ) * 4/3 ) calc( var( --size ) * 2 );
}

/* == 2. TYPOGRAPHY ====== */
.launch-announcement,
.headline-prefix,
.headline {
    margin: 0 10vw;
    text-align: center;
}

.launch-announcement,
.headline-prefix {
    font-size: min( 4vw, 1.15em );
    font-variant: small-caps;
}
.headline {
    font-size: min( 6vw, 1.5em );
    line-height: calc( min( 6vw, 1.5em ) * 1.05 );
}

/* == 3. LAYOUT (PHYS.) == */
/* .hero .background,
.hero {
    display: grid;
}

.hero .background,
.hero .foreground {
    grid-area: hero;
}
.background img,
.background p {
    grid-area: bg;
} */

/* .hero {
    display: flexbox;
    background-image: url('/public/landing-hero.png');
    color: #343434;
    background-repeat: no-repeat;
    background-size: contain;
} */

/* .hero2 {
    display: flexbox;
    background: linear-gradient( 90deg, rgb(208, 0, 0), orangered, orange, gold );
    background-image: url('/public/landing-product.png');
    color: #343434;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 10rem;
} */

.waves {
    position: relative;
    width: 100%;
    align-self: flex-end;
}
/* .waves::after {
    content: '';
    background: #fdfdfd;
    width: 100%;
    height: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
} */
.content {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-flow: column;
    gap: 2rem;
    /* padding: 2rem 2rem 5rem; */
    /* padding: 2rem ; */
}
.visuals {
    visibility: hidden;
    background: hsl( 45, 100%, 95%, .5 );
    margin: auto 1rem;
    height: 40vw;
    border-radius: 1rem;
    padding: 1rem;
}

.page > nav {
    position: relative;
    font-size: .9rem;
    z-index: 2;
}

.page > nav > *:nth-child( 2 ) > *:nth-child( 2 ) {
    transform: scale( 1.25 ) translate( -.5rem, -.1rem );
}
.page > nav > *:nth-child( 4 ) {
    display: grid;
    grid-auto-flow: column;
    gap: 1.5rem;
}
.page > nav > *:nth-child( 4 ) > a:first-child {
    font-weight: 900;
    color: black;
    text-decoration: none;
}
.page > nav > *:nth-child( 4 ) > a:last-of-type {
    text-decoration: none;
    font-weight: 900;
    color: #fdfdfd;
    text-transform: uppercase;
}

.logo {
    padding: 0;
}

.hero {
    justify-items: center;
    grid-template-areas: 'hero';
}
.hero .background {
    grid-template-areas: 'bg';
    opacity: .5;
}
.hero .foreground {
    display: grid;
    gap: 2rem;
    padding: 0 2rem;
    grid-template-columns: repeat( 3, 1fr );
    z-index: 1;
}

.hero img {
    max-width: 80vw;
}
.background p {
    font-family: 'Permanent Marker', cursive;
    padding: 2rem;
}

/* == 4. LAYOUT (AESTH.) = */

/* == 5. STATES & PSEUDO = */

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */
@media only screen and ( min-width: 600px ) {
    .page nav {
        justify-content: space-between;
    }
}

@media only screen and ( min-width: 992px ) {
    .page > form {
        display: none;
    }
}