.schoolyear-day-month,
.schoolyear .dates,
.logo-and-name,
.quarter,
.colours,
.colour,
.school,
.field {
    display: grid;
}
.schoolyear .section-contents {
    display: flex;
}

.schoolyear .dates {
    align-items: center;
    text-align: center;
    /* align-content: center; */
}
.schoolyear .section-contents {
    /* align-items: center; */
    justify-content: space-around;
    /* gap: 5.5em; */
}
.schoolyear-dates .section-contents > div {
    position: relative;
}

.schoolyear-day-month {
    text-align: center;
    background: hsl( 0, 0%, 75% );
    padding: .5em 1.5em;
    margin: 1em;
}
/* .schoolyear-dates > section > p:first-of-type::after {
    content: '⮕';
    position: absolute;
    right: -1em;
    top: 30%;
} */

.details {
    position: relative;
}
.details > form,
.details > div {
    padding: .5em 4em;
}
.details > button {
    position: absolute;
    left: 0;
    top: 0;
}

.school {
    grid-template-columns: 15% 10% 1fr 15%;
    gap: 1em;
    padding: .5em 0em;
    align-items: center;
}


.colour {
    position: relative;
}
.colour sup {
    font-size: .75em;
    position: absolute;
    align-self: center;
    justify-self: center;
}

.colour > div {
    height: 1.5em;
    border: 2px solid black;
}
.colour:first-of-type > div {
    border-top-left-radius: .5em;
    border-top-right-radius: .5em;
    border-bottom: 0;
}
.colour:last-of-type > div {
    border-bottom-left-radius: .5em;
    border-bottom-right-radius: .5em;
    border-top: 0;
}

.colour > div::after {
    content: 'W';
    color: transparent;
}

.logo-and-name {
    /* display: flex; */
    grid-template-columns: 20% 80%;
    /* justify-items: ; */
}
/* .logo-and-name fieldset:first-of-type input {
    max-width: 4em;
} */
.logo-and-name fieldset:first-of-type input,
.logo-and-name fieldset:last-of-type input {
    width: 100%;
}

.logo {
    font-size: 3em;
    text-align: center;
}

.form-mode .colours {
    display: grid;
    grid-area: colours;
}

.form-mode .colour {
    display: flex;
    align-items: center;
}
.form-mode .colour input[ type="color" ] {
    border-radius: 0;
    height: 2.75em;
}
.form-mode .colour:first-of-type input[ type="color" ] {
    border-top-left-radius: .5em;
}
.form-mode .colour:last-of-type input[ type="color" ] {
    border-bottom-left-radius: .5em;
}

.form-mode .colour input[ type="text" ] {
    border-radius: 0;
    border: none;
    max-width: 7.5em;
}
.form-mode .colour:first-of-type input[ type="text" ] {
    border-top-right-radius: .5em;
}
.form-mode .colour:last-of-type input[ type="text" ] {
    border-bottom-right-radius: .5em;
}

.form-mode .edit {
    display: none;
}

.form-mode .school {
    grid-template-areas: " colours logo-and-name "
                         " colours levels ";
    grid-template-columns: max-content 1fr;
    /* grid-template-rows: 1fr 2em; */
}
.form-mode .logo-and-name > fieldset {
    display: flex;
    gap: 1em;
}
/* .form-mode .logo-and-name > fieldset:first-of-type input {
    max-width: 5em;
}
.form-mode .logo-and-name > fieldset:last-of-type input {
    width: 10em;
} */

.schoolyear .section-contents p {
    display: grid;
    text-align: center;
}
.quarter {
    grid-template-columns: max-content 1fr max-content 1fr;
    gap: 1em;
}