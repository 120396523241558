/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
.attachment-container > span,
.points .ungraded {
    text-transform: uppercase;
    font-weight: 900;
}

button.attach .add {
    font-size: 1rem;
}

.points .ungraded {
    font-size: 70%;
    font-style: italic;
}

/* == 3. LAYOUT (PHYS.) == */
/* .points-and-attachments, */
/* .attachment-container, */
/* button.attach,
.details,
.points,
.bar {
    display: grid;
} */

/* .details,
.points {
    grid-auto-flow: column;
    grid-template-columns: 1fr 4rem;
} */

/* .points-and-attachments,
.attachment-container {
    grid-template-rows: max-content 1fr;
    gap: .25rem;
} */

/* .points > fieldset:first-of-type,
.ungraded {
    margin-top: .25rem;
} */

.bar fieldset {
    margin: 0;
    padding: 0;
}

/* .points-and-attachments { */
    /* display: grid; */
    /* grid-template-columns: max-content 1fr; */
    /* display: flex; */
    /* margin: 0 .25rem 1rem; */
    /* gap: .25rem; */
/* } */

/* .attachment-container.hide-attachments {
    grid-template-columns: 1fr;
} */

/* .details .directions textarea {
    width: 100%;
    min-height: 4.5rem;
} */

/* .points .ungraded {
    width: 100%;
    padding-left: .25rem;
} */

/* .attachment img {
    width: 1.25rem;
    height: auto;
} */

/* button.attach { */
    /* padding: 0; */
    /* margin: 0; */
    /* flex: 1; */
/* } */

/* button.attach .add {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .25rem;
    padding: .25rem;
} */

/* button.attach .add span {
    margin-left: .25rem;
} */
/*  */
.details {
    position: relative;
    display: grid;
}

.details .directions textarea {
    min-height: 5rem;
    border-bottom: 2.5rem solid white;
    resize: vertical;
}

.points-and-attachments {
    display: grid;
    grid-template-columns: max-content 1fr;
    /* gap: 1rem; */
    position: absolute;
    bottom: .35rem;
    left: .5rem;
    align-items: center;
    width: calc( 100% - 1rem );
}

.sans-button .points-and-attachments {
    grid-template-columns: max-content max-content;
    justify-content: space-between;
}

.points {
    display: grid;
    grid-template-columns: 1rem 2.5rem max-content;
    align-items: center;
    /* height: 2rem; */
}

.attachments {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
}

/* == 4. LAYOUT (AESTH.) = */
.attachment .contents,
button.attach .add,
button.remove,
.attachment-container .attachments,
.attachment {
    transition: .3s ease-in-out;
}

.attachment .contents,
button.attach {
    cursor: pointer;
}

button.attach .add {
    background-color: var( --secondary-colour-pale );
}

.bar fieldset {
    border: none;
}

button.attach {
    border: .25rem dashed white;
    background: transparent;
}

.bar textarea {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* == 5. STATES & PSEUDO = */
/* .hide-attachments .attachments button,
.hide-attachments .attachments input,
.hide-attachments .attachments {
    padding: 0;
    border: 0;
    width: 0;
    font-size: 0;
    height: 0;
    opacity: 0;
} */

button.attach .add:hover {
    margin: .05rem;
}

.attachment .contents:hover {
    transform: scale( 1.1 );
}

.attachment button.remove:hover {
    transform: scale( 1.5 );
    color: var( --secondary-colour-light );
    font-weight: 900;
}

.squares {
    position: relative;
}

i.chain {
    position: absolute;
    left: -1rem;
    top: .5rem;
    background-color: grey;
    color: white;
    padding: .4rem .5rem;
    border-radius: 50%;
    font-size: .5rem;
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */
@media ( max-width: 1025px ) {
    .attachment {
        background: hsl(60, 80%, 90%, .5);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5rem;
        height: 3rem;
    }

    .attachment button.remove {
        position: absolute;
        left: 0;
        background: transparent;
        border: none;
        padding: .5rem;
        cursor: pointer;
        z-index: 1;
    }

    .attachment .icon {
        margin-left: .75rem;
    }

    .attachment img {
        width: 2rem;
    }

    .attachment .label {
        top: -100%;
        right: -50%;
        padding: .25rem .5rem;
        max-width: 10rem;
        opacity: 0;
        position: absolute;
        background-color: black;
        color: white;
        border-radius: .25rem;
        transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    .attachment .label::after {
        position: absolute;
        content: '';
        right: 22.5%;
        bottom: -.5rem;
        width: 0;
        height: 0;
        border-left: .5rem solid transparent;
        border-right: .5rem solid transparent;
        border-top: .75rem solid black;
    }

    .attachment .icon:hover ~ .label {
        opacity: 1;
        z-index: 2;
    }
}