/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES & PSEUDO
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
button.modal-link:hover {
    color: var(--secondary-colour);
}

button.modal-link {
    color: var(--primary-colour-highlight);
    text-decoration: underline;
}

/* == 2. LAYOUT (PHYS.) == */
/* == 3. LAYOUT (AESTH.) = */
button.modal-link {
    background: none;
    border: none;
}

/* == 4. STATES & PSEUDO = */
/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */