/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES & PSEUDO
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
.side-nav li {
    text-transform: uppercase;
}

/* == 2. LAYOUT (PHYS.) == */
.side-nav,
.cover {
    position: absolute;
    z-index: 3;
    margin-top: 3rem;
    height: calc( 100% - 3rem );
}

.side-nav {
    width: 20vw;
    padding: 1rem 0;
}

.side-nav.active {
    height: calc( 100% - 3rem );
    width: 20vw;
}

.side-nav ul {
    display: grid;
    gap: 1rem;
}

.side-nav li {
    padding: .5rem 1rem;
}

.cover {
    width: 80vw;
    left: 20vw;
}

/* == 3. LAYOUT (AESTH.) = */
.side-nav {
    background: hsla( 45, 100%, 90%, .75);
    backdrop-filter: blur( 5px );
    transition: .3s ease-in-out;
}

.side-nav li {
    cursor: pointer;
}

.cover {
    background-color: hsla(0, 0%, 0%, .25);
    cursor: pointer;
}

/* == 4. STATES & PSEUDO = */
.side-nav li:hover {
    background: var(--secondary-colour-highlight);
}

/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */