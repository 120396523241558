.card {
    position: relative;
    background: transparent;
    display: grid;
    grid-template-rows: 6rem max-content 1fr;
    gap: 1rem;
    justify-items: center;
    padding: 1rem;
    border-radius: 1rem;
    /* z-index: 1; */
    /* background: hsl( 45, 0%, 100% ); */
    background: #fdfdfd;
    box-shadow: .1rem .1rem 1rem 0 rgba( 0, 0, 0, .25 );
    transition: .3s ease-in-out;
}
.card.free {
    display: grid;
    align-items: center;
    grid-template-columns: 24% 38% 28%;
    gap: 5%;
    grid-template-rows: max-content;
    /* margin: 0 1.5rem; */
}
.card:hover {
    transform: scale( 1.05 );
    border-color: orange;
}
/* .card:hover svg {
    fill: orange;
} */
/* .card:hover > *:not( button ) {
    color: white;
} */

.free svg {
    transform: scale( .5 );
}

.card button,
.card a {
    z-index: 1;
}

.card button:first-of-type {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1em;
    border: 3px solid #fdfdfd;
    background: hsl( 45, 100%, 50%, 0 );
}

.card > header {
    display: grid;
}
.card.free > header {
    grid-auto-flow: column;
    align-items: center;
    gap: 1rem;
}

.plan-name {
    font-size: .6rem;
    display: grid;
    grid-template-columns: 2rem 1fr;
    gap: .5rem;
    align-items: center;
}

.card:not( .free ) .billing {
    display: grid;
    justify-items: center;
    align-items: center;
}
.free .billing {
    margin: 0;
}

.title {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    height: 1rem;
    text-transform: uppercase;
    text-align: left;
    font-size: inherit;
    margin: .25rem 0 0 0;
    line-height: .5rem;
}
.free .title {
    margin: 0;
}
.title svg {
    height: 1rem;
    margin-right: .25rem;
}

.price {
    display: inline-flex;
    font-weight: 900;
    font-size: 1rem;
}
.billing > .frequency {
    font-size: .75em;
    align-self: flex-start;
}
.price .frequency {
    margin-left: .25rem;
    align-self: center;
    font-size: .5em;
    color: grey;
}

.features {
    align-self: flex-start;
    display: grid;
    position: relative;
}
.card:not( .free ) .features {
    gap: .5rem;
    margin-bottom: 1rem;
}
.free .features {
    /* padding-left: .5rem; */
    align-self: center;
    gap: 0;
    margin-bottom: 0;
}

.feature {
    font-size: .9em;
    transition: .3s ease-in-out;
}
.card:not( .free ) .feature {
    padding: .5em .25em;
    text-align: center;
}
.card.selected:nth-last-of-type( 2 ) .feature {
    padding: .2em .25em;
}
.card.selected:nth-last-of-type( 2 ) .feature:last-of-type {
    padding-bottom: 4.5rem;
}

.feature:not( .applies ) {
    color: hsl( 45, 0%, 75%);
}

.slider {
    position: absolute;
    bottom: -1rem;
    justify-self: center;
    width: 100%;
    padding: 1rem 1rem 0;
    opacity: 0;
    height: 0rem;
    overflow: hidden;
    z-index: 1;
    transition-delay: .3s;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
}
.selected .slider {
    opacity: 1;
    height: max-content;
}

/* .button {
    height: min-content;
    align-items: end;
} */

/* .card.focus button:last-of-type { */
    /* background: linear-gradient( 135deg, orangered, darkorange, orange, gold ); */
    /* background:
        radial-gradient( circle farthest-side at 30% 10%, transparent 50%, gold, orange, orangered ),
        linear-gradient( to top right, yellow, transparent ),
        linear-gradient( to top, orangered, orange ); */
/* } */
/* .card.focus button.button {
    color: white;
} */