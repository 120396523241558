.draggable {
    touch-action: none;
    display: grid;
    grid-template-columns: max-content 1fr;
}

.draggable button {
    padding: 0 .5rem;
}

.draggable.with-handle button {
    cursor: grab;
}