/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
.drag-handle {
    font-size: 0;
}

/* == 2. LAYOUT (PHYS.) == */
.draggable,
.content {
    position: relative;
}

.draggable {
    display: grid;
    margin: 0 1rem;
}

.content {
    display: flex;
}

.content > *:nth-last-child(1) {
    flex: 1;
}

/* <Bar /> .bar */
.content:hover > *:nth-last-child(1) > div {
    margin-left: 0;
}

.drag-handle {
    align-self: normal;
    padding: 0;
    z-index: 10;
}

/* == 3. LAYOUT (AESTH.) = */
.drag-handle,
.content {
    transition: .3s ease-in-out;
}

.drag-handle {
    border: none;
    background-color: var(--neutral-background-colour);
    cursor: grab;
}

/* == 4. STATES & PSEUDO = */
.content:hover .drag-handle {
    font-size: 1rem;
    padding: 0 .5rem;
}

/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */