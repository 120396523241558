.hive {
    --width: 3rem;
    --radius: calc( var( --width ) / 3 );

    position: relative;
    display: grid;
    gap: .1rem;
    justify-items: center;
    align-items: center;
    align-content: center;
}

.rung {
    animation-name: colour-down;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    width: var( --width );
    height: calc( var( --width ) / 3 );
    background: hsl( 40, 100%, 60% );
    border-radius: calc( var( --radius ) * .25 );
    transition: .3s ease-in-out;
}

.rung:nth-child( -n + 3 ) {
    border-top-left-radius: var( --radius );
    border-top-right-radius: var( --radius );
}
.rung:nth-child( n + 5 ) {
    border-bottom-left-radius: var( --radius );
    border-bottom-right-radius: var( --radius );
}

.rung:nth-child( even ) {
    background: hsl( 45, 100%, 65% );
}

.rung:nth-child( odd ):not( :first-of-type, :last-of-type ) {
    width: calc( var( --width ) * 2 );
}
.rung:nth-child( 4n - 2 ) {
    width: calc( var( --width ) * 1.5 );
}
.rung:nth-child( 4 ) {
    width: calc( var( --width ) * 2.5 );
    border-radius: var( --radius );
    height: calc( ( var( --width ) / 3 ) * 1.25 );
    display: grid;
    justify-items: center;
    align-content: center;
}

@keyframes colour-down {
    0% { opacity: 0; }
    50% {
  /*     filter: saturate( 100% ); */
      opacity: 1;
    }
    100% {
  /*     filter: saturate( 0% ); */
      opacity: 0;
    }
  }