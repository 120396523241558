/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
/* == 3. LAYOUT (PHYS.) == */
.calendar-container {
    display: grid;
}

.calendar-container {
    position: relative;
    grid-template-rows: max-content 1fr;
    height: calc( 100vh - 8.75rem );
}

.calendar {
    overflow: hidden;
}

.calendar h2 {
    padding-top: 2rem;
}

.sidebar-container.hide {
    width: 0vw;
}
.sidebar-container.show {
    width: 20vw;
}

div.modal {
    width: 100%;
    height: 100%;
}

/* == 4. LAYOUT (AESTH.) = */
.sidebar-container {
    background-color: var( --secondary-colour-pale );
    transition: .3s ease-in-out;
}

/* == 5. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */