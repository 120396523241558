.board-and-wave,
.wave-and-h1,
.headline,
.visuals,
.board,
.hero,
.box {
    display: grid;
}

.board-and-wave,
.headline,
.visuals,
.hero {
    justify-items: center;
}

.board-and-wave,
.hero {
    align-items: end;
}

.board-and-wave > *,
.wave-and-h1 > *,
.hero > * {
    grid-area: 1 / 1;
}

.hero {
    --width: 100vw;
    --h: 45;
    --s: 100%;
    --l: 50%;

    --border-colour: hsl( var( --h ), var( --s ), calc( var( --l ) - 10% ) );
    --checked-box-background: hsl( var( --h ), var( --s ), calc( var( --l ) + 10% ), .5 );
    --box-background: hsl( var( --h ), var( --s ), calc( var( --l ) + 10% ), .25 );

    grid-template-rows: calc( 100vh - 5rem );
    /* grid-template: "hero" calc( 100vh - 5rem ); */
}

.hero p:not( .description ),
.hero h1 {
    transition: .3s ease-in-out;
    margin: 0 calc( var( --width ) * .1 );
    text-align: center;
}

.description {
    position: absolute;
    color: transparent;
}

/* .visuals {
    margin-top: 15rem;
} */

.board {
    grid-template-columns: repeat( 3, calc( ( var( --width ) - 6em ) / 3 ) );
    grid-template-rows: repeat( 3, calc( calc( ( var( --width ) * .75 ) - 4em ) / 3 ) );

    border-radius: 1em;
}
.corner {
    --border-radius: .8em;
    border: 4px solid var( --border-colour );
}
.right.centre,
.left.centre {
    border-left: 4px solid var( --border-colour );
    border-right: 4px solid var( --border-colour );
}
.bottom.middle,
.top.middle {
    border-top: 4px solid var( --border-colour );
    border-bottom: 4px solid var( --border-colour );
}
.top.left.corner { border-top-left-radius: var( --border-radius ) }
.top.right.corner { border-top-right-radius: var( --border-radius ) }
.bottom.left.corner { border-bottom-left-radius: var( --border-radius ) }
.bottom.right.corner { border-bottom-right-radius: var( --border-radius ) }

.attachments,
.box {
    gap: calc( var( --width ) * .015 );
}

.box {
    align-items: start;

    background: var( --box-background );
    padding: calc( var( --width ) * .05 ) calc( var( --width ) * .025 ) calc( var( --width ) * .025 );
}
.checked.box {
    background: var( --checked-box-background );
}

.line {
    justify-self: center;

    height: calc( var( --width ) / 200 );
    border-radius: 1vw;
    width: 80%;
    background-color: hsl( 0, 0%, 25% );
}

.checkbox-and-attachments {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.attachments img {
    height: calc( var( --width ) / 40 );
}
.attachments > div > div {
    padding: 1vw !important;
}

.hero header {
    align-self: self-end;
    padding-bottom: 3vw;
    z-index: 1;
}

/* BOARD PROGRESS BAR */
.visuals > div {
    width: calc( 100% - 6rem );
}

.wave {
    overflow: hidden;
    z-index: 1;
    width: 100vw;
    transform: scaleX( -1 );
}

.box:not( .checked ) .checkmark,
.hero .left-pane,
.wave-and-h1 p,
.hero > .wave,
.hero form {
    display: none;
    visibility: hidden;
}

.checkbox {
    position: relative;
    width: calc( var( --width ) / 50 );
    height: calc( var( --width ) / 50 );
    border-radius: calc( var( --width ) / 200 );
    border: calc( var( --width ) / 400 ) solid black;
}
.checked .checkbox {
    background: var( --border-colour );
    border-color: var( --border-colour );
}

.checkmark {
    position: absolute;
    bottom: -.25em;
    left: -.05em;
    font-size: calc( var( --width ) * .03 );
}

@media only screen and ( min-width: 768px ) {
    .visuals > p {
        display: none;
        visibility: hidden;
    }
    .wave-and-h1 p {
        display: block;
        visibility: visible;
    }
}

@media only screen and ( min-width: 992px ) {
    .visuals > p,
    .wave-and-h1,
    .headline {
        display: none;
        visibility: hidden;
    }
    .hero .left-pane,
    .hero .wave {
        display: block;
        visibility: visible;
    }
    .hero form {
        display: flex;
        visibility: visible;
    }

    .hero {
        --width: 60vw;
        grid-template-columns: 40% 60%;
    }

    .left-pane {
        align-self: center;
        z-index: 2;
    }

    .visuals {
        grid-area: 1 / 2;
    }

    .wave {
        grid-area: 1 / 1 / 2 / 1;
        position: absolute;
    }
}