.card {
    border-radius: 1rem;
    background: white;
    display: grid;
    /* grid-template-columns: max-content min-content; */
    padding: 1rem 2rem;
    width: fit-content;
    align-items: center;
    justify-items: center;
    gap: .5rem;
}

/* .label-and-slider {} */

.value {
    font-size: 2rem;
}

.slider {
    width: 100%;
}
.label {
    text-transform: uppercase;
}