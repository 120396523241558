.container {
    --h: 45;
    --s: 100%;
    --l: 50%;

    position: relative;
    /* margin-top: 2rem; */
    column-gap: 1rem;
    display: grid;
    grid-template-areas: " header header "
                         " buttons form ";
    align-items: center;
    grid-template-columns: min-content 1fr;
    background: hsl( var( --h ), var( --s ), var( --l ) );
    padding: 1rem 2rem;
}

.container > button {
    position: absolute;
    top: .5rem;
    right: .5rem;
    border-radius: .5rem;
    padding: .25rem .5rem;
}

.container > header button {
    padding: .25rem .5rem;
}

.container > header > nav,
.container > header {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.container > header {
    grid-area: header;
}

.container > header > nav {
    padding-left: 1rem;
}

.form-section {
    grid-area: form;
}

.button-section {
    display: grid;
    grid-area: buttons;
    grid-template-rows: max-content 1fr;
    padding: 1rem 0;
    gap: 1em;
}
.button-section header {
    font-size: .75em;
    text-align: center;
    font-weight: 900;
}

.buttons {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    display: grid;
    gap: .5rem;
    margin-top: .5rem;
}
.buttons > * {
    padding: .5rem 1rem;
    font-size: .75rem;
    white-space: nowrap;
    border-radius: .5rem;
    /* justify-content: center; */
    /* text-align: center; */
}