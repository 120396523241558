.page {
    --h: 45;
    --s: 100%;
    --l: 50%;
}

.inputs-container {
    display: grid;
    grid-auto-flow: column;
}

/* .boards,
.months {
    display: grid;
    gap: 2em;
} */

.board,
.month {
    border: 2px solid hsl( var( --h ), var( --s ), var( --l ) );
    border-radius: 1em;
}

.board {
    display: grid;
    overflow-x: scroll;
    justify-items: start;
}

.board > header {
    display: flex;
    align-items: center;
    gap: 2em;
    margin-left: 1em;
}
.board > header > h2 {
    display: flex;
    align-items: center;
    gap: 1em;
}
.board .edit-button {
    padding: .25em 1em;
}

.board-assignments {
    display: flex;
}

.board .assignment {
    display: grid;
    grid-template-areas: "square";
    padding: .5em 2em;
}

.assignment > * {
    grid-area: square;
}

div.button {
    background: hsl( 0, 0%, 100%, .1 );
    backdrop-filter: blur( .1em );
    align-self: normal;
    display: grid;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: .3s ease-in-out;
}
div.button button {
    padding: .5em 2em;
}

div.content {
    padding: 1em;
}

div.button:hover {
    opacity: 1;
}