/* ======== MENU ============
 * 1. GENERAL
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */
 
/* == 1. GENERAL ========= */

/* == 2. TYPOGRAPHY ====== */
.step {
    text-align: center;
}

.step span {
    font-weight: 700;
    font-size: 1.25rem;
    white-space: nowrap;
}

.step i {
    font-size: 2rem;
}

.step p {
    hyphens: auto;
}

/* == 3. LAYOUT (PHYS.) == */
.steps {
    margin: 2rem;
}

.steps ul {
    display: flex;
}

.step {
    padding: 0rem 2rem;
    margin: 2rem 2rem 0 2rem;
    width: 20vw;
}

/* == 4. LAYOUT (AESTH.) = */

/* == 5. STATES ========== */

/* == 6. ANIMATIONS ====== */

/* == 7. @MEDIA ========== */