/* ======== MENU ============
 * 1. TYPOGRAPHY
 * 2. LAYOUT (PHYS.)
 * 3. LAYOUT (AESTH.)
 * 4. STATES & PSEUDO
 * 5. ANIMATIONS
 * 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */

/* == 2. LAYOUT (PHYS.) == */
.carousel {
    position: relative;
    display: grid;
    align-items: center;
    user-select: none;
    z-index: 5;
}

.carousel button {
    position: absolute;
    padding: .2rem .5rem;
    z-index: 2;
}

.carousel button:nth-of-type(1) {
    left: -2rem;
}
.carousel button:nth-of-type(2) {
    right: -2rem;
}

.carousel button:not( :disabled ):hover {
    padding: .3rem .6rem;
}

.carousel button:nth-of-type(1) {
    transform: translateX(-.5rem);
}

.carousel button:nth-of-type(2) {
    transform: translateX(.5rem);
}

/* == 3. LAYOUT (AESTH.) = */
/* .carousel > div > div:nth-of-type(1) {
    box-shadow: 0 0 1rem hsl(0, 0%, 0%, .5);
    border-radius: 1rem;
} */

.carousel button {
    border-radius: 50%;
}

/* == 4. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */