/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES & PSEUDO
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
.add-line i {
    color: transparent;
}

/* == 2. LAYOUT (PHYS.) == */
.add-line {
    position: relative;
    padding-top: .5rem;
    margin-bottom: .5rem;
}

.add-line i {
    position: absolute;
    left: calc( 50% - 1.5rem );
    top: .2rem;
    width: 3rem;
}
/* == 3. LAYOUT (AESTH.) = */
.add-line i,
.add-line {
    background-color: var(--neutral-background-colour);
    transition: .3s ease-in-out;
}

.add-line {
    border: none;
    border-bottom: 3px dashed transparent;
}

/* == 4. STATES & PSEUDO = */
.add-line:hover i,
.add-line:hover {
    color: black;
    border-color: black;
}

/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */