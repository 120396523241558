/* ======== MENU ============
* 1. VARIABLES
* 2. TYPOGRAPHY
* 3. LAYOUT (PHYS.)
* 4. LAYOUT (AESTH.)
* 5. STATES
* 6. ANIMATIONS
* 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.form {
    --border-size: .25rem;
    --border-type: dashed;
    --border-colour: black;
}

/* == 2. TYPOGRAPHY ====== */
.form input[ type = number ],
.form input[ type = text ] {
    text-align: center;
}

.period-group {
    text-align: center;
}

.form input[ type = text ] {
    font-size: 1.25rem;
}

.button {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 900;
}

/* == 3. LAYOUT (PHYS.) == */
.form {
    display: grid;
    justify-items: center;
    margin: 0 1rem;
}
.form.visible {
    padding: .25rem;
}

.period-inputs fieldset {
    padding: 0;
}

.period-group fieldset section {
    display: flex;
    flex-wrap: wrap;
}

.period-group input[ type = number ] + label,
.text-input input[ type = text ] + label {
    left: 37.5%;
}

.form input[ type = number ] {
    width: 6ch;
}

.form input[ type = text ] {
    width: 75vw;
}

.submit-button {
    margin: .25rem 0;
    width: 75vw;
}

.add-period-line {
    width: 82.5vw;
}

.button {
    width: 100%;
    padding: .5rem 0;
    margin: 0;
}

/* == 4. LAYOUT (AESTH.) = */
.period-inputs fieldset,
.form input[ type = number ],
.form input[ type = text ] {
    border: none;
}

.form {
    border: var( --border-size )
            var( --border-type )
            var( --secondary-colour-light );
    border-radius: .5rem;
}

.form input[ type = number ],
.form input[ type = text ] {
    border-bottom: var( --border-size )
                   var( --border-type )
                   var( --border-colour );
    background: var( --secondary-colour-pale );
}

.period-group {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.form input[ type = text ] {
    border-bottom: 4px double var(--border-colour);
}

.button {
    background: none;
    border: none;
    transition: .3s ease-in-out;
}

/* == 5. STATES & PSEUDO = */
.form.hidden:hover > button {
    background-color: var( --secondary-colour-pale );
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */