.page {
    display: grid;
    /* grid-template-rows: 7.5vh 80vh 7.5vh; */
    grid-template-columns: minmax( 15rem, 30% ) 1fr;
    height: 100vh;
    gap: 1em;
    overflow: hidden;
}

.page :is( h4, h5 ) {
    display: inline;
}

.page dfn {
    font-style: normal;
}
.page abbr {
    text-decoration: none;
}

.page > footer {
    grid-area: 3 / 1 / 3 / 3;
}

.page > nav {
    grid-area: 1 / 1 / 1 / 3;
}

.page h1 {
    font-size: 36px;
    margin-bottom: 0;
}
.page h2 {
    font-size: 30px;
}

section.left {
    grid-area: 2 / 1 / 3 / 1;
    padding: 0 2rem 0 4rem;
    overflow: auto;
}
section.left h2:first-of-type {
    padding: 0;
}

.menu > li > ul {
    font-size: .9em;
}
.menu > li:not( :first-of-type ) {
    margin-top: .75em;
}

.sub-menu {
    padding-left: 2em;
}

section.right {
    grid-area: 2 / 2 / 3 / 2;
    padding: 0 4rem 0 2rem;
    overflow: hidden auto;
}

section.right > header {
    grid-area: 3 / 1 / 2 / 3;
    padding: 2rem 4rem;
    text-align: center;
}

section.right > a {
    position: fixed;
    right: 1em;
    bottom: 1em;
    padding: .5em;
    /* width: fit-content; */
}
section.right section > p:not( :last-of-type ) {
    margin-bottom: 1em;
}

.page li > span,
.date {
    font-weight: 900;
}

ol:not( .definitions ) li > span {
    text-transform: uppercase;
}

.page :is( h2, h3, h4 ) {
    text-align: left;
    /* padding-top: 2rem; */
    padding: 2rem 0 1rem;
    margin: 0;
}


.update-date {
    font-style: italic;
}

/* .policy > * {
    margin-top: 2rem;
}
.policy > * > *:not( :first-child ) {
    margin-top: 1em;
} */

.right ul {
    display: grid;
    gap: 1rem;
    list-style: "⬢";
    padding-left: 1.5rem;
}
.right ul li {
    padding-left: .5rem;
}

.page ol:not( .numeric, .roman ) {
    --type: lower-alpha
}
.page ol > li > ol {
    counter-reset: section;
}

.page ol.numeric {
    --type: decimal
}
.page ol.roman {
    --type: lower-roman
}

.page ol:not( .definitions ) {
    list-style-type: none;
}
.page ol:not( .definitions ) > li::before {
    content: "( " counter( section, var( --type ) ) " ) ";
    font-weight: 700;
}
.page ol:not( .definitions ) > li {
    counter-increment: section;
}

/* .header.main {
    margin-top: 4rem;
} */

.contact-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1rem 2rem;
}
.contact-header {
    font-weight: 900;
}

/* GLOSSARY */
.page ol {
    display: grid;
    gap: 1em;
}

.glossary .term {
    font-weight: 900;
}