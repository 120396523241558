/* ======== MENU ============
* 1. VARIABLES
* 2. TYPOGRAPHY
* 3. LAYOUT (PHYS.)
* 4. LAYOUT (AESTH.)
* 5. STATES
* 6. ANIMATIONS
* 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.title h2 {
    --border-colour: black;
    --border-style: double;
    --border-size: 5px;
}

/* == 2. TYPOGRAPHY ====== */
.title h2 {
    font-size: 1.25rem;
}

/* == 3. LAYOUT (PHYS.) == */
.collapsed .sections ul,
.title h2,
.bar {
    margin: 0;
}

.title,
.bar {
    position: relative;
}


.sections ul,
.bar {
    display: flex;
    flex-wrap: wrap;
}

.bar {
    gap: 0 1rem;
    z-index: 2;
}

.add-line {
    margin: 0 1rem;
}

.bar.collapsed {
    align-items: center;
}

.bar > button {
    align-self: normal;
    position: relative;
    padding: 0 .5rem;
}

.header {
    padding: 1rem 0;
    flex: 1 0 7rem;
}

.collapsed .title {
    padding-right: 1rem;
}

.title h2 {
    padding: .5rem 0;
}

.sections ul {
    justify-content: center;
    margin-top: .5rem;
    gap: .5rem;
}

.collapsed .sections ul {
    justify-content: flex-start;
}

.header + .assignments,
.header + .sections {
    flex-grow: 3;
}

.assignments {
    padding: 1rem .5rem;
}

.assignments li:nth-last-of-type( n + 2 ) {
    margin-bottom: .5rem;
}

/* == 4. LAYOUT (AESTH.) = */
.bar {
    /* background-color: var( --neutral-background-colour-dark ); */
    background: hsl(54, 100%, 75%);
}

.bar > button {
    background: var( --secondary-colour-light );
}

.bar > button i {
    transform: scale(1.5);
}

button.is-expanded i {
    transform: rotate(90deg);
}

/* == 5. STATES & PSEUDO = */
.bar > button i,
.bar {
    transition: .3s ease-in-out;
}

.bar > button:hover {
    background-color: var(--secondary-colour-highlight);
}

.title h2::after {
    content: '';
    position: absolute;
}

.expanded .title h2::after {
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: var(--border-size) var(--border-style) var(--border-colour);
}

.collapsed .title h2::after {
    height: 80%;
    top: 10%;
    right: 0;
    border-right: var(--border-size) var(--border-style) var(--border-colour);
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */