/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
.form header {
    text-align: center;
}

/* == 3. LAYOUT (PHYS.) == */
.assignments,
.form h1,
.form h2,
.form h3 {
    margin: 0;
}

.assignments,
.form form {
    display: grid;
}

.form form {
    grid-template-rows: 50vh max-content;
    width: 65vw;
    overflow: hidden auto;
}

.form header {
    margin-bottom: 1rem;
}

.assignments {
    padding: 0;
    overflow: hidden scroll;
}

.button-container {
    display: grid;
    overflow: hidden;
    /* grid-template-columns: 175%;
    position: absolute;
    bottom: 5vh; */
}

/* == 4. LAYOUT (AESTH.) = */
.assignments {
    border: none;
}

/* == 5. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */