/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES & PSEUDO
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
/* == 2. LAYOUT (PHYS.) == */
.not-in-focus,
.in-focus {
    display: grid;
    justify-items: right;
}

.in-focus hr {
    margin: 2rem 0 1rem;
    width: 15vw;
}

.not-in-focus hr {
    margin: 1rem 0 .5rem auto;
}

/* == 3. LAYOUT (AESTH.) = */
/* == 4. STATES & PSEUDO = */
/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */