/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
.container > header h2 {
    font-size: 1.1rem;
    line-height: 1rem;
}
.container > header sup {
    font-size: .5em;
}

.container > header li {
    font-size: .8rem;
    text-transform: uppercase;
    /* font-variant: small-caps; */
}

/* == 3. LAYOUT (PHYS.) == */
.container,
.board,
.grid {
    display: grid;
}

.container {
    position: relative;
    padding-top: 2rem;
    overflow: hidden auto;
    grid-template-rows: max-content max-content max-content 1fr;
}

.board {
    grid-template-columns: min-content 1fr;
    gap: 2rem;
    padding: 0 2rem;
}
.board >:last-child {
    align-self: start;
}

.container > header {
    align-items: center;
    justify-content: center;
    position: relative;
}
.container > header h2,
.container > header ul {
    display: none;
}
.container > header h2 {
    margin: 0;
}
.container > header li {
    display: inline-block;
    padding: 0 1rem;
    /* font-variant: small-caps; */
}
.container > header li:not( :last-of-type ) {
    border-right: 3px double black;
}

.buttons {
    position: absolute;
    margin: 0;
    padding: 0;
    bottom: -50%;
    left: 45%;
    border: none;
}
.buttons button {
    font-size: .7rem;
    padding: .25em .5em;
    margin-left: .5em;
}

.planning {
    transition: .3s ease-in-out;
}

/* .container .planning:not( .active ) *, */
.planning:not( .active ) {
    padding: 1rem 0;
    transition: .3s ease-in-out;
    height: 0;
    /* visibility: hidden; */
}

/* == 4. LAYOUT (AESTH.) = */
.board > header h2 {
    border-right: .25rem double black;
}

/* == 5. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */
@media only screen and ( min-height: 800px ){
    .container > header {
        display: grid;
    }

    .container > header ul,
    .container > header h2 {
        display: block;
    }
}