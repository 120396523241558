.icon,
.icon path {
    transition: var( --transition );
}

.icon {
    --extra-transform: '';
    --transition: '';

    transform: var( --extra-transform );
}

.icon:hover path {
    opacity: 1;
    fill-opacity: 1;
}