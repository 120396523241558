.bio > fieldset {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.bio > fieldset:nth-of-type( 2 ) {
    flex-wrap: wrap-reverse;
}
.county > fieldset > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page {
    margin: 10vh 5rem;
    display: grid;
    grid-template-rows: min-content 1fr;
    /* align-items: center; */
    /* align-content: center; */
    justify-content: center;
    height: max-content;
    overflow: hidden;
    box-shadow: .1rem .1rem .6rem -.4rem black;
    border-radius: .5rem;
    background: white;
    padding: 2.5rem 2.5rem;
    gap: 2.5em;
    overflow: visible;
    /* box-shadow: 5px 5px 10px hsla( 0, 0%, 0%, 0.1 ); */
    /* box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4); */
}

.stepper {
    display: grid;
    /* transform: translate3d( 0, -3rem, 0 ); */
}

.page h1 {
    margin: 0;
    display: grid;
    grid-template-columns: max-content;
    justify-items: center;
    justify-content: center;
    /* justify-content: center; */
    white-space: nowrap;
    /* transform: translate3d( 0, -1.5em, 0 ); */
    line-height: 0rem;
}
.page h1 > span {
    /* padding: 1rem 0 0 .75rem; */
    padding: 1rem 0 0 0;
    z-index: 1;
}

.page header {
    position: relative;
}

.role .inputs {
    justify-content: center;
}


.button {
    display: grid;
    justify-content: center;
}

.button button {
    padding: 1em 2em;
    font-weight: 900;
    font-size: 1rem;
    border: 3px solid black;
    box-shadow: .1em .1em;
    border-radius: .5em;
    transition: .1s ease-in-out;
    background: linear-gradient( to right, orangered, orange 40%, gold );
    background: linear-gradient( to right, hsl( 50, 100%, 55% ), hsl( 50, 100%, 65% ), hsl( 50, 100%, 75% ) );
    /* background: hsl( 50, 100%, 50% ); */
    letter-spacing: 2px;
}

.button button:hover {
    transform: translate3d( -.05em, -.05em, 0 );
    box-shadow: .15em .15em;
}
.button button:active {
    transform: translate3d( .05em, .05em, 0 );
    box-shadow: .05em .05em;
}
.button button:hover .line {
    display: block;
    opacity: 1;
    animation: wobble;
    animation-fill-mode: both;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}
.line,
.button button:active .line {
    opacity: 0;
}

.line {
    --x-y: -.5em;
    --rotation: 45deg;
    --x-translation: .5em;

    position: absolute;
    border: .2em solid transparent;
    border-right-color: orange;
    border-radius: 50%;
    padding: 1em;
    transition: .1s ease-in-out;
    display: none;
    transform: rotate3d( 0, 0, 1, var( --rotation-deg ) ) translateX( var( --x-translation ) );
}
.line:first-of-type {
    --rotation-deg: calc( 180deg + var( --rotation ) );

    left: var( --x-y );
    top: var( --x-y );
}
.line:last-of-type {
    --rotation-deg: var( --rotation );

    right: var( --x-y );
    bottom: var( --x-y );
    /* transform: rotate3d( 0, 0, 1, var( --rotation-deg ) ) translateX( var( --x-translation ) ); */
}

.line::after {
    content: '';
    position: absolute;
    border-radius: inherit;
    border: .15em solid transparent;
    border-right-color: gold;
    padding: .75em;
    right: calc( var( --x-y ) * 1.25 );
    bottom: .25em;
    transform: rotate( -10deg );
}

.bee {
    display: block;
    position: absolute;
    top: -4rem;
    left: calc( ( 100% - 8rem ) / 2 );
    font-style: normal;
    font-size: 6rem;
    animation-name: float;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

.plans {
    width: 100%;
}

.plans ul {
    display: flex;
    flex-wrap: wrap;
    /* grid-template-columns: 25% 40% 25% 10%; */
    gap: 2rem;
    width: 100%;
}

@keyframes wobble {
    20% {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), 15deg); */
      transform: rotate3d( 0, 0, 1, calc( var( --rotation-deg ) + 15deg ) ) translateX( var( --x-translation ) );
    }

    40% {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), -10deg); */
      transform: rotate3d( 0, 0, 1, calc( var( --rotation-deg ) - 10deg ) ) translateX( var( --x-translation ) );
    }

    60% {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), 5deg); */
      transform: rotate3d( 0, 0, 1, calc( var( --rotation-deg ) + 5deg ) ) translateX( var( --x-translation ) );
    }

    80% {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), -5deg); */
      transform: rotate3d( 0, 0, 1, calc( var( --rotation-deg ) - 5deg ) ) translateX( var( --x-translation ) );
    }

    to {
      /* -webkit-transform: rotate3d(0, 0, var( --rotation-deg ), 0deg); */
      transform: rotate3d( 0, 0, 1, var( --rotation-deg ) ) translateX( var( --x-translation ) );
    }
}

@keyframes float {
    0% { transform: translate3d( .5rem, 0%, 0 ) rotate3d( 0, 1, 0, 180deg ) }
    50% { transform: translate3d( .5rem, .25rem, 0 ) rotate3d( 0, 1, 0, 180deg ); }
    100% { transform: translate3d( .5rem, 0%, 0 ) rotate3d( 0, 1, 0, 180deg ); }
}