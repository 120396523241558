/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES & PSEUDO
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
.rating li {
    color: transparent;
}

/* == 2. LAYOUT (PHYS.) == */
.rating {
    width: fit-content;
}

.rating li {
    display: inline-block;
}

/* == 3. LAYOUT (AESTH.) = */
.rating ul {
    background: linear-gradient(
        90deg,
        var(--tertiary-colour) 0%,
        var(--secondary-colour-highlight) 20%,
        var(--tertiary-colour) 40%,
        var(--secondary-colour-highlight) 60%,
        var(--tertiary-colour) 80%,
        var(--secondary-colour-highlight) 100%
    );
    background-attachment: fixed;
    background-clip: text;
}

/* == 4. STATES & PSEUDO = */
/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */