/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
.left:not( .sans-gradient ) > *:last-child,
.menu-item > *:not( .emoji ) {
    background: radial-gradient( circle farthest-side at 30% 10%, orangered 50%, orange, gold );
    background-clip: text;
    color: transparent;
}

.navbar i {
    font-style: normal;
}

.menu-item:last-of-type,
.left > *:last-child {
    font-weight: 900;
}

/* == 3. LAYOUT (PHYS.) == */
.navbar > section,
.menu-item {
    display: grid;
    align-items: center;
}

section.left,
.menu-item {
    grid-template-columns: min-content 1fr;
    gap: 1em;
}

section.right {
    grid-template-columns: 1fr min-content;
    gap: 2em;
}

.menu-item,
.navbar {
    padding: 1rem 2rem;
}

.sub-menu,
.navbar {
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

/* .navbar > section, */
.navbar {
    position: relative;
}
.centre-brand {
    justify-content: center;
}

.brand {
    align-self: center;
    margin-left: .5rem;
}

.background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -2;
}

.sub-menu {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    right: 0rem;
    top: 2rem;
    z-index: 10;
    transition: .3s ease-in-out;
}
.sub-menu.show {
    top: 100%;
    opacity: 1;
    visibility: visible;
}

.img-container {
    display: flex;
    align-items: center;
}
.img-container img {
    width: 1rem;
}

/* == 4. LAYOUT (AESTH.) = */
/* CHROME CANNOT NEST BACKDROP-FILTERS */
.sub-menu,
.background {
    background: hsl( 45, 100%, 50%, .1 );
    backdrop-filter: blur( 4px );
    box-shadow: 0 4px 30px 1px hsla( 0, 0%, 0%, 0.1 );
}

.background {
    background: hsl( 0, 0%, 99%, .1 );
}

.navbar {
    --colour-honey: hsl( 45, 100%, 95% );
    --colour-border: hsl( 35, 85%, 75% );
    --size: 20px;

    /* background:
        radial-gradient(
            circle farthest-side at 0% 50%,
            var( --colour-honey ) 23.5%,
            rgba( 240, 166, 17, 0 ) 0
        ) calc( var( --size ) * .7 ) var( --size ),
        radial-gradient(
            circle farthest-side at 0% 50%,
            var( --colour-border ) 24%,
            rgba( 240, 166, 17, 0 ) 0
        ) calc( var( --size ) * 19/30 ) var( --size ),
        linear-gradient(
            var( --colour-honey ) 14%,
            rgba( 240, 166, 17, 0 ) 0,
            rgba( 240, 166, 17, 0 ) 85%,
            var( --colour-honey ) 0
        ) 0 0,
        linear-gradient(
            150deg,
            var( --colour-honey ) 24%,
            var( --colour-border ) 0,
            var( --colour-border ) 26%,
            rgba( 240, 166, 17, 0 ) 0,
            rgba( 240, 166, 17, 0 ) 74%,
            var( --colour-border ) 0,
            var( --colour-border ) 76%,
            var( --colour-honey ) 0
        ) 0 0,
        linear-gradient(
            30deg,
            var( --colour-honey ) 24%,
            var( --colour-border ) 0,
            var( --colour-border ) 26%,
            rgba( 240, 166, 17, 0 ) 0,
            rgba( 240, 166, 17, 0 ) 74%,
            var( --colour-border ) 0,
            var( --colour-border ) 76%,
            var( --colour-honey ) 0
        ) 0 0,
        linear-gradient(
            90deg,
            var( --colour-border ) 2%,
            var( --colour-honey ) 0,
            var( --colour-honey ) 98%,
            var( --colour-border ) 0%
        ) 0 0 var( --colour-honey ); */
    background: hsl( 0, 0%, 99%, .25 );
    background-size: calc( var( --size ) * ( 4/3 + ( 4/3 * 1/4 ) ) ) calc( var( --size ) * ( 2 + ( 2 * 1/4 ) ) );
    /* box-shadow: 0 4px 30px 1px hsla( 0, 0%, 0%, 0.1 ); */
    position: relative;
}

.menu-item > * {
    transition: .3s ease-in-out;
}

.sub-menu {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background: hsl( 45, 100%, 85%, .5 );
    backdrop-filter: blur( 4px );
    /* box-shadow: -2.5px 7.5px 10px 1px hsla( 0, 0%, 0%, 0.1 ); */
}

.menu-item {
    cursor: pointer;
}
.menu-item:not( :last-of-type ) > * {
    filter: brightness( 0 );
}

.menu-item:last-of-type {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background: hsl( 45, 100%, 100%, .75 );
    backdrop-filter: blur( 8px );
}
.menu-item:last-of-type img {
    width: 1.25rem;
}

/* == 5. STATES & PSEUDO = */
.menu-item:hover > * {
    transform: scale( 1.1 );
}

.menu-item:hover .label {
    font-weight: 900;
}
.menu-item:not( :last-of-type ):hover i:not( .emoji ) {
    filter: brightness( 100% );
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */