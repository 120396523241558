/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

.container > nav {
    max-height: calc( 50vh - 3rem );
    overflow: hidden auto;
    direction: rtl;
}
.container > nav::-webkit-scrollbar-thumb {
    background: white;
}
.container > nav > ul {
    direction: ltr;
}

/* == 1. VARIABLES ======= */
.container {
    --h: 100;
    --s: 100%;
    --l: 50%;
    --hue: var( --h );
    --saturation: var( --s );
    --lightness: var( --l );
    --blur: 5px;
    --text-colour: black;
}

.container .week {
    --opacity: 1;
    --scale: 1;
    --hue: var( --h );
    --saturation: var( --s );
    --lightness: var( --l );
}

.sub-menu {
    --opacity: .5;
}

/* == 2. TYPOGRAPHY ====== */
.active-quarter,
.week.active {
    font-weight: 900;
}

.board-num,
.container {
    color: var( --text-colour )
}

.board-num {
    font-size: .75rem;
}

/* == 3. LAYOUT (PHYS.) == */
.active-quarter,
.container {
    position: relative;
}

.active-quarter,
.container li {
    padding: .5rem 1rem;
}

.active-quarter,
.week {
    display: grid;
    grid-template-areas: "square";
    justify-items: center;
    align-items: center;
}

.container {
    padding: .1em .25em;
}

.week > * {
    grid-area: square;
}

.sub-menu {
    position: absolute;
    right: -2rem;
    top: 0;
    z-index: 15;
}

.sub-menu li {
    padding-left: .5rem;
    padding-right: .5rem;
}

.board-num {
    padding: .2rem .3rem;
}

/* == 4. LAYOUT (AESTH.) = */
.active-quarter,
.container li {
    cursor: pointer;
    transition: .3s ease-in-out;
}

.container {
    background: hsl( var( --hue ), var( --saturation ), 90%, .5 );
}

.week {
    --week-scale: var( --scale );
}
.week.active {
    --week-scale: calc( var( --scale ) + .25 );

    transform: scale( var( --week-scale ) );
}

.board-num {
    border-radius: .5rem;
    background: hsl( var( --h ), var( --s ), 95%, .75 );
    backdrop-filter: blur( 2.5px );
}

.sub-menu {
    backdrop-filter: blur( 5px );
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    background: hsl( var( --hue ), var( --saturation ), var( --lightness ), var( --opacity ) );
}

/* == 5. STATES & PSEUDO = */
.week:hover {
    filter: saturate( 1.5 );
    transform: scale( calc( var( --week-scale ) + .25 ) );
}

.week:active {
    background: hsl(0, 0%, 100%, var( --opacity ) );
}

.sub-menu-trigger:hover {
    background: hsl( var( --hue ), var( --saturation ), calc( var( --lightness ) + 10% ) );
}
.sub-menu-trigger:hover .sub-menu {
    opacity: 1;
    visibility: visible;
}

.sub-menu li:hover {
    background: hsl( var( --hue ), var( --saturation ), 95% );
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */