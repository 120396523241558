/* ======== MENU ============
 * 1. TYPOGRAPHY
 * 2. LAYOUT (PHYS.)
 * 3. LAYOUT (AESTH.)
 * 4. STATES & PSEUDO
 * 5. ANIMATIONS
 * 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
/* .text {
    text-align: center;
} */

.stars {
    font-size: 1.25em;
}

.in-focus .stars ul {
    background: var(--primary-gradient);
    background-attachment: fixed;
    background-clip: text;
}

.card {
    font-size: max(.75rem, 90%);
}

.text {
    text-align: center;
}

.text i {
    font-size: 2em;
}

.text p {
    font-size: .75rem;
}

.in-focus p {
    font-size: 1em;
}

.in-focus footer {
    text-align: right;
    font-size: .75em;
    font-weight: 900;
}

/* == 2. LAYOUT (PHYS.) == */
.review {
    position: relative;
    margin: 1rem;
    display: flex;
    /* flex-wrap: nowrap; */
    align-items: stretch;
    /* min-width: max(30vw, 50%); */
}

.container {
    position: relative;
}

.in-focus .text {
    padding-left: 3rem;
    z-index: 0;
}

.not-in-focus .text {
    padding-top: 1rem;
}

.portrait {
    position: absolute;
    /* flex: 1 1 10rem; */
    left: -5%;
    bottom: 0%;
    max-width: 5rem;
    z-index: 1;
}

.portrait img {
    width: max(10rem, 100%);
}

.card {
    display: grid;
    justify-items: center;
    align-content: space-between;
    min-width: 12.5rem;
    height: 97.5%;
    padding: .5rem 1rem;
    margin: 0;
}

.in-focus .card {
    padding-left: 2.5rem;
    justify-items: right;
}

.in-focus .stars {
    margin-left: auto;
}

.review hr {
    width: 15vw;
    margin: 1rem 0 .5rem auto;
}

.review footer {
    padding-bottom: 1rem;
}

/* == 3. LAYOUT (AESTH.) = */
.review hr {
    border: .1rem solid var(--font-colour-on-light-background);
}

.card {
    backdrop-filter: blur(.5rem);
}

.review {
    filter: blur(2.5px);
    opacity: .5;
    transition: .3 ease;
}

.review.not-in-focus  {
    transform: scale(.8);
}

.review.in-focus {
    filter: blur(0);
    opacity: 1;
}

.portrait img {
    border-bottom-left-radius: 1.5rem;
    transform: translateX(-25%);
}

/* == 4. STATES & PSEUDO = */
.in-focus .container::before {
    position: absolute;
    content: '';
    bottom: 0%;
    left: -22%;
    width: 70%;
    height: 65%;
    border-radius: 50% 50% 1rem 1rem;
    /* border-radius: 8rem 100% 2rem 2rem; */
    background-color: var(--secondary-colour-light);
}

/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */