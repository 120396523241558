/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.theme {
    --background: brown;
}

/* == 2. TYPOGRAPHY ====== */
.themes {
    text-align: center;
}

.text .title {
    font-size: 1.25em;
    line-height: 1;
    white-space: wrap;
    text-overflow: ellipsis;
}

.text .subtitle {
    font-size: 1.5vw;
    font-style: italic;
    text-transform: uppercase;
}

/* .edit-button {
    font-size: 0;
}

button.add {
    font-size: 1.5rem;
} */

/* == 3. LAYOUT (PHYS.) == */
.bar input,
.theme-list,
.theme,
.title,
.bar {
    margin: 0;
    padding: 0;
}

.theme-list,
.themes li,
.theme {
    display: grid;
}

.themes.bar-header,
.theme-list {
    gap: .25rem;
}

fieldset.buttons {
    display: flex;
    gap: 2rem;
    justify-content: center;
    padding: 1rem;
}

/* SORTABLE WRAPPER */
.themes ul {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem 2rem;
    padding: 2.5rem 2rem;
    justify-content: center;
}

.themes.bar-header {
    margin: 0 2rem;
}
.themes.bar-header.has-units {
    grid-template-columns: min-content 1fr;
}

.theme-list {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
}

.themes li {
    grid-template-rows: 1fr .5rem;
}

.theme {
    grid-template-areas: "text-and-cover"
                         "colour-input";
}

.text {
    padding: .75rem 0 .25rem;
    grid-area: text-and-cover;
    position: relative;
}

.title {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: .25rem;
    min-height: 2.5rem;
    padding: 0 .5rem;
}

.bar {
    grid-area: colour-input;
    position: relative;
    height: .5rem;
}

.bar input {
    height: 0;
    width: 100%;
    transform: translateY( -0.6em );
    /* visibility: hidden; */
    /* visibility: hidden; */
    /* position: absolute; */
    /* width: 102%; */
    /* height: 200%; */
    /* bottom: -.2rem; */
    /* bottom: 0; */
    /* left: -1%; */
}

/* .edit-button {
    width: 10vw;
    margin-right: .5rem;
}

button.add {
    padding: 1rem 0;
} */

.cover {
    z-index: 1;
    grid-area: text-and-cover;
}

/* == 4. LAYOUT (AESTH.) = */
.themes:hover > *:not( .form ),
.bar input,
.text {
    transition: .3s ease-in-out;
}

.bar input,
.cover,
.bar {
    cursor: pointer;
}

.theme .title,
.themes {
    overflow: hidden;
}

.cover,
.theme,
.text {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}
.bar {
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
}
.theme {
    border-bottom-left-radius: .5em;
    border-bottom-right-radius: .5em;
}

.themes:not( .header ) {
    height: 100%;
    padding-top: 3em;
}

/* .edit-button,
button.add {
    border: .25rem dashed var( --secondary-colour-light );
    background: var( --secondary-colour-pale );
} */

.edit-button{
    transform: translateX( -10vw);
    opacity: 0;
}

.theme-list {
    transform: translateX( calc( -1.5rem - 3vw ) );
}

.themes li {
    list-style: none;
}

.text {
    background: linear-gradient( transparent 50%, var( --background ) 50% );
    background-size: 100% 200%;
    /* transform: translateY( -.5em ); */
}

.bar {
    background: var( --background );
}

.bar input {
    border: none;
    background: transparent;
}

/* == 5. STATES & PSEUDO = */
.bar input::after {
    position: absolute;
    content: "";
    top: 0;
    height: .6em;
    width: 100%;
    background: var( --background );
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    /* background: black; */
}

.themes:hover > *:not( .form ) {
    transform: translateX( 0 );
}

.themes:hover .edit-button {
    opacity: 1;
    font-size: 4vw;
}

.edit-button:hover,
button.add:hover {
    filter: saturate( 2 );
}
.edit-button:active,
button.add:active {
    background: var( --secondary-colour-pale-opaque );
}

.cover:hover + .text {
    background-position-y: bottom;
}
/* .text::after {
    position: absolute;
    content: ""; */
    /* width: 2em; */
    /* height: 1em;
    bottom: -.5em;
    left: 0;
    right: 0;
}
.cover:hover + .text::after {
    background: var( --background ); */
    /* border: 2px solid black; */
/* } */

.cover:hover + .text h3,
.cover:hover + .text p {
    background-color: var( --background );
    background-clip: text;
    color: transparent;
    filter: sepia(5) saturate(100) invert(1) grayscale(1) contrast(9);
}

.cover:hover + .bar input {
    opacity: 0;
}

.bar input:hover {
    opacity: 1;
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */
/* .cover,
.theme {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
} */
.theme {
    width: 15rem;
    /* border: .25rem dashed var( --background ); */
    box-shadow: .1rem .1rem 1rem 0 var( --background );
}