/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. GENERAL ========= */
/* == 2. TYPOGRAPHY ====== */
.feed .name,
.finished,
.positive,
.negative {
    font-weight: 900;
}

.activity i {
    font-style: normal;
}

.worker-bees ul {
    text-align: left;
}

.worker-bees li i {
    font-size: 1.5rem;
}

.feed li header {
    font-size: .9em;
}
.feed li header i {
    font-style: normal;
}
.feed .timestamp {
    font-style: italic;
}

.feed {
    font-size: .8rem;
}

.positive {
    color: green
}
.negative {
    color: red;
}
.finished {
    color: hsl(30, 100%, 50%);
}

.negative i,
.finished i {
    font-size: 5em;
}

/* == 3. LAYOUT (PHYS.) == */
.worker-bees,
.sections,
.feed li,
.details,
.feed {
    display: grid;
}

.feed ul,
.activity,
.buttons {
    display: flex;
}

.worker-bees,
.details {
    gap: 1rem;
    grid-template-rows: max-content 1fr;
}

.worker-bees li,
.buttons button {
    padding: .5rem;
}

.activity {
    flex-flow: row wrap-reverse;
    overflow: hidden;
    padding: 1.5rem 2rem 0;
    gap: 2rem;
    /* align-self: center; */
}

.worker-bees {
    direction: rtl;
    overflow: hidden;
    height: 100%;
    align-self: flex-end;
}
.worker-bees li {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 2.5rem;
    align-items: center;
}
.worker-bees li:not( :last-of-type ) {
    margin-bottom: 1rem;
}
.worker-bees li i {
    margin-right: .5rem;
}

.buttons {
    gap: 1rem;
    flex-wrap: wrap-reverse;
    justify-content: center;
}

.worker-bees ul {
    overflow: auto;
}

.details {
    flex: 1;
}
.details.just-feed {
    grid-template-rows: 85vh;
}

.sections {
    grid-auto-flow: column;
}

.feed {
    align-self: center;
    overflow: auto;
    height: 97.5%;
}
.feed ul {
    flex-direction: column-reverse;
    justify-content: flex-end;
}

.feed li {
    padding: 1rem;
    margin: 0 1rem;
    overflow: hidden;
    position: relative;
}

.feed li:not( :first-of-type ) {
    margin-bottom: 1rem;
}

.feed li header i {
    padding-right: .25rem;
}

.feed li > span {
    padding-top: .5rem;
    padding-left: 1.1rem;
}

.negative i,
.finished i {
    position: absolute;
}

.finished i {
    bottom: -.5em;
    right: -.5em;
}
.negative i {
    bottom: -1.5rem;
    right: -2rem;
    opacity: .5;
}

/* == 4. LAYOUT (AESTH.) = */
.activity {
    background: repeating-linear-gradient(
      -45deg,
      #fec412 0,
      #fec412 15px,
      transparent 0,
      transparent 30px
    ),
    repeating-linear-gradient(
      45deg,
      #fec412 0,
      #fec412 15px,
      transparent 0,
      transparent 30px
    ),
    #f8bd1f;
}

.buttons button,
.feed {
    border-radius: .5rem;
}

.activity li {
    transition: .3s ease-in-out;
}

.worker-bees li {
    border: 2px solid white;
    background: hsl( 50, 100%, 100%, .5 );
    box-shadow: 0 4px 30px hsla( 0, 0%, 0%, 0.1 );
}
.delete li {
    border-color: hsl( 0, 100%, 75% );
    background: hsl( 0, 100%, 90%, .5 );
}

.feed {
    border: 2px solid hsl( 50, 100%, 70% );
    background: hsl( 50, 100%, 85% );
}

.feed li {
    background: hsl( 50, 100%, 100%, .25 );
    border-radius: .5rem;
}

.feed li:nth-of-type( 2n + 0 ) {
    background: hsl( 50, 100%, 100%, .5 );
}

.feed li header {
    display: flex;
    justify-content: space-between;
}

.delete .student {
    animation-name: wiggle1;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    overflow: hidden;
    margin: .5rem 0;
}
.delete .student:nth-of-type( 3n + 1 ) {
    animation-delay: .3s;
    animation-duration: 3s;
}
.delete .student:nth-of-type( 2n + 0 ) {
    animation-name: wiggle2;
    animation-fill-mode: both;
    animation-delay: .15s;
}

.delete-button {
    --colour: maroon;

    background: hsl( 0, 100%, 90% );
    border: 2px solid var( --colour );
    color: var( --colour );
    font-weight: 900;
}
.add-button {
    --colour: hsl( 280, 100%, 25% );
    background: hsl( 280, 100%, 90% );
    border: 2px solid var( --colour );
    color: var( --colour );
    font-weight: 900;
}

.finished i {
    transform: rotateY( 180deg );
}

/* == 5. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
@keyframes wiggle1 {
    0% { transform: translate3d(1px, 1px, 0) rotate(0deg); }
    2% { transform: translate3d(-1px, -2px, 0) rotate(-1deg); }
    4% { transform: translate3d(-3px, 0px, 0) rotate(1deg); }
    6% { transform: translate3d(3px, 2px, 0) rotate(0deg); }
    8% { transform: translate3d(1px, -1px, 0) rotate(1deg); }
    10% { transform: translate3d(-1px, 2px, 0) rotate(-1deg); }
    12% { transform: translate3d(-3px, 1px, 0) rotate(0deg); }
    14% { transform: translate3d(3px, 1px, 0) rotate(-1deg); }
    16% { transform: translate3d(-1px, -1px, 0) rotate(1deg); }
    18% { transform: translate3d(1px, 2px, 0) rotate(0deg); }
    20% { transform: translate3d(1px, -2px, 0) rotate(-1deg); }
}
@keyframes wiggle2 {
    0% { transform: translate3d(1px, -1px, 0) rotate(1deg); }
    2% { transform: translate3d(1px, 1px, 0) rotate(0deg); }
    4% { transform: translate3d(1px, -2px, 0) rotate(-1deg); }
    6% { transform: translate3d(-1px, -2px, 0) rotate(-1deg); }
    8% { transform: translate3d(3px, 2px, 0) rotate(0deg); }
    10% { transform: translate3d(-3px, 1px, 0) rotate(0deg); }
    12% { transform: translate3d(3px, 1px, 0) rotate(-1deg); }
    14% { transform: translate3d(-1px, 2px, 0) rotate(-1deg); }
    16% { transform: translate3d(-1px, -1px, 0) rotate(1deg); }
    18% { transform: translate3d(-3px, 0px, 0) rotate(1deg); }
    20% { transform: translate3d(1px, 2px, 0) rotate(0deg); }
}

/* == 7. @MEDIA ========== */