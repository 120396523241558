.label,
.form {
    display: flex;
    align-items: flex-end;
}

button.email {
    /* padding: 0 1rem; */
    height: 1.5rem;
}

.label {
    gap: 1rem;
    font-size: 14px;
    color: orange;
}

.form input {
    border-color: grey;
}