/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.logo {
    --size: .4rem;
    --background: linear-gradient(
        45deg,
        hsl(0, 93%, 17%),
        hsl(0, 93%, 17%),
        hsl(0, 97%, 30%),
        hsl(0, 100%, 43%)
    );
    --border-radius: calc( var( --size ) / 2 );
    --border-width: calc( var( --size ) / 10 );
    --empty-colour: white;
}

.square {
    --border-colour: goldenrod;
    --active-colour: orange;
}

/* == 2. TYPOGRAPHY ====== */
/* == 3. LAYOUT (PHYS.) == */
.logo {
    display: grid;
    grid-template-areas: "top-left top-centre top-right"
                         "mid-left mid-centre mid-right"
                         "bottom-left bottom-centre bottom-right";
    justify-content: start;
    gap: calc( var( --size ) / 5 );
    width: fit-content;
    border-radius: var( --border-radius );
}

.square {
    width: var( --size );
    height: var( --size );
    padding: 0;
}

/* == 4. LAYOUT (AESTH.) = */
.square {
    border-width: var( --border-width );
    border-style: solid;
    border-color: var( --border-colour );
}

.square:not( .empty ) {
    background: var( --background );
    background-size: 300% 300%;
}

.logo .top.left.corner {
    background-position: 0% 0%;
    border-top-left-radius: var( --border-radius );
}
.logo .top.centre {
    background-position: 50% 0%;
}
.logo .top.right.corner {
    background-position: 100% 0%;
    border-top-right-radius: var( --border-radius );
}
.logo .middle.right {
    background-position: 100% 50%;
}
.logo .middle.centre {
    background-position: 50% 50%;
}
.logo .middle.left {
    background-position: 0% 50%;
}
.logo .bottom.left.corner {
    background-position: 0% 100%;
    border-bottom-left-radius: var( --border-radius );
}
.logo .bottom.centre {
    background-position: 50% 100%;
}
.logo .bottom.right.corner {
    background-position: 100% 100%;
    border-bottom-right-radius: var( --border-radius );
}

.square.empty {
    background: var( --empty-colour );
}

/* == 5. STATES & PSEUDO = */
.logo.hoverable .square:hover,
.square.active {
    background: var( --active-colour );
}

button.square:active {
    opacity: .5;
}
/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */