.locale,
.form {
    gap: 2em;
}

.form {
    display: grid;
    padding: 3em;
}
.form > fieldset {
    display: flex;
    gap: 2em;
    align-items: center;
}

.abbr-button {
    grid-auto-flow: row;
}

.label {
    font-size: .7em;
    color: hsl( 45, 0%, 45% );
    font-weight: normal;
}

.buttons {
    justify-self: center;
}