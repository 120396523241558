.price,
.bar {
    display: grid;
}

.bar {
    gap: 2rem;
    align-items: center;
    /* width: min-content; */
}
.bar.horizontal {
    grid-template-columns: max-content 7.5rem min-content;
}

.heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0em .5em;
}
.icon svg{
    /* height: 1em; */
    height: 2em;
}
.price {
    font-size: 1.5em;
}
.title {
    font-size: .5em;
    text-transform: uppercase;
    color: grey;
}

.billing {
    /* align-self: flex-end; */
    font-size: .5em;
}

.audience {
    font-size: .75em;
}