.page > section,
.page {
    display: grid;
    grid-template-rows: min-content 1fr;
}

.page {
    height: 100vh;
}

.page header {
    padding-top: 4rem;
}

.message {
    padding: 2rem 4rem;
}
.message p {
    line-height: 1rem;
    margin-bottom: 4rem;
}
.message .buttons {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 1rem 3rem;
    margin: 0;
    padding: 0;
    border: none;
}

.forms {
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 1rem;
}

.sidenav {
    background: hsl( 45, 0%, 90% );
}

.menu {
    display: grid;
}
.nav-item {
    position: relative;
}
.nav-item:not( :last-of-type )::after {
    content: '';
    position: absolute;
    height: 1.5rem;
    width: .15rem;
    border-radius: .5rem;
    background: grey;
    bottom: -.75rem;
    left: 1.55rem;
}