/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
.date-input label {
    /* font-weight: 900; */
    text-transform: uppercase;
    color: hsl( 45, 0%, 45% );
    font-size: .75em;
    padding-left: .75em;
}

.date-input input {
    font-size: 1rem;
}

/* == 3. LAYOUT (PHYS.) == */
.date-input input,
.date-input {
    border: none;
}

.date-input input,
.date-input label {
    padding-right: .5rem;
}

.date-input {
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
    margin: .5rem;
    justify-content: flex-end;
}

.date-input.label-on-top {
    flex-direction: column-reverse;
}

.date-input input {
    padding-left: .5rem;
    margin-right: .5rem;
    flex: 1;
}

/* == 4. LAYOUT (AESTH.) = */
.date-input input {
    border-radius: .25rem;
}

/* == 5. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */