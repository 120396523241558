/* ======== MENU ============
* 1. VARIABLES
* 2. TYPOGRAPHY
* 3. LAYOUT (PHYS.)
* 4. LAYOUT (AESTH.)
* 5. STATES
* 6. ANIMATIONS
* 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.group {
    --border-radius: 20px;
}

/* == 2. TYPOGRAPHY ====== */
.group {
    text-align: center;
    text-transform: capitalize;
}

/* == 3. LAYOUT (PHYS.) == */
.radio-group {
    display: grid;
    /* gap: .5rem; */
    padding-left: 0;
}

.radio-group.column {
    padding: 0;
}

.group {
    display: grid;
    border: none;
    padding: 0;
}

.row .group {
    align-items: center;
    grid-auto-flow: column;
}

.column .group {
    gap: .5rem;
    justify-content: center;
    /* width: 10rem; */
}

.row .group:first-of-type > span:first-child:empty {
    margin-right: 0;
}

.row .group:first-of-type > span:first-child {
    margin-right: .51rem;
}

.row .group:first-of-type > span:last-child {
    margin-left: .5rem;
}

.row .group > span:first-of-type:empty {
    margin: 0;
}

.column .button {
    width: 7.5rem;
}

/* == 4. LAYOUT (AESTH.) = */
.radio-group {
    border: none;
}

.row .group > .button:first-of-type {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.row .group > .button:last-of-type {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

/* == 5. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */