.month > header,
.calendar,
.sidebar,
.month,
.days,
.day {
    display: grid;
}

section.calendar {
    --h1: 45;
    --s1: 100%;
    --l1: 50%;
    --h2: 0;
    --s2: 100%;
    --l2: 25%;

    grid-template-columns: min-content 1fr;
    height: calc( 100vh - 8.85em );
    /* background: palevioletred; */
    overflow-y: hidden;
    gap: 2em;
    /* padding-top: 1em; */
}

/* .sidebar {
    padding: 1em;
    grid-template-rows: min-content 1fr;
    gap: 1em;
    opacity: 1;
    transition: .3s ease-in-out;
}
.sidebar.hide {
    opacity: 0;
    visibility: hidden;
    width: 0;
    padding: 0;
}

.sidebar .day-type {
    white-space: nowrap;
} */

/* .close {
    justify-self: start;
    padding: .25em .5em;
} */

.content {
    display: grid;
    padding-top: 1em;
    grid-template-rows: min-content 1fr;
}

.months {
    height: 32.5%;
    overflow-y: scroll;
    padding-bottom: 2em;
    align-items: flex-start;
}
.months:not( .without-sidebar ) {
    padding-bottom: 20em;
}

.legend {
    display: flex;
    gap: 2em;
    justify-self: center;
}

.label {
    display: grid;
    justify-items: center;
    padding-bottom: 1em;
}
.label-text {
    padding: .25em .75em;
    border-radius: 1em;
}
/* .label::before {
    content: 'W';
    width: 1em;
    height: 1em;
    margin-right: .25em;
    color: transparent;
    border: 1px solid black;
} */
/* .label:first-of-type:before, */
.label:first-of-type .label-text {
    background: hsl( var( --h1 ), var( --s1 ), var( --l1 ), .5 );
    border: 1px solid hsl( var( --h1 ), var( --s1 ), calc( var( --l1 ) * .75 ), .5 );
}
/* .label::before, */
.label .label-text {
    background: hsl( var( --h2 ), var( --s2 ), var( --l2 ), .5 );
    border: 1px solid hsl( var( --h2 ), var( --s2 ), calc( var( --l2 ) * .75 ), .5 );
}
/* .label:last-of-type:before, */
.label:last-of-type .label-text {
    background: hsl( var( --h1 ), 0%, var( --l1 ), .25 );
    border: 1px solid hsl( var( --h1 ), 0%, calc( var( --l1 ) * .75 ), .5 );
}

.month {
    /* justify-content: center; */
    justify-items: center;
}
.month > header {
    grid-template-columns: min-content 1fr min-content;
    align-items: center;
    /* margin: 0 2em; */
    /* width: 10em; */
}
.show-all-months .month > header {
    grid-template-columns: 1fr;
}

.month h2 {
    margin: 1em 0 .5em;
    font-size: 2em;
}
/* .month:first-of-type h2 {
    margin-top: 1em;
} */

.month .long-name {
    display: none;
}

.days {
    grid-template-columns: repeat( 5, 1fr );
    grid-auto-rows: 2em;
    margin: 0 3em;
    padding: .5em;
    border-radius: 1em;
    box-shadow: .1em .1em .25em 0 hsl( 0, 0%, 30%, .25 );
}

.day:nth-of-type( 1 ) {
    border-top-left-radius: 1em;
}
.day:nth-of-type( 5 ) {
    border-top-right-radius: 1em;
}
.day:nth-last-of-type( 5 ) {
    border-bottom-left-radius: 1em;
}
.day:nth-last-of-type( 1 ) {
    border-bottom-right-radius: 1em;
}

.day.for-this-month {
    grid-auto-rows: 1.5em;
    padding: .25em .75em;
}
.calendar:not( .show-all-months ) .day.for-this-month {
    grid-template-rows: min-content repeat( 2, 1.5em );
}

button:is( .current-month-button, .next-or-previous-month-button ) {
    padding: 0;
    /* border-radius: 0; */
    opacity: 0;
    transition: .3s ease-in-out;
}
.day.for-this-month:not( .holiday ):hover > button {
    opacity: 1;
}
.day .actual-date {
    justify-self: end;
    color: red;
}

.day:not( .for-this-month ),
.day.holiday {
    font-style: italic;
    opacity: .5;
}
.day.for-this-month {
    grid-template-areas: " day ";
}
.day.for-this-month > * {
    grid-area: day;
}

.day.odd.for-this-month {
    background: hsl( var( --h1 ), var( --s1 ), var( --l1 ), .5 );
}
.day.even.for-this-month {
    background: hsl( var( --h2 ), var( --s2 ), var( --l2 ), .5 );
}
.day.holiday.for-this-month {
    background: hsl( var( --h1 ), 0%, var( --l1 ), .25 );
}

.future-month h2,
.past-month h2 {
    font-weight: 100;
}
.current-month .past-day.for-this-month,
.past-month .day,
.past-month h2 {
    color: slategrey;
}
.past-month h2 {
    font-style: italic;
}
.current-month .days {
    border: 2px solid hsl( var( --h1 ), var( --s1 ), var( --l1 ), .3 );
}

.past-month .day.odd.for-this-month,
.current-month .past-day.odd.for-this-month {
    background: hsl( var( --h1 ), var( --s1 ), var( --l1 ), .3 );
}
.past-month .day.even.for-this-month,
.current-month .past-day.even.for-this-month {
    background: hsl( var( --h2 ), var( --s2 ), var( --l2 ), .3 );
}
/*
.day.holiday.for-this-month {
    background: red;
} */

.present-day.day.for-this-month {
    /* --l1: calc( var( --l1 ) * .5 ); */
    /* --h1: 45; */
    background: gold;
}

.day-info {
    font-size: .8em;
    padding: .25em .75em;
    border-radius: 1em;
    white-space: nowrap;
    /* border-width: 6px; */
    /* border-color: red; */
}

@media only screen and ( min-width: 768px ) {
    .month .short-name {
        display: none;
    }
    .month .long-name {
        display: block;
    }
}

.show-all-months .months {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    /* justify-content: center; */
    /* display: grid; */
    /* grid-template-columns: repeat( 3, 1fr ); */
    font-size: .75em;
}
.show-all-months .month {
    flex: 1 1 22.5%;
}

.show-all-months .months h2 {
    padding-top: 1em;
}

.show-all-months .days {
    margin: 0 1em;
    max-width: fit-content;
}

.months.without-sidebar {
    justify-content: center;
}

.teacher-workday.for-this-month {
    background: gold;
}