/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
/* == 2. LAYOUT (PHYS.) == */
.card {
    position: relative;
    margin: 1rem;
}

/* == 3. LAYOUT (AESTH.) = */
.card {
    overflow: visible;
    border-radius: 20px;
    box-shadow: 0 0 1rem hsl(0, 0%, 0%, .5);
    background: var(--neutral-background-colour-opaque);
    backdrop-filter: blur(.5rem);
}

/* == 4. STATES & PSEUDO = */
/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */