/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ========= */
/* == 2. TYPOGRAPHY ====== */
.course-and-sections h1 {
    font-size: 2rem;
}

.sections {
    text-transform: uppercase;
}

.info :is( h1, h2, h3 ) {
    text-align: left;
    font-size: 1em;
    line-height: 1em;
}
.info .about-board {
    font-size: .8em;
}

/* .home {
    color: black;
} */

/* == 3. LAYOUT (PHYS.) == */
.hive > header,
.sections,
.banner,
.hive {
    display: grid;
}

.hive > header,
.sections {
    align-items: center;
}

.hive {
    /* grid-template-rows: min-content max-content 1fr max-content; */
    grid-template-rows: min-content calc( 100vh - 6rem ) min-content;
    height: 100vh;
    overflow: hidden;
}

.hive.logged-in > header {
    grid-template-columns: max-content 1fr;
    justify-items: stretch;
}

.logged-in .banner {
    grid-template-columns: 1fr max-content 1fr;
}

.course-and-sections h1,
.info :is( h1, h2, h3 ) {
    padding: 0;
    margin: 0;
}

.sections {
    grid-auto-flow: column;
    gap: .5rem;
    padding-bottom: .5rem;
}

.hive > article .about-board h2,
.hive > article .about-board ul,
.info .about-board > fieldset {
    display: none;
}

.about-board fieldset {
    display: flex;
    justify-content: center;
    gap: 1em;
}
.about-board fieldset button {
    transform: scale( .75 );
}

.info .about-board > ul {
    display: flex;
}
.hive nav .about-board li:nth-last-of-type( n + 2 )::after {
    content: '';
    padding-right: .5em;
    margin-left: .5em;
    border-left: 2px dotted black;
}

/* == 4. LAYOUT (AESTH.) = */
.home i,
.home {
    transition: .3s ease-in-out;
}

.hive > header {
    background: darkgrey;
}

/* == 5. STATES & PSEUDO = */
.home:hover i,
.home:hover {
    transform: scale( 1.1 );
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */
@media only screen and ( min-height: 800px ){
    .info .about-board {
        display: none;
    }
    .hive > article .about-board {
        display: grid;
        grid-template-areas: "h2"
                             "ul"
                             "buttons";
    }

    .hive > article .about-board h2,
    .hive > article .about-board ul {
        display: block;
    }
    .hive > article .about-board h2 {
        display: block;
        grid-area: h2;
    }
    .hive > article .about-board ul {
        display: block;
        grid-area: ul;
    }
    .hive > article .about-board fieldset {
        grid-area: buttons;
    }
}