/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
.info-button {
    font-size: 1rem;
    color: hsl( 55, 70%, 90% );
}

/* == 3. LAYOUT (PHYS.) == */
.info-button {
    position: relative;
    padding: 0;
    margin: 0;
}

/* == 4. LAYOUT (AESTH.) = */
.info-button {
    display: inline-flex;
    border: none;
    background: none;
    cursor: default;
}

.default.info-button {
    border-radius: 50%;
    border: .1rem solid hsl( 55, 70%, 80% );
    background: maroon;
    cursor: help;
}

.info-button > div {
    visibility: hidden;
    opacity: 0;
}

/* == 5. STATES & PSEUDO = */
.info-button.clicked > div,
.info-button:hover > div {
    /* --background: red; */
    visibility: visible;
    opacity: 1;
}

.info-button:active {
    color: hsl( 55, 90%, 90% );
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */