.page fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

.page {
    display: grid;
    font-family: 'Arima Madurai', cursive;
}

.initial.page {
    align-items: center;
}

.planning.page {
    padding: 2rem 2.5rem;
    height: calc( 100vh - 5rem );
    grid-template-areas: " header header "
                         " assignments weeks "
                         " editor editor ";
    grid-template-columns: 55% 45%;
    grid-template-rows: max-content 1fr max-content;
}

.planning > *:not( .editor ) {
    padding: 2rem 2.5rem;
    /* margin: 2rem 2.5rem; */
}
.planning .assignments {
    grid-area: assignments;
}
.planning .weeks {
    grid-area: weeks;
}
.planning > nav {
    grid-area: header;
    display: grid;
    text-align: center;
    justify-items: center;
}
.planning .editor {
    grid-area: editor;
    transition: .3s ease-in-out;
    height: 0;
    opacity: 0;
}

.assignments,
.weeks {
    overflow: hidden auto;
}

/* .assignment-list header { */
    /* margin: 0; */
    /* text-transform: none; */
    /* text-align: left; */
    /* font-weight: normal; */
/* } */

.assignment-list:nth-of-type( even ) {
    background: hsl( 45, 0%, 96% );
}

.assignments {
    border-radius: 1rem;
    padding: 2rem 2.5rem;
    /* box-shadow: 0 4px 30px hsla( 0, 0%, 0%, 0.1 ); */
    box-shadow: .1rem .1rem .6rem -.4rem black;
}

.assignments > ul {
    display: grid;
    gap: 1rem;
}

.copies > li {
    margin-left: 2rem;
}

.assignments > header {
    padding-bottom: 2rem;
    text-align: center;
}

.planning.edit .editor {
    height: fit-content;
    opacity: 1;
    transition: .3s ease-in-out;
}

.planning > nav {
    display: grid;
    text-align: center;
    justify-items: center;
}
.planning > nav.multiple {
    grid-template-columns: max-content 1fr max-content;
}

/* .theme {
    font-size: 1em;
} */
.date-range {
    font-size: .6em;
}

.unit {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem 2rem;
    font-size: 1.25em;
}

.caret {
    transition: .3s ease-in-out;
}

.caret:hover path {
    opacity: 1;
    fill-opacity: 1;
}
.caret:hover {
    transform: scale( 1.25 );
}