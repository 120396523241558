/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES & PSEUDO
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
/* == 2. LAYOUT (PHYS.) == */
.boards,
.group {
    display: flex;
    gap: .5rem;
}

.group {
    flex-flow: row-reverse wrap-reverse;
    justify-content: flex-end;
    margin: 1rem;
}

.boards {
    flex-wrap: wrap;
}

.plain-links {
    flex: 1;
}

/* == 3. LAYOUT (AESTH.) = */
/* == 4. STATES & PSEUDO = */
/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */