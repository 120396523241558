/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

/* == 1. VARIABLES ======= */
.board {
    --light-box-lightness: 90%;
    /* --light-box-lightness: calc( var( --lightness ) + 20% ); */
    --hue: 60;
    --lightness: 70%;
    --blank-hue: 0;
    --blank-saturation: 0%;
    --saturation: 100%;
    --req-text-colour: black;

    --border-lightness: calc( var( --lightness ) - 20% );
    --border-colour: hsl( var( --hue ), var( --saturation ), var( --border-lightness ) );
    --border-radius: 1rem;
    --border-style: solid;
    --border-width: 3px;
}

/* == 2. TYPOGRAPHY ====== */
.req a,
.req {
    color: var( --req-text-colour );
}

.square.blank,
.square {
    /* font-size: .9rem; */
    font-size: 85%;
    color: black;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

/* .square.blank {
    font-style: italic;
} */

.square:not( .blank ) {
    font-weight: 900;
}

.hint {
    color: hsl( var( --hue ), var( --saturation ), 40% );
    /* color: hsl( var( --hue ), var( --saturation ), var( --border-lightness ) ); */
}

/* == 3. LAYOUT (PHYS.) == */
.square > div .links,
.skeleton .lines,
.skeleton,
.square,
.board {
    display: grid;
}
.skeleton .boxes {
    display: flex;
    margin: auto;
}

.board {
    position: relative;
    grid-template-columns: repeat( 3, 1fr );
    grid-auto-rows: minmax( max-content, 16vh );
    transition: .3s ease-in-out;
}
.board.with-text-to-speech {
    margin-top: 3.5rem;
}

.skeleton ~ * {
    visibility: hidden;
    position: absolute;
    z-index: -1;
}

.square {
    position: relative;
    /* align-items: center; */
    align-items: stretch;
    text-align: center;
    /* padding: .75rem; */
    padding: .75rem .75rem .25rem;
}

.square:not( .directions-box ) {
    grid-template-rows: min-content 1fr max-content;
}

.directions-box {
    align-content: center;
}

.details {
    display: contents;
    height: 50%;
}
/* MODAL BUTTON */
.details > div > :is( button:last-child ) {
    /* background: red; */
    margin-top: 15%;
}

button.playback {
    visibility: hidden;
    font-size: .5rem;
    padding: .25em .5em;
    transition: .3s ease-in-out;
    opacity: 0;
}

.square.board-view {
    padding: .75rem 0 0 0;
}

.square.board-view {
    align-items: stretch;
}

.square > div .links {
    position: absolute;
    bottom: 0;
    left: 25%;
    justify-content: center;
}

.container > div {
    padding-left: .75rem;
}

/* == 4. LAYOUT (AESTH.) = */
/* .board */
/* .square {
    border: 2px solid hsl( var( --hue ), var( --saturation ), var( --border-lightness ) );
} */

/* .board {
    border-radius: var( --border-radius );
} */

.square {
    background: hsl( var( --hue ), var( --saturation ), var( --lightness ) );
}

/* .square:nth-child( 3n + 3 ) { */
:is( .left, .right ) {
    border-right: var( --border-width ) var( --border-style ) var( --border-colour );
    border-left: var( --border-width ) var( --border-style ) var( --border-colour );
}
/* .square:nth-last-child( -n + 3 ) { */
:is( .top, .bottom ) {
    border-top: var( --border-width ) var( --border-style ) var( --border-colour );
    border-bottom: var( --border-width ) var( --border-style ) var( --border-colour );
}

.square:nth-child( 1 ) {
    border-top-left-radius: calc( var( --border-radius ) - .1rem );
}
.square:nth-child( 3 ) {
    border-top-right-radius: calc( var( --border-radius ) - .1rem );
}
.square:nth-child( 7 ) {
    border-bottom-left-radius: calc( var( --border-radius ) - .1rem );
}
.square:nth-child( 9 ) {
    border-bottom-right-radius: calc( var( --border-radius ) - .1rem );
}

.square.prereq {
    background: hsl( var( --hue ), var( --saturation ), var( --light-box-lightness ) );
}

.square input[ type="checkbox" ] {
    transform: scale( 1.5 );
}

.directions-box {
    background: hsl( var( --blank-hue ), var( --blank-saturation ), 100%, .6 );
}

/* .directions-box.save-changes { */
    /* border-color: gold; */
    /* background: hsl( 50, 100%, 50%, .25 ); */
/* } */
.directions-box.save-changes button {
    background: var( --border-colour );
    color: white;
}

.square.blank {
    opacity: .5;
    cursor: not-allowed;
}

.square.active {
    --gradient-lightness: var( --lightness );
    opacity: 1;
    /* transition: .3s ease-in-out; */
}
.square.active.req {
    --gradient-lightness: calc( var( --lightness ) + 10% );
}

.directions-box button {
    font-size: .75rem;
    max-width: 80%;
}

.directions-skeleton,
.checkbox-skeleton,
.links-skeleton {
    position: relative;
    overflow: hidden;
    /* background: linear-gradient( to right, orangered, orange, gold ); */
    background: linear-gradient(
        to right,
        hsl( var( --hue ), var( --saturation ), var( --gradient-lightness ) ),
        hsl( calc( var( --hue ) + 30 ), var( --saturation ), var( --gradient-lightness ) )
    );
}

.skeleton .boxes,
.skeleton .lines,
.skeleton {
    gap: .5em;
}
.directions-skeleton,
.skeleton .boxes {
    width: 80%;
}

.directions-skeleton,
.links-skeleton {
    /* margin-bottom: .5em; */
    border-radius: .5em;
    margin: auto;
}
.skeleton .boxes > * {
    height: 1em;
}

.directions-skeleton {
    height: .5em;
}
.skeleton .boxes {
    margin-top: .5em;
}
.links-skeleton {
    width: calc( 80% - 2em );
    margin-right: 0;
}
.checkbox-skeleton {
    /* height: 1em; */
    width: 1em;
    border-radius: .25em;
    background: hsl( calc( var( --hue ) + 15 ), var( --saturation ), var( --gradient-lightness ) );
}

.links-and-button > div > button {
    padding: 0;
    width: 3rem;
    height: 1rem;
}
.links-and-button > div > button svg {
    transform: scale( .75 );
}

/* == 5. STATES & PSEUDO = */
.directions-skeleton::after,
.checkbox-skeleton::after {
    content: '';
    animation: shine-swipe 3s infinite;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to right,
        transparent,
        hsl( 45, 100%, 100%, .2 ),
        transparent
    );
    transform: translateX( -150% );
}

.square:hover .playback {
    display: inline-block;
    opacity: 1;
    visibility: visible;
}

/* .square input:checked {
    background-color: red;
} */
/* == 6. ANIMATIONS ====== */
@keyframes shine-swipe {
    to { transform: translateX( 100% ); }
}

/* == 7. @MEDIA ========== */
.content {
    display: grid;
    grid-template-rows: 1fr max-content;
}

.links-and-button.sans-links:last-child {
    margin: auto auto auto 0;
}

.directions-box path {
    fill: var( --border-colour );
}