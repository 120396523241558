/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

/* == 1. VARIABLES ======= */
.toggle-container {
    --border-radius: 1.5rem;
    --label-width: calc( var( --border-radius ) * 2.5 );
}

/* == 2. TYPOGRAPHY ====== */
.toggle-container {
    text-align: center;
    font-size: calc( var( --border-radius ) *  2 / 3 );
}

.vertical.toggle-container .placeholder-label,
.toggle-container label {
    text-transform: uppercase;
}

/* == 3. LAYOUT (PHYS.) == */
.toggle-container > button,
.vertical .toggle-field,
.vertical .inputs,
.cover {
    position: absolute;
}

.vertical .toggle-field,
.cover {
    top: 0;
}

.toggle-container,
.cover {
    height: 1.75rem;
}

.toggle-container {
    position: relative;
    display: grid;
    align-items: center;
    margin: .25rem 0;
    padding: 0 0 var( --border-radius ) 0;
}

.toggle-container.horizontal {
    grid-auto-flow: column;
    grid-auto-columns: calc( var( --label-width ) * 1.5 );
}

.toggle-container.horizontal > label {
    justify-self: center;
}

.toggle-container input {
    position: fixed;
}

.toggle-container label {
    padding: calc( var( --border-radius ) / 6 ) calc( var( --border-radius ) / 3 );
    width: max-content;
}

.toggle-container > button {
    left: calc( var( --border-radius ) * 3.5 );
    width: calc( var( --label-width ) * 1.5 );
    height: calc( var( --border-radius ) * ( 2/3 ) );
}

.toggle-container button.on-left {
    left: calc( var( --border-radius ) / 5 );
    width: calc( var( --label-width ) * 1.25 );
}

.cover {
    width: 100%;
    z-index: 15;
}

.vertical.toggle-container .placeholder-label,
.vertical .inputs label {
    padding: 0 calc( var( --border-radius ) / 2 ) 0 var( --border-radius );
}

.vertical .placeholder-label.empty {
    padding-right: .5rem;
    padding-left: .5rem;
}

.vertical .inputs {
    display: flex;
    flex-direction: column-reverse;
    top: -90%;
    gap: calc( var( --border-radius ) / 3 );
}

.vertical.toggle-container button {
    --from-left: calc( var(--border-radius) * .066 );
    --side-length: calc( var(--border-radius) / 2 );

    bottom: 50%;
    left: var( --from-left );
    width: var( --side-length );
    height: var( --side-length );
}

.vertical.toggle-container button.on-left {
    bottom: 10%;
}

.vertical .toggle-field {
    height: 100%;
    width: 1rem;
}

/* == 4. LAYOUT (AESTH.) = */
.toggle-container > button,
.vertical .inputs,
.toggle-container {
    transition: .5s ease-in-out;
}

.toggle-container.horizontal,
.toggle-container > button {
    border-radius: var(--border-radius);
}

.toggle-container > button {
    background: #efefef;
}

.toggle-container {
    overflow: hidden;
}

.toggle-container.horizontal {
    background: var(--secondary-colour-light);
}

.toggle-container.horizontal.inactive {
    background-color: var(--primary-colour-highlight);
    color: white;
}

.toggle-container input {
    opacity: 0;
}

.cover {
    background-color: hsl(0, 0%, 25%, 0);
    cursor: pointer;
}

/* .toggle-container.inactive {
    background: red;
}
.toggle-container:not( .inactive ) {
    background: green;
} */
.vertical.toggle-container .toggle-field {
    background: green;
    border-radius: .5rem;
}
.vertical.toggle-container.inactive .toggle-field {
    background: red;
}

.horizontal .toggle-field {
    background: red;
}

.vertical:not( .inactive ) .inputs > label {
    transform: translateY( -11px );
}

/* == 5. STATES & PSEUDO = */
input:checked + label {
    font-weight: 900;
}

.vertical.inactive .inputs {
    top: 10%;
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */