.interface {
    display: grid;
    grid-template-columns: 10em 1fr;
    padding: 0 2em;
    gap: 2em;
}

/* .interface > * {
    padding: 0 2em;
} */

.sidebar .search input {
    width: 100%;
}

.sidebar .accordeon > header {
    display: grid;
    grid-template-columns: 1fr min-content;
}
.sidebar .accordeon > header button {
    padding: .25em;
}

.pill:not( :first-of-type ) {
    margin-top: .5em;
}
.pill:hover {
    background: linear-gradient( orange, orange );
}

.content {
    overflow: hidden;
}