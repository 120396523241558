/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.inputs {
    --background : brown;
}

/* == 2. TYPOGRAPHY ====== */
.buttons button {
    font-size: 1rem;
}

/* == 3. LAYOUT (PHYS.) == */
.input-fieldset-container,
.buttons {
    display: flex;
}

.input-fieldset-container {
    overflow: scroll;
}

.form {
    display: grid;
    /* grid-template-columns: 2rem 1fr; */
    grid-template-rows: max-content 1fr;
    height: 100%;
    /* grid-template-rows: max-content 60%; */
    /* grid-template-areas: " buttons days-left "
                         " buttons squares "; */
    margin: 0 4rem;
    /* max-height: 75vh; */
    /* overflow: scroll; */
    gap: 2rem;
}

.form fieldset:not( .inputs ) {
    padding: 0;
}

.days-left h3 {
    margin: 0;
}

.buttons {
    gap: 2.5rem;
    /* grid-area: buttons; */
    /* flex-direction: column; */
}

.buttons button {
    padding: .25rem;
    flex: 1;
    margin: .25rem 0;
    justify-content: center;
}

.inputs {
    display: grid;
    gap: 1.5rem;
    justify-items: stretch;
    position: relative;
    padding: 2.5rem 1.5rem;
    margin: 2rem 0 2rem 3rem;
}

.inputs input:not( [ type="color" ] ) {
    width: 100%;
}

.input-fieldset-container {
    /* grid-area: squares; */
    flex-wrap: wrap;
}

.blocks-and-colour {
    position: relative;
    display: flex;
    gap: 1rem;
    /* justify-content: center; */
    align-items: center;
}

fieldset.number {
    flex: 3;
}
/* .colour {
    flex: 1;
} */

/* .colour input {
    min-width: 2rem;
    min-height: 1rem;
} */

button.close {
    position: absolute;
    left: 1rem;
    top: .75rem;
}

/* == 4. LAYOUT (AESTH.) = */
button.close,
.inputs {
    border-radius: 1em;
}

.form fieldset:not( .inputs ) {
    border: none;
}

button.close {
    background: transparent;
    color: var( --background );
    border: 2px solid transparent;
    transition: .3s ease-in-out;
}

/* .inputs input {
    border-bottom: 2px solid black;
} */

.inputs {
    width: 15rem;
    /* border: .25rem dashed var( --background ); */
    box-shadow: .1rem .1rem 1rem 0 var( --background );
}

/* == 5. STATES & PSEUDO = */
button.close:hover {
    border-color: var( --background );
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */