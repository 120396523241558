/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
/* == 3. LAYOUT (PHYS.) == */
.tag-container {
    position: relative;
    display: flex;
    align-items: center;
}

.tag {
    padding: .25rem .5rem;
    max-width: 7.5rem;
    display: inline-flex;
    align-items: center;
    gap: .25rem;
}
.label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tag i {
    padding-left: .25rem;
}

.cover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.tooltip {
    position: absolute;
    display: flex;
    bottom: 1.25rem;
    width: max-content;
    padding: .25rem .5rem;
}

/* == 4. LAYOUT (AESTH.) = */
.tag-container,
.tooltip,
.cover,
.tag i,
.tag {
    border-radius: .25rem;
}

.untouchable.has-tooltip .cover,
.removeable .cover {
    cursor: pointer;
}

.untouchable .cover {
    cursor: not-allowed;
}

.tooltip {
    transform: scale( 1, 0 ) translateY( 3rem );
    background-color: transparent;
    transition: .3s ease-in-out;
}

/* == 5. STATES & PSEUDO = */
.removeable .cover:hover + span {
    background-color: var( --secondary-colour-pale );
}

.untouchable.has-tooltip .cover:hover ~ .tooltip {
    transform: scale( 1 ) translateY( 0 );
    background: grey;
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */