.edit-mode.theme {
    display: grid;
    grid-template-areas: "icon theme"
                         "button button";
}
fieldset.icon > input {
    width: 4rem;
}
fieldset.topic > input {
    text-overflow: ellipsis;
}
.edit-mode.theme .save-button {
    grid-area: button;
}

.curriculum-nav {
    display: grid;
    margin-bottom: 2em;
}
.curriculum-nav header {
    font-weight: 600;
    font-variant: small-caps;
    color: darkgoldenrod;
}
.curriculum-nav ul {
    display: flex;
    align-items: center;
    gap: 1em;
}

.subject-nav-item button {
    padding: .25em
}

.subject-nav-item:not( .active ) button {
    column-gap: 0em;
    padding-left: .75em;
    border-radius: .75em;
}
.subject-nav-item:not( .active ) span {
    font-size: 0em;
    opacity: 0;
    visibility: hidden;
}

.curriculum {
    overflow-x: auto;
    display: grid;
    gap: 1em;
}

.tile-container {
    display: flex;
    /* align-items: stretch; */
}

.theme {
    --h: 30;
    --s: 100%;
    --l: 50%;
    --opacity: 0;
    --badge-colour: darkgoldenrod;

    flex: 1;
    display: grid;
    align-items: center;
    /* gap: 1em; */
    position: relative;
    border-radius: 1em;
    background: hsl( var( --h ), var( --s ), var( --l ), var( --opacity ) );
}
.tile-container:not( .level ) .theme {
    padding: 1em 1.5em;
    grid-template-columns: max-content 1fr;
}
/* .tile-container:not( .is-expanded ) .theme,
.theme:not( .is-expanded ) {
    justify-content: center;
} */

/* .level .theme { */
    /* padding: 0; */
    /* grid-template-columns: 1fr; */
/* } */

.subject-nav-item,
.theme > * {
    transition: .3s ease-in-out;
}

.tile-container.is-hidden .theme .details,
.theme.is-hidden .details {
    font-size: 0em;
    opacity: 0;
    visibility: hidden;
}
.tile-container.is-hidden .theme .icon,
.theme.is-hidden .icon {
    font-size: 2em;
    padding-left: .1em;
}

.tile-container.is-expanded .theme .details,
.tile-container.is-hidden:hover .details,
.theme.is-expanded .details {
    padding-left: 1em;
}


.theme .icon {
    font-style: normal;
    cursor: pointer;
}
.tile-container.is-hidden:hover .icon,
.tile-container.is-expanded .icon {
    font-size: 1.25em;
}

.theme .button.edit {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 1em;
}

.theme .badge {
    position: absolute;
    font-size: .75em;
    background: hsl( var( --h ), var( --s ), var( --l ) );
    color: var( --badge-colour );
    /* mix-blend-mode: difference; */
    padding: .5em 1em;
    border-radius: 50%;
    right: -1em;
    top: -1em;
}
.tile-container.is-hidden:hover .badge,
.theme.is-expanded .badge {
    opacity: 0;
    font-size: 0em;
    visibility: hidden;
}

.tile-container.is-hidden:hover .details {
    font-size: 1em;
    opacity: 1;
    visibility: visible;
}

.theme .title {
    font-size: .9em;
    max-width: 15ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.theme .duration {
    font-size: .65em;
    color: var( --badge-colour );
    white-space: nowrap;
    font-weight: 500;
}