/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.container {
    --opacity: .5;
    --label-background: black;
}

/* == 2. TYPOGRAPHY ====== */
.squares .url,
.small .url {
    color: white;
    font-size: .75rem;
}

/* .squares button {
    font-size: 0;
} */

.label {
    font-weight: 900;
}

.url {
    white-space: nowrap;
}

.bars .url {
    overflow: hidden;
    text-overflow: ellipsis;
}

/* == 3. LAYOUT (PHYS.) == */
.content .icon,
.attachment,
.container,
.content,
.link {
    display: grid;
}

.squares .content,
.content .icon,
.attachment {
    align-items: center;
}

/* .squares:not( .without-reset-button, .without-colose-button ) .attachment, */
/* .squares:not( .without-reset-button, .without-colose-button ) .content, */
:is( .squares.with-reset-button, .squares.with-close-button ) :is( .attachment, .content ) {
    grid-template-columns: min-content 1fr;
}

.squares .url,
.small .url {
    position: absolute;
    padding: .5rem 1rem;
    top: -2.25rem;
    right: 0;
    /* display: flex; */
    /* align-items: center; */
}

/* .container:not( .without-reset-button, .without-colose-button ) { */
:is( .container.with-reset-button, .container.with-close-button ) {
    gap: .5rem;
}

.squares .container {
    width: 2rem;
}
.bars.container {
    padding: 1rem .5rem;
}

.squares.container.horizontal {
    grid-auto-flow: column;
}

/* .bars .attachment, */
/* .bars .content {
    grid-template-columns: 2rem 1fr;
} */
.bars .content:not( :has( a ) ) {
    grid-template-columns: 2rem 1fr;
}
.bars .link {
    grid-template-columns: 2rem 1fr;
}

/* .attachment button {
    margin: 0;
} */

/* .bars {
    width: 2rem;
    padding: 0 .5rem;
} */

button.close,
button.reset {
    height: 100%;
}

.content,
.link {
    position: relative;
}

.bars .content {
    padding: .5rem 1rem .5rem 0;
}

.squares .content {
    padding: .5rem;
    /* padding: .5rem .5rem .5rem 0; */
    height: 100%;
    grid-template-columns: 1fr;
}

.squares .content,
.content .icon {
    justify-items: center;
}

.content img {
    max-width: 2rem;
    height: 1rem;
}

.container > button,
.container > input {
    margin: auto 3rem auto auto;
}

.container > button ~ .attachment:not( :first-of-type ),
.container > input ~ .attachment:not( :first-of-type ) {
    margin-left: .5em;
}

/* == 4. LAYOUT (AESTH.) = */
button.close,
button.reset,
.content,
.url {
    transition: .3s ease-in-out;
    cursor: pointer;
}

.squares .url,
.small .url {
    background: var( --label-background );
    border-radius: .25rem;
    visibility: hidden;
    opacity: 0;
}

.attachment {
    border-radius: .25rem;
}
.attachment:nth-of-type( even ),
.squares .attachment {
    background-color: hsl( 60, 80%, 90%, var( --opacity ) );
}

button.close,
button.reset {
    border: none;
    background: transparent;
    border-radius: .25rem 0 0 .25rem;
    padding: .5rem;
    /* border-top-left-radius: .25rem; */
    /* border-bottom-left-radius: .25rem; */
}

span.href {
    visibility: hidden;
    position: absolute;
}

.content {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

/* == 5. STATES & PSEUDO = */
.squares .url::after,
.small .url::after {
    content: '';
    position: absolute;
    bottom: -.5rem;
    right: .6rem;
    border-top: .5rem solid var( --label-background );
    border-left: .5rem solid transparent;
    border-right: .5rem solid transparent;
}

/* .squares .attachment:hover button:hover, */
.squares .attachment:hover button:hover,
.container button:hover {
    font-size: 1.15rem;
}

button:is( .close, .reset ):hover ~ .content,
button:is( .close, .reset ):hover {
/* .container button:hover { */
    background: hsl( 0, 80%, 80%, var( --opacity ) );
}

.attachment:hover > * {
    background: lightgoldenrodyellow;
}

.bars .attachment:hover > * {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.squares .attachment:hover button {
    /* border-radius: 0; */
    font-size: 1rem;
    /* padding-left: .5rem; */
}

button:is( .close, .reset ):hover path {
    fill: hsl( 0, 80%, 50% );
}
button:is( .close, .reset ):hover ~ div,
button:is( .close, .reset ):hover {
    /* background: hsl( 0, 80%, 50% ); */
    color: hsl( 0, 80%, 50% );
}

.squares .attachment:hover .content {
    padding-left: .5rem;
}

.squares .attachment:hover .url {
    visibility: visible;
    opacity: 1;
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */

.horizontal {
    justify-content: space-between;
}