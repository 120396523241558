.console h1 {
    margin: 0;
    font-size: 1.15em;
    line-height: 1em;
}
.subtitle {
    display: flex;
    font-size: .75em;
    gap: 0 1em;
    align-items: center;
    justify-content: center;
}
.subtitle button {
    padding: .25em 1em;
}

.page-tabs ul {
    margin-top: 2em;
}

.header + section {
    padding-top: 2em;
}

.page-tabs ul {
    display: flex;
    justify-content: center;
    gap: 1em;
}

.header > p,
.subtitle p {
    color: slategrey;
}
.header > p {
    margin-bottom: 1.5em;
}

.header > p {
    text-align: center;
}