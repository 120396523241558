.sheets {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.sheets > *:last-child {
    padding-top: 3rem;
}