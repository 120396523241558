.page header,
.page {
    display: grid;
    justify-items: center;
}

.page {
    /* width: 100vw; */
    grid-template-areas: " nav "
                         " header "
                         " pricing ";
    grid-template-rows: min-content min-content 1fr;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background: linear-gradient( hsl( 45, 0%, 75% ) 60%, transparent 0% );
}
.cards,
.table {
    grid-area: pricing;
}

.page > nav {
    justify-self: normal;
}
.page > nav > *:last-child {
    display: flex;
    column-gap: 1.5rem;
}

.page > header {
    display: grid;
    align-items: end;
    grid-template-columns: 66% 34%;
    padding: 4rem 2rem 2rem 4rem;
}

.page h1 {
    text-align: left;
    font-size: 1.5rem;
    margin: 0;
    line-height: 1.75rem;
}

.subtitle {
    color: grey;
    font-size: 1rem;
}

.guarantee {
    display: flex;
    align-items: center;
    font-size: .6em;
}
.guarantee svg {
    height: 1.5em;
}

.cards > ul {
    display: grid;
    grid-template-areas: " wand honey hive "
                         " free free free ";
    grid-template-columns: 26% 32% 32%;
    gap: 3rem 5%;
    width: calc( 100vw - 4rem );
}

.card {
    font-size: .8rem;
}
.card.active .honey,
.card:hover .honey {
    fill: gold;
}
.card.active:hover .honey {
    fill: orange
}

.card:last-of-type {
    grid-area: free;
}

.card.active {
    border: 3px solid gold;
}
.card.active.free {
    border-color: grey;
}

.card:first-of-type a {
    font-size: 1em;
}

.table {
    display: none;
}

@media only screen and ( max-width: 800px ) {
    .cards {
        display: none;
    }

    .table {
        display: grid;
    }
}