.table {
    --tab-colour: orange;
    padding: 2rem;
    font-size: .9rem;
    border-spacing: 0px;
}

/* .table tbody td {
    margin: 0 1rem;
} */

.table tr > *:nth-child( n + 2 ) {
    text-align: center;
    border-left: .5rem solid #fdfdfd;
    border-right: .5rem solid #fdfdfd;
    padding: 1rem 0;
}

.table th:nth-of-type( n + 2 ) {
    background: var( --tab-colour );
    position: relative;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.table th:nth-last-of-type( 2 ) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.table th:nth-last-of-type( 2 )::before {
    content: '';
    position: absolute;
    top: -1rem;
    left: 0;
    width: 100%;
    height: 1rem;
    background: var( --tab-colour );
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.feature.applies {
    background: hsl( 35, 100%, 90% );
}
.feature.has-text {
    background: hsl( 35, 100%, 95% );
}
.feature {
    background: hsl( 35, 0%, 90% );
}

.button {
    padding: .5rem 1rem;
}