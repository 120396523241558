.sidebar {
    display: grid;
    gap: 1em;
    grid-template-rows: repeat( 3, min-content ) 1fr;
    width: 20vw;
    padding: 1em;
    opacity: 1;
    transition: .3s ease-in-out;
}
.sidebar.hide {
    opacity: 0;
    visibility: hidden;
    width: 0;
    padding: 0;
}

.date-and-type > header {
    display: grid;
    grid-template-columns: max-content 1fr;
    /* gap: 3em; */
    gap: 25%;
    width: 100%;
    align-items: center;
}

.day-type {
    font-size: .8em;
    padding: .25em .75em;
    border-radius: 1em;
    white-space: nowrap;
}

.quarters {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
}
.quarter {
    border-radius: 2em;
    padding: .25em .75em;
    background: pink;
    font-size: .75em;
    cursor: pointer;
}
.quarter:not( :last-of-type ) {
    margin-right: 1em;
}

.edit-event-button {
    padding: .25em .75em;
    margin-right: .5em;
}
.event-label {
    display: inline;
}
.event-label span {
    font-size: .75em;
}