/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES & PSEUDO
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.edge {
    --solid-colour: var(--neutral-background-colour);
    --opaque-colour: hsl(0, 0%, 0%, 0);
    --solid-peak: 40%;
    --distance-from-edge: .25rem;
}

.reviews-container > div {
    --i: 1;
    --width: min(10rem, 30vw);
    --centre-width: min(15rem, 40vw);
    --centre-offset: calc( 50vw - (var(--centre-width)/2) );
}

/* == 2. TYPOGRAPHY ====== */

/* == 3. LAYOUT (PHYS.) == */
.reviews-container,
.edge {
    display: flex;
}

.carousel {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    grid-template-areas: "left-edge carousel right-edge";
    grid-template-columns: 3rem calc(100vw - 6rem) 3rem;
}

.edge {
    justify-content: center;
    z-index: 3;
}

.edge:nth-of-type(1) {
    grid-area: left-edge;
}
.edge:nth-last-of-type(1) {
    grid-area: right-edge;
}

.edge button {
    padding: 25vw 1.5vw;
}

.reviews-container > div {
    position: absolute;
    width: var(--width);
    height: min(20rem, 80vw);
    top: 15%;
    left: var(--centre-offset);
}

div.far-left {
    /* --i: -3; */
    left: calc(var(--centre-offset) - 46.5rem);
    opacity: .25;
}
div.near-left {
    /* --i: -2; */
    left: calc(var(--centre-offset) - 32rem);
    opacity: .5;
}
div.left {
    /* --i: -1; */
    left: calc(var(--centre-offset) - 17.5rem);
    opacity: .75;
}
div.centre {
    width: var(--centre-width);
}
div.right {
    /* --i: 1; */
    /* left: calc( var(--centre-width) * var(--i) + 30vw); */
    left: calc(var(--centre-offset) + 17.5rem);
    opacity: .75;
}
div.near-right {
    /* --i: 2; */
    left: calc(var(--centre-offset) + 32rem);
    opacity: .5;
}
div.far-right {
    /* --i: 3; */
    left: calc(var(--centre-offset) + 46.5rem);
    opacity: .25;
}

div.hidden {
    display: none;
}

/* == 4. LAYOUT (AESTH.) = */
.reviews-container > * {
    transition: .5s ease-in;
}

.edge {
    transition: .3s ease-in-out;
}

div.hidden {
    opacity: 0;
}

.edge:nth-of-type(1) {
    transform: translateX( var(--distance-from-edge) );
    background: linear-gradient(
        to right,
        var(--solid-colour) var(--solid-peak),
        var(--opaque-colour)
    );
}
.edge:nth-last-of-type(1) {
    transform: translateX( calc(-1 * var(--distance-from-edge)) );
    background: linear-gradient(
        to left,
        var(--solid-colour) var(--solid-peak),
        var(--opaque-colour)
    );
}

.edge:nth-of-type(1) button {
    transform: translateX( var(--distance-from-edge) );
}
.edge:nth-last-of-type(1) button {
    transform: translateX( calc(-1 * var(--distance-from-edge)) );
}

/* == 5. STATES & PSEUDO = */
.edge:focus,
.edge:hover {
    --solid-colour: var(--secondary-colour-pale);
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */