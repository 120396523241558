/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */
.action-zone > button:nth-child( 2 ) p {
    color: #fdfdfd;
}

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
.action-zone button img,
.action-zone button i {
    font-size: 1.5rem;
}

.content i,
.icon {
    color: transparent;
}

.empty-input {
    color: var(--field-colour);
}

.icon {
    font-size: 1.25rem;
}

.action-zone button {
    font-size: 1rem;
}

.action-zone input {
    font-size: 1.05rem;
}

.action-zone label {
    font-style: italic;
}

/* == 3. LAYOUT (PHYS.) == */
.action-zone fieldset,
.reading-zone,
.action-zone,
.sign-in {
    display: grid;
}

.action-zone button,
.options {
    display: flex;
}

.action-zone fieldset,
.action-zone {
    justify-items: center;
}

.action-zone button,
.options {
    align-items: center;
}

.action-zone fieldset > div,
.password,
.email {
    position: relative;
}

.action-zone label,
.icon {
    position: absolute;
}

.reading-zone,
.action-zone {
    padding: 2rem;
}

.action-zone button img,
.action-zone button i {
    width: 1.5rem;
}

.sign-in {
    grid-template-rows: min-content 1fr;
    height: 100vh;
}

.options {
    justify-self: center;
}

/* .action-zone hr {
    margin-top: 2rem;
    width: 80%;
} */

.action-zone {
    align-content: center;
    /* align-self: center; */
    justify-self: center;

    width: fit-content;
    height: fit-content;
    box-shadow: .1em .1em .25em 0 hsl( 0, 0%, 30%, .25 );
    background: linear-gradient( 45deg, orangered, orange );

    padding: 10vw 12vw 6vw;
    margin-top: 10vh;
    border-radius: 1rem;
}

.action-zone button {
    flex-flow: row nowrap;
    gap: .5rem;
    height: 2.5rem;
    margin: .5rem 0;
    padding: 0 1.5rem;
}

.action-zone fieldset {
    margin-top: 1rem;
    gap: 1rem;
}

.action-zone input {
    height: 2.5rem;
    min-width: 4rem;
    /* margin-top: 2rem; */
    padding: .25rem .5rem;
    /* padding: .25rem .5rem .25rem 2rem; */
}

/* .action-zone label {
    width: fit-content;
    left: 2rem;
    top: 58%;
} */

.icon {
    left: .5rem;
    bottom: 12.5%;
}

/* == 4. LAYOUT (AESTH.) = */
.reading-zone button,
.action-zone button,
.action-zone label,
.action-zone input {
    transition: .3s ease-in-out;
}

.reading-zone button:not( .to-profile-page ),
.action-zone fieldset {
    border: none;
}

.reading-zone button:not( .to-profile-page ) {
    background: none;
    text-decoration: underline;
}

/* .action-zone {
    border-left: 2px solid var(--secondary-colour);
} */

.action-zone button {
    border-radius: 2rem;
}

.action-zone > div button {
    border: 1px solid darkgrey;
    background: white;
}

/* .action-zone input {
    background: var( --field-colour );
} */

/* .action-zone label {
    cursor: text;
} */

.icon {
    background: linear-gradient(
        to bottom,
        var(--tertiary-colour),
        var(--secondary-colour-highlight)
    );
    background-attachment: fixed;
    background-clip: text;
}

/* == 5. STATES & PSEUDO = */
.reading-zone button:not( .to-profile-page ):hover,
.action-zone button:hover {
    transform: scale( 1.1 );
}

.reading-zone button:not( .to-profile-page ):hover {
    color: darkgoldenrod;
}

.empty-input:focus-visible,
.empty-input:focus {
    outline: var(--secondary-colour-highlight) auto 2px;
}

.empty-input:focus-visible + label,
.empty-input:focus + label,
.input-has-value + label {
    font-weight: 900;
    font-style: normal;
    color: var(--font-colour-on-light-background);
    transform: translate( -2rem, -2.5rem );
    cursor: default;
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */