/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.pane {
    --dark: hsl( 25, 90%, 70% );
    --mid: hsl( 30, 65%, 65% );
    --light: hsl( 35, 95%, 60% );
}
/* == 2. TYPOGRAPHY ====== */
/* .course-name input, */
.card .course-name,
.delete-mode,
.nothing-due,
.copied {
    text-align: center;
}

/* .course-name input, */
.course-name .name,
.button button,
.status {
    color: white;
    font-weight: 900;
    text-transform: capitalize;
    font-size: 1.25rem;
}

.button button,
.status {
    text-transform: uppercase;
}

.button button,
.pane a {
    color: black;
}

.assignment span,
.status {
    font-size: .6rem;
}

.pane a {
    text-decoration: none;
}

.delete-mode span {
    font-weight: 900;
    color: maroon;
}

/* == 3. LAYOUT (PHYS.) == */
.relevant-assignments ul,
.delete-mode,
.course-name,
.square,
.copied,
.status,
.pane {
    display: grid;
}

.delete-mode,
.copied,
.status {
    align-items: center;
}

.view-filter ul,
.view-filter,
.courses {
    display: flex;
    flex-wrap: wrap;
}

.view-filter ul,
.view-filter {
    gap: 1em;
    align-items: center;
}

.card {
    grid-template-rows: 4rem 1fr;
}

.view-filter {
    padding: 1rem 2rem;
    justify-content: center;
}

.pane {
    overflow: auto;
    grid-template-rows: max-content 1fr;
}

.courses {
    justify-content: flex-start;
    align-content: flex-start;
    gap: 2rem;
    padding-top: 2rem;
    /* margin: 0 4rem; */
    margin: 0 7.5vw;
    /* margin: 0 min( 4rem, 7.5vw ); */
}

.square:not( .form ) {
    position: relative;
    width: 15.25rem;
    min-height: 10rem;
}
.square.form {
    animation: expand .5s forwards;
}

.course-name {
    position: relative;
    align-content: center;
    justify-items: center;
}

.status {
    grid-template-columns: max-content 1fr;
    gap: .5rem;
    padding: .25rem .5rem;
}

.kebab {
    position: absolute;
    right: 0;
    bottom: 55%;
    top: 0;
    padding: 0 1rem;
}

.assignment {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: .25rem;
}

.assignment span {
    padding-right: .5rem;
}

.relevant-assignments ul {
    margin: 1rem;
    gap: .5rem;
}

.copied {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.buttons {
    margin: 0 1em;
}

/* == 4. LAYOUT (AESTH.) = */
.button button,
.square {
    transition: .3s ease-in-out;
    background: hsl( 0, 100%, 100%, .75 );
}

.square {
    border: .25rem solid rust;
    border-radius: 1em;
    box-shadow: .1rem .1rem 1rem 0 rgba( 0, 0, 0, .25 );
}
.square.delete-mode {
    border-color: maroon;
    background: hsl( 0, 100%, 75%, .4 );
}

.course-name {
    border-radius: 1em 1rem 0 0;
    box-shadow: 0 4px 30px rgba( 0, 0, 0, 0.1 );
    background: linear-gradient( to top right, orangered, orange );
}

.worker-hive .course-name {
    background: linear-gradient( to top right, hsl( 30, 100%, 50% ), hsl( 48, 100%, 50% ) );
}

.status {
    background: hsl( 0, 0%, 50% );
    width: fit-content;
    border-radius: .5rem;
}
.status.full {
    background: hsl( 43, 75%, 50% );
}
.status.enough {
    background: hsl( 33, 100%, 50% );
}
.status.in-progress {
    background: hsl( 0, 100%, 25% );
}

.status .light {
    width: .5rem;
    height: .5rem;
    background: white;
    display: inline-block;
    border-radius: 50%;
}

.button {
    background: none;
    /* border: .2rem solid transparent; */
}
.button button {
    border-radius: .5em;
    border: none;
}

.assignment {
    transition: .2s ease-in-out;
}

.copied {
    border-radius: .25rem;
    background: #fdfdfd;
    opacity: 0;
    visibility: 0;
    transition: .2s ease-in-out;
}
.copied.show {
    opacity: 1;
    animation: 1.4s circle;
}

/* == 5. STATES & PSEUDO = */
.square:focus-within,
.square:hover {
    transform: scale( 1.05 );
}

.button:hover {
    /* border-color: cornsilk; */
    transform: scale( 1.025 );
}
.button:hover button {
    background: linear-gradient( to top right, orangered, orange );
    color: white;
    font-size: 1.5rem;
}

.assignment:hover {
    transform: scale( 1.1 ) translateX( .25rem );
}

/* == 6. ANIMATIONS ====== */
@keyframes circle {
    0% { clip-path: circle( 75% ); }
    100% { clip-path: circle( 0% ); }
}

@keyframes expand {
    0% { width: 90%; }
    100% { width: calc( 90% * 2 + 2rem ); }
}
/* == 7. @MEDIA ========== */
/* == GRADIENT */
/* background:
    radial-gradient( circle farthest-side at 30% 10%, transparent 50%, gold, orange, orangered ),
    linear-gradient( to top right, yellow, transparent ),
    linear-gradient( to top, orangered, orange ); */