.hero {
    padding-top: 40vh;
    flex-direction: column;
    justify-content: center;
    background-image: url('/public/banner-half.png'), url('/public/banner-half-border.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 0 10vh;
    background-size: contain, 0% 100vw;
    width: 100vw;
    transition: all .5s ease-in;
    /* transition: background-image .5s ease-in-out; */
}

.hero-text {
    padding: 0 3rem;
    z-index: 2;
}

div.text {
    padding: 2rem;
    text-align: center;
    background-color: var(--neutral-background-colour-mid-opaque);
}

.text span {
    font-weight: 900;
    transition: all .5s ease-in-out;
}

.text button {
    margin: 2rem auto 1rem;
}

.hero:hover {
    background-size: contain, contain;
}

.hero:hover span {
    background: linear-gradient(90deg, #540303ff, #980202ff, #db0000ff, #980202ff, #540303ff, #980202ff);
    background-size: 150% 100%;
    background-clip: text;
    color: transparent
}