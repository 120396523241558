/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
/* == 2. TYPOGRAPHY ====== */
/* == 3. LAYOUT (PHYS.) == */
.visible {
    position: absolute;
    left: -8.5rem;
    padding: .25rem 1rem;
}

/* == 4. LAYOUT (AESTH.) = */
.visible {
    background: hsl(0, 0%, 15%, .85);
    border-radius: .5rem;
    backdrop-filter: blur( .25rem );
    animation: fadeUp 1.4s ease-in-out 1;
}

/* == 5. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
@keyframes fadeUp {
    0% {
        top: -1rem;
        opacity: 0;
    }
    20% {
        top: -.5rem;
        opacity: 1;
    }
    100% {
        top: -3rem;
        opacity: 0;
    }
}

/* == 7. @MEDIA ========== */