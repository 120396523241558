/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

/* == 1. VARIABLES ======= */
.lines {
    --width: 1rem;
    --colour: black;
    --radius: 1rem;

    --rotation-angle: 45deg;
    --translate-x: 30%;
    --translate-y: calc( var( --translate-x ) * 6 );

    --middle-translate-x: -80%;
}

.lines > .line {
    --angle: var( --rotation-angle );
    --x: var( --translate-x );
    --y: var( --translate-y );
}
.lines > .line:last-of-type {
    --angle: calc( 0deg - var( --rotation-angle ) );
    --x: calc( var( --translate-x ) - 2.5% );
    --y: calc( var( --x ) * -6 );
}

.middle .left {
    --x: var( --middle-translate-x );
}
.middle .right {
    --x: calc( var( --middle-translate-x ) * -1 );
}

/* == 2. TYPOGRAPHY ====== */
/* == 3. LAYOUT (PHYS.) == */
.lines {
    display: grid;
    gap: .25rem;
    justify-items: center;
}

.line {
    width: var( --width );
    height: 3px;
}

.middle {
    display: flex;
}

.middle .line {
    width: calc( ( var( --width ) * 4/3 ) / 2 );
}

/* == 4. LAYOUT (AESTH.) = */
.lines {
    cursor: pointer;
}

.line {
    border: 2px solid var( --colour );
    background: var( --colour );
    border-radius: var( --radius );
    transition: .4s ease-in-out;
}

.lines > .line:first-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.lines > .line:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.middle .left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.middle .right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* == 5. STATES & PSEUDO = */
.open.lines > .line {
    transform: rotate3d( 0, 0, 1, var( --angle ) ) translate3d( var( --x ), var( --y ), 0 );
    border-radius: var( --radius );
}
.open .middle .line {
    transform: translate3d( var( --x ), 0, 0 );
    opacity: 0;
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */