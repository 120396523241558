/* ======== MENU ============
 * 1. GENERAL
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES & PSEUDO
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */
 
 /* == 1. GENERAL ========= */
 
 /* == 2. TYPOGRAPHY ====== */
 
 /* == 3. LAYOUT (PHYS.) == */
 .squares {
    position: relative;
}

.square {
    position: absolute;
}

.square:nth-of-type(6) {
    top: 0;
    right: 0;
    width: 5rem;
    height: 5rem;
    z-index: 5;
}
.square:nth-of-type(2) {
    top: 50%;
    left: 0;
    width: 6rem;
    height: 6rem;
}
.square:nth-of-type(3) {
    bottom: -10%;
    right: 10%;
    width: 3.5rem;
    height: 3.5rem;
}
.square:nth-of-type(4) {
    bottom: -10%;
    left: 15%;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 5;
}
.square:nth-of-type(5) {
    top: -5%;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 5;
}
    
    /* == 4. LAYOUT (AESTH.) = */
.square {
    background: var(--secondary-colour-pale-opaque);
    backdrop-filter: blur(.5rem);
    box-shadow: 0 25px 45px hsla(0, 0%, 100%, .1);
    border: 1px solid hsla(0, 100%, 100%, .5);
    border-right-color: hsla(0, 100%, 100%, .2);
    border-bottom-color: hsla(0, 100%, 100%, .2);
    border-radius: .5rem;
    animation: animate 10s linear infinite;
    animation-delay: calc( -1s * var(--i) );
}

/* == 5. STATES & PSEUDO = */

/* == 6. ANIMATIONS ====== */
@keyframes animate {
    0%,
    100% { transform: translateY( -2rem); }
    50% { transform: translateY( 2rem); }
}

/* == 7. @MEDIA ========== */