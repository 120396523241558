/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.menu {
    --border-radius: 1rem;
}

/* == 2. TYPOGRAPHY ====== */
.kebab > i {
    color: white;
}

/* == 3. LAYOUT (PHYS.) == */
.menu {
    position: absolute;
    right: 2rem;
    top: 1.5rem;
    width: calc( 15.25rem - 3rem );
}
.menu.show {
    top: 2rem;
}

.menu ul {
    display: grid;
}

.menu li {
    padding: .5rem 1rem;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    gap: .5rem;
}

/* == 4. LAYOUT (AESTH.) = */
.menu li,
.menu {
    transition: .3s ease-in-out;
}

.kebab {
    border: none;
    background: none;
}

.kebab i {
    transition: .3s ease-in-out;
}

.menu {
    background: hsl( 45, 100%, 65%, .4 );
    backdrop-filter: blur( 10px );
    border-radius: var( --border-radius );
    visibility: hidden;
    opacity: 0;
}
.menu.show {
    visibility: visible;
    opacity: 1;
}

.menu li:nth-child( even ) {
    background: hsl( 0, 0%, 100%, .4 );
}
.menu li:first-of-type {
    border-top-right-radius: var( --border-radius );
    border-top-left-radius: var( --border-radius );
}
.menu li:last-of-type {
    border-bottom-right-radius: var( --border-radius );
    border-bottom-left-radius: var( --border-radius );
}

/* == 5. STATES & PSEUDO = */
.menu li:hover i,
.kebab:hover > i {
    transform: scale( 1.25 );
}

.menu li:hover {
    background: hsl( 30, 100%, 65%, .75 );
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */