/* ======== MENU ============
* 1. TYPOGRAPHY
* 2. LAYOUT (PHYS.)
* 3. LAYOUT (AESTH.)
* 4. STATES & PSEUDO
* 5. ANIMATIONS
* 6. @MEDIA
========================== */

/* == 1. TYPOGRAPHY ====== */
/* == 2. LAYOUT (PHYS.) == */
.logo img:last-of-type,
.closed-menu,
.open-menu {
    position: absolute;
}

.closed-menu,
.open-menu {
    height: 100%;
}

.logo img,
.logo {
    padding: .25rem 0;
}

.logo {
    position: relative;
}

.logo img {
    width: 1.5rem;
}

.logo img:last-of-type {
    right: 0rem;
}

/* == 3. LAYOUT (AESTH.) = */
.open-menu .logo img:first-of-type,
.logo:hover img:first-of-type {
    transform: scale( 2 ) translate( .25rem, -.25rem );
    opacity: 0;
}

.open-menu .logo img:last-of-type,
.logo:hover img:last-of-type {
    transform: scale( 1 ) translate( 0 , 0 );
}

.logo img:last-of-type,
.logo {
    background: none;
}

.logo {
    overflow: hidden;
    border: none;
    border-radius: 0;
}

.logo img {
    transition: .5s ease-in-out;
}

.logo img:last-of-type {
    transform: scale( .6 ) translate( -.5rem, .5rem );
}

.open-menu .logo {
    background: var( --secondary-colour-pale-opaque );
}

.menu {
    transition: .3s ease-in-out;
}

.closed-menu .menu {
    visibility: hidden;
}

/* == 4. STATES & PSEUDO = */
/* == 5. ANIMATIONS ====== */
/* == 6. @MEDIA ========== */