.layout {
    display: grid;
    grid-template-columns: minmax( 10em, 10vw ) 1fr;
}
.layout > * {
    padding: 1em 2em;
}

.units {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
}

.unit-link {
    width: 12.5em;
    height: 10em;
    /* border: 2px solid gold; */
    padding: 1em 2em;
    grid-auto-flow: row;
    grid-auto-columns: auto;
    align-content: center;
    justify-items: center;
    border-radius: 1em;
    /* padding: 1em 2em; */
}

/* .subject-banner {
    position: absolute;
    background: gold;
    padding: .1em 1em;
    width: fit-content;
    font-variant: all-small-caps;
    left: -.5em;
    top: 1em;
}
.subject-banner::before {
    content: "";
    background: red;
    border-right: 10px solid blue;
    border-top: 10px solid purple;
    border-bottom: 10px solid pink;
} */

.unit-link > header {
    font-size: 1.1em;
    text-align: center;
}

.unit-length {
    font-size: .75em;
    font-style: italic;
    color: slategrey;
    text-align: center;
}

/* HTML: <div class="ribbon">Your content</div> */
.subject-banner {
    --r: 10px; /* control the ribbon shape (the radius) */
    --c: gold;

    position: absolute;
    font-variant: all-small-caps;
    top: 20px;
    left: calc(-1*var(--r));
    line-height: 1.8; /* control the height */
    padding: 0 .5em calc(2*var(--r));
    border-radius: var(--r) 0 0 var(--r);
    background:
      radial-gradient(100% 50% at left,var(--c) 98%,#0000 101%)
       100% 0/.5lh 1lh,
      radial-gradient(100% 50% at right,#0005 98%,#0000 101%)
       0 100%/var(--r) calc(2*var(--r)),
      conic-gradient(from 90deg at var(--r) calc(100% - 2*var(--r)),#0000 25%,var(--c) 0)
       0 0/calc(101% - .5lh) 100%;
    background-repeat: no-repeat;
  }