/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
 ========================== */

/* == 1. VARIABLES ======= */
.progress {
    --completion-rate: 0%;
    --icon-position: 0%;
    --h-increment-direction: 1;
    --h: 45;
    --s: 100%;
    --l: 50%;
}

/* == 2. TYPOGRAPHY ====== */
/* == 3. LAYOUT (PHYS.) == */
.progress {
    margin: 1rem 2rem 2rem;
    width: calc( 100vw - 4rem );
    overflow-x: clip;
    display: grid;
}

.icon img {
    height: 3rem;
}

.bar {
    height: 1.5rem;
}

.fill {
    height: 1.2rem;
}

/* == 4. LAYOUT (AESTH.) = */
.icon img,
.icon,
.fill {
    transition: .3s ease-in-out;
}

.icon {
    transform: translateX( var( --icon-position ) );
}

.fill,
.bar {
    border-radius: 1rem;
}

.bar {
    border: .15rem solid black;
}

.fill {
    background: linear-gradient(
        to right,
        hsl( var( --h ), var( --s ), var( --l ) ),
        hsl( calc( var( --h ) + calc( 10 * var( --h-increment-direction ) ) ), var( --s ), var( --l ) ),
        hsl( calc( var( --h ) + calc( 20 * var( --h-increment-direction ) ) ), var( --s ), var( --l ) )
    );
    width: var( --completion-rate );
}

/* == 5. STATES & PSEUDO = */
/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */