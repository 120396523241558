/* ======== MENU ============
 * 1. VARIABLES
 * 2. TYPOGRAPHY
 * 3. LAYOUT (PHYS.)
 * 4. LAYOUT (AESTH.)
 * 5. STATES
 * 6. ANIMATIONS
 * 7. @MEDIA
========================== */

/* == 1. VARIABLES ======= */
.select {
    --padding: .25rem;
}

/* == 2. TYPOGRAPHY ====== */
.select.container label,
.input-container {
    white-space: nowrap;
    overflow: hidden;
}

.selection-icon.pending,
.tags li {
    font-size: calc( var( --padding ) * 3);
}

.options ul,
input.text {
    font-size: calc( var( --padding ) * 3.75 );
    text-overflow: ellipsis;
}

.select.container label {
    font-weight: 900;
    text-transform: uppercase;
}

.selection-icon {
    font-size: calc( var( --padding ) * 1.5 );
}

button.reset {
    color: red;
}

/* == 3. LAYOUT (PHYS.) == */
.select.container.label-on-left,
.select.single .field,
.input-container,
.options ul {
    display: grid;
    align-items: center;
}

.select.container,
.input-container,
.field {
    position: relative;
}

.selection-icon,
.options {
    position: absolute;
}

.select.container {
    padding: 0;
    margin: .25rem .5rem;
}

.select.container:not( .label-on-left ) .field {
    margin-top: .25rem;
}

/* .select.single .field {
} */
.horizontal.select.single .field {
    grid-template-columns: min-content 1fr;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
}

.input-and-buttons {
    display: flex;
}

.select.container.label-on-left {
    grid-template-columns: max-content 1fr;
    margin: .25rem 0 .25rem .5rem;
}

.select.container.label-on-left label {
    padding-right: calc( var( --padding ) * 2 );
    width: fit-content;
}

.field {
    padding: .25rem 0 .25rem var( --padding );
}
.vertical .field {
    padding-right: .25rem;
}

.field > button:last-of-type i {
    padding: var( --padding );
}

.input-container {
    grid-template-columns: 1fr;
    margin-left: var( --padding );
    min-width: calc( var( --padding ) * 16 );
}

input.text {
    padding: 0 var( --padding ) 0 calc( var( --padding ) * 7 );
    /* width: fit-content; */
}

.tags ul {
    display: flex;
    flex-wrap: wrap;
    gap: var( --padding );
}

.options {
    /* top: calc( var( --padding ) * 9.75 ); */
    top: 1.95rem;
    right: -.1rem;
    max-height: 10rem;
    max-width: 92%;
    z-index: 20;
    overflow-y: scroll;
}

.options ul {
    text-align: left;
}

.options li {
    padding: 2rem;
}

.select.container.label-on-left .options {
    top: calc( var( --padding ) * 5 );
}

.selection-icon {
    left: calc( var( --padding ) * 2 );
}

.selection-icon.pending {
    left: calc( var( --padding ) * 1.1 );
}

/* == 4. LAYOUT (AESTH.) = */
.select.container,
.field .options,
button.select,
button.reset,
.options li,
.field {
    transition: .3s ease-in-out;
}

button.select,
button.reset,
input.text {
    border: none;
}

.options,
.field {
    background: white;
    border: 2px solid black;
}

button.select,
button.reset,
.field {
    border-radius: calc( var( --padding ) * 1.5 );
}

input.text {
    overflow: hidden;
}

button.select,
button.reset {
    background: none;
}

.select.expanded .field {
    border-bottom-right-radius: 0;
}

.options {
    border-top: white;
    box-shadow: .1em .1em;
    border-bottom-left-radius: .5em;
    border-bottom-right-radius: .5em;
    border-left-style: dashed;
}

.options li {
    cursor: pointer;
    padding: calc( var( --padding ) * 2 ) calc( var( --padding ) * 4 );
}

.hide-field .input-container {
    visibility: hidden;
}

/* == 5. STATES & PSEUDO = */
button.select:hover,
.options li:hover {
    background-color: var( --secondary-colour-pale );
}

input.text:focus {
    outline: none;
}

.field:focus-within .options,
.field:focus-within {
    border-color: hsl(228, 60%, 60%);
}

button.reset:hover {
    color: white;
    background: maroon;
}

/* == 6. ANIMATIONS ====== */
/* == 7. @MEDIA ========== */